import { Avatar } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";
import { Benin } from "utils/fakedata";
import { JournalItem } from "utils/interfaces";

interface IProps {
  journal: JournalItem;
}

const BottomContentItem: FC<IProps> = ({ journal }) => {
  const status = journal.status;
  const { t } = useTranslation();

  
  const statusText = status === 1 ? t('alert.connected') : t('alert.connected');
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "25%",
        }}
      >
        <Avatar src={`${process.env.REACT_APP_API_URL}/${journal.profilePicture}`}/>

        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
            marginBottom: 5,
            marginLeft: 15,
          }}
        >
          {journal.name}
        </p>
      </section>
      <div style={{ width: "15%", display: "flex", alignItems: "center", gap: 4 }}>
        {/* <img src={Benin} style={{ height: 20, marginTop: 4 }} /> */}
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.black,
          }}
        >
          {journal.pays}
        </p>
      </div>
      <div style={{ width: "30%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.black,
          }}
        >
          {journal.activity}
        </p>
      </div>
      <div style={{ width: "15%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {journal.date}
        </p>
      </div>
      <div style={{ width: "10%", display: "flex" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: status === 1 ? baseStyles.color.blue.primary : baseStyles.text.default,
          }}
        >
          {statusText}
        </p>
      </div>
    </div>
  );
};

export default BottomContentItem;
