import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#131A21"
      fillRule="evenodd"
      d="M8.71.614C8.43.5 8.08.5 7.374.5c-.704 0-1.056 0-1.334.114-.37.152-.665.444-.818.812-.07.168-.098.363-.108.647a1.211 1.211 0 0 1-.597 1.015 1.23 1.23 0 0 1-1.184.006c-.254-.133-.438-.207-.62-.23a1.52 1.52 0 0 0-1.117.296c-.238.182-.414.485-.767 1.09-.352.605-.528.908-.567 1.204-.052.394.055.793.3 1.109.11.144.267.265.51.416.357.223.587.602.587 1.021 0 .419-.23.798-.587 1.02-.243.152-.4.273-.51.417a1.492 1.492 0 0 0-.3 1.11c.04.295.215.598.567 1.203.352.605.529.908.767 1.09.318.242.72.349 1.117.297.182-.024.366-.098.62-.231a1.23 1.23 0 0 1 1.184.006c.365.21.581.596.597 1.014.01.285.038.48.108.648.153.368.448.66.818.812.278.114.63.114 1.334.114.704 0 1.056 0 1.334-.114.37-.152.665-.444.818-.812.07-.168.098-.363.108-.647.016-.419.232-.806.597-1.015a1.23 1.23 0 0 1 1.184-.006c.254.133.438.207.62.23.397.053.799-.054 1.117-.296.238-.182.415-.485.767-1.09.352-.605.528-.908.567-1.204a1.492 1.492 0 0 0-.3-1.109c-.11-.144-.267-.265-.51-.416A1.21 1.21 0 0 1 13.09 8c0-.419.23-.798.587-1.02.243-.152.4-.273.51-.417.245-.316.352-.715.3-1.11-.04-.295-.215-.598-.567-1.203-.352-.605-.528-.908-.767-1.09a1.52 1.52 0 0 0-1.117-.297c-.182.024-.366.098-.62.231a1.23 1.23 0 0 1-1.184-.006 1.211 1.211 0 0 1-.597-1.015c-.01-.284-.038-.48-.108-.647A1.506 1.506 0 0 0 8.71.614ZM7.374 10.25A2.259 2.259 0 0 0 9.642 8a2.259 2.259 0 0 0-2.267-2.25A2.259 2.259 0 0 0 5.108 8a2.259 2.259 0 0 0 2.267 2.25Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as SettingIcon };
