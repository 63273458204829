import { FC, ReactElement } from "react";
import styles from "./styles";
import Spacer from "components/Spacer/Spacer";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { useMediaQuery } from "@mui/material";
import { GoBackIcon } from "components/Icons/GoBackIcon";
import { useNavigate } from "react-router";
import { useScreenType } from "utils/constants/UseScreenType";

interface IProps {
  children: ReactElement;
  logo: any;
  title: string;
  subtitle?: string;
  onBtnClick: (event: MouseEvent) => void;
  phoneNumber?: string;
  loading?: boolean;
  btnLabel?: string;
  isLoginMobile?: boolean;
}

const CustomAuthContent: FC<IProps> = ({
  onBtnClick,
  children,
  logo,
  title,
  subtitle,
  phoneNumber,
  loading,
  btnLabel,
  isLoginMobile,
}) => {
  const navigation: any = useNavigate();
  const { isMobile, isTab } = useScreenType();
  const handleGoBack = () => (!isLoginMobile ? navigation(-1) : undefined);
  return (
    <div style={!isTab ? styles.container : styles.containerTab}>
      <div onClick={handleGoBack} style={styles.goBackMobile}>
        {!isLoginMobile && isTab && <GoBackIcon />}
      </div>

      <div style={styles.header}>
        {!isMobile ? (
          <img height={145} width={160} alt="logo-medium" src={logo} />
        ) : (
          <div style={{ marginTop: "10vh" }}>
            <img height={145} width={160} alt="logo-medium" src={logo} />
          </div>
        )}

        <Spacer />

        <>
          {!isTab ? (
            <>
              <p style={styles.title}>{title}</p>
              <Spacer />
              {subtitle && <p style={styles.description}>{subtitle}</p>}

              {phoneNumber && <p style={styles.phoneNmuber}>{phoneNumber}</p>}
            </>
          ) : (
            <div style={{ width: isMobile ? "90vw" : 400 }}>
              <p style={styles.title}>{title}</p>
              <Spacer />
              {subtitle && <p style={styles.descriptionMobile}>{subtitle}</p>}

              {phoneNumber && <p style={styles.phoneNmuber}>{phoneNumber}</p>}
            </div>
          )}
        </>
      </div>

      <Spacer />

      <section style={styles.children}>{children}</section>

      <Spacer />

      {!isMobile ? (
        <NeuromorphBtn
          loading={loading}
          label={btnLabel}
          onClick={onBtnClick}
        />
      ) : (
        <div
          style={{
            bottom: 22,
            width: "100vw",
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NeuromorphBtn
            width={"90%"}
            loading={loading}
            label={btnLabel}
            onClick={onBtnClick}
          />
        </div>
      )}
    </div>
  );
};

export default CustomAuthContent;
