import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomAuthenticationWrapper from "components/CustomAuthenticationWrapper/CustomAuthenticationWrapper";
import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import Spacer from "components/Spacer/Spacer";
import forgotLogo from "assets/images/forgot-pwd-img.png";
import ForgetPasswordMobile from "./ForgetPasswordMobile";
import { useScreenType } from "utils/constants/UseScreenType";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { Ghana } from "utils/fakedata";
import { useForgotPassword } from "hooks/useForgotPassword";

export const ForgetPassword = () => {
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");

  const { SendConfirmationCodeToPhone, sendingForgotCode } =
    useForgotPassword();

  const onPhoneChanged = (value: string) => {
    setPhone(value);
  };

  const handleValidate = () => {
    SendConfirmationCodeToPhone(phone);
  };

  const disableBtn = phone?.trim()?.length < 7;

  return !isTab ? (
    <CustomAuthenticationWrapper>
      <CustomAuthContent
        loading={sendingForgotCode}
        logo={forgotLogo}
        title={t("forgotPassword.forgotenPassword")}
        subtitle={t("forgotPassword.info")}
        onBtnClick={handleValidate}
      >
        <>
          <Spacer />

          <NeomorphInputWithIcon
            width={400}
            label={t("forgotPassword.phone")}
            placeholder={t("forgotPassword.placeholder")}
            icon={
              <img
                alt="country"
                style={{ marginTop: 3, width: 30, height: 25 }}
                src={Ghana}
              />
            }
            onChange={onPhoneChanged}
            value={phone}
          />

          <Spacer />
        </>
      </CustomAuthContent>
    </CustomAuthenticationWrapper>
  ) : (
    <ForgetPasswordMobile />
  );
};
