import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { journales } from "utils/fakedata";
import JournalItem from "./JournalItem";
import useJournalHooks from "hooks/useJournalHooks";
import { FC, useEffect, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import { IJournalFilter, JournalItem as IJournalItem } from "utils/interfaces";
import { useTranslation } from "react-i18next";
interface IProps {
  filter: IJournalFilter;
}
const JournalItems: FC<IProps> = ({ filter }: IProps) => {
  const { dateRange, getDataJournal, journalesData, loading, paginationData } =
    useJournalHooks();
  const { t } = useTranslation();
  const [filteredJounal, setFilteredJournal] = useState<IJournalItem[]>([]);
  console.log(journalesData);

  useEffect(() => {
    const _copy = journalesData
      .filter((item) => {
        const k = filter?.keyword?.toLowerCase();
        if (k?.length === 0) {
          return true;
        } else {
          return (
            item?.activity?.toLowerCase()?.includes(k) ||
            item?.name?.toLowerCase()?.includes(k)
          );
        }
      })
      .filter((item) => {
        if (filter?.countryCode?.length === 0 || !filter?.countryCode) {
          return true;
        } else {
          return item?.countryCode === filter?.countryCode;
        }
      });
    setFilteredJournal(_copy);
  }, [filter]);

  useEffect(() => {
    getDataJournal(dateRange.minDate, dateRange.maxDate, 1);
  }, []);

  useEffect(() => {
    setFilteredJournal(journalesData);
  }, [journalesData]);

  return (
    <div style={{ paddingTop: 20 }}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <CircularProgress
            style={{ color: baseStyles.color.blue.primary }}
            disableShrink
          />
        </div>
      ) : (
        <div>
          {filteredJounal.length === 0 ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20%",
              }}
            >
              <Typography
                style={{
                  color: baseStyles.text.default,
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                {t('alert.noLog')}
              </Typography>
            </div>
          ) : (
            filteredJounal?.map((journal) => (
              <NeomorphContainer key={journal.id} style={{ marginBottom: 20 }}>
                <JournalItem journal={journal} />
              </NeomorphContainer>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default JournalItems;
