import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getHistory } from "services/Journal/JournalService";
import { JournalItem } from "utils/interfaces";
import {
  formatDateDifference,
  formatDateTime,
  formmatDateToAPI,
  getStartAndEndOfYear,
  humanizeDate,
} from "utils/utils";

function useJournalHooks() {
  const [journalesData, setJournalesData] = useState<JournalItem[]>([]);
  const [paginationData, setPaginationData] = useState<any>("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    minDate: getStartAndEndOfYear().start,
    maxDate: new Date(),
  });

  const [active, setActive] = useState(1);
  const getAcitivityLabel = (key: string) => {
    let label = t("input.undefined");
    switch (key) {
      case "profilePicture":
        label = t("labels.profilePicture");
        break;
      case "active":
        label = t("labels.active");
        break;
      case "role":
        label = t("labels.role");
        break;
      case "lastname":
        label = t("labels.lastname");
        break;
      case "firstname":
        label = t("labels.firstname");
        break;
      case "picture":
        label = t("labels.picture");
        break;
      case "country":
        label = t("labels.country");
        break;
      case "accountName":
        label = t("labels.accountName");
        break;
      default:
        break;
    }
    return label;
  };
  const getDataJournal = (startDate: Date, endDate: Date, active: number) => {
    setLoading(true);
    getHistory(
      formmatDateToAPI(startDate),
      formmatDateToAPI(endDate),
      active,
      6
    )
      .then((res: any) => {
        const data = res.data.results;
        setPaginationData(res.data.pagination);
        console.log(formatDateTime(data.date));

        setLoading(false);
        const newDataEntries = data.map((item: any) => ({
          id: item.id,
          name:
            item.updater?.firstname + " " + item.updater?.lastname ??
            t("input.undefined"),
          profilePicture: item.updater.profilePicture?.path ?? "",
          pays: item.country?.name ?? t("input.undefined"),
          activity: getAcitivityLabel(item.field) ?? t("input.undefined"),
          date: humanizeDate(item.date) ?? t("input.undefined"),
          status: item.updater.active ?? t("input.undefined"),
          countryCode: item?.country?.code ?? t("input.undefined"),
        }));

        setJournalesData(newDataEntries);
        console.log(journalesData);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des données:", error);
      });
  };

  useEffect(() => {
    getDataJournal(dateRange.minDate, dateRange.maxDate, active);
  }, [dateRange]);

  return {
    getDataJournal,
    journalesData,
    loading,
    paginationData,
    dateRange,
    setDateRange,
  };
}

export default useJournalHooks;
