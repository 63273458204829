import { FC, useState } from "react";
import { Drawer, Grid, Typography, Button, Avatar } from "@mui/material";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import { baseStyles } from "utils/constants/baseStyles";
import { IUserItem } from "utils/interfaces";
import CountryPhoneNumber from './CountryPhoneNumber';
import KycRejectionDrawer from "./KycRejectionDrawer";
import useUsersHooks from "hooks/useUsersHooks";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import Spacer from "components/Spacer/Spacer";
import { useTranslation } from "react-i18next";
import { UserService } from "services/User/UserService";

interface IProps {
  open: boolean;
  onClose: () => void;
  user: IUserItem | undefined;
}

const UserKycDrawer: FC<IProps> = ({ open, onClose, user }) => {
  const { t } = useTranslation();
  const { kycInformation = {} } = user || {};
  const { identityCardRecto = {}, identityCardVerso = {} } = kycInformation;
  const pathRecto = kycInformation?.identityCardRecto?.path || null;
  const pathVerso = kycInformation?.identityCardVerso?.path || null;
  const [isRejectionDrawerOpen, setRejectionDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    ValidateUserKyc,
    performGetUserList
  } = useUsersHooks({});

  const handleOpenRejectionDrawer = () => {
    setRejectionDrawerOpen(true);
  };

  const handleCloseRejectionDrawer = () => {
    setRejectionDrawerOpen(false);
    onClose();
  };

  const handleKycValidation = (userId: number | undefined) => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      onClose();
    }
    ValidateUserKyc(userId, onSuccess);
  }

  const deleteAccount = async () => {
    if (user?.id && 
      user.askedAccountDeletion === true) {
      await UserService.DeleteUser({ userId: user?.id });
      await performGetUserList();
      onClose();
    }
  }

  const containerStyle = {
    width: '290px',
    height: '186px',
    borderRadius: '10px',
    backgroundColor: '#dcdcdc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      variant="temporary"
    >
      <Grid
        style={{
          width: 350,
          backgroundColor: baseStyles.color.grey,
          paddingBottom: 40,
          padding: 25,
          minHeight: '100vh',
        }}
      >
        {/* Header */}
        <Grid
          style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}
        >
          <Typography fontSize={16} fontWeight={600}>
            {t('user.customerAccount')}
          </Typography>
          <CustomIconButton
            icon={<CloseIcon />}
            onClick={onClose}
          />
        </Grid>

        {/* Profile Section */}
        <Grid
          style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 20 }}
        >
          <Avatar
            alt={`${user?.firstname ?? ""} ${user?.lastname ?? ""}`}
            src={`${process.env.REACT_APP_API_URL}/${user?.profilePicture?.path}`}
            sx={{ width: 150, height: 150 }}
          />
          <Typography 
            style={{
              fontWeight: 700,
              fontSize: 14,
              marginTop: 10,
              color: baseStyles.text.black,
            }}
          >{`${user?.firstname ?? ""} ${user?.lastname ?? ""}`}</Typography>

          <CountryPhoneNumber phoneNumber={user?.phone} />
          <Typography
            style={{ 
              fontSize: 12, 
              fontWeight: 500,
              color: baseStyles.text.default, 
              marginTop: 5 
            }}
          >
            {user?.email}
          </Typography>
        </Grid>

        {/* Document Section */}
        <Typography fontSize={16} fontWeight={600}style={{ marginBottom: 10 }}>
          {t('user.customerKycDocuments')}
        </Typography>

        <Typography 
          fontSize={14} 
          fontWeight={500} 
          style={{ 
            marginBottom: 10,
            color: baseStyles.text.default
        }}>
          {t('user.customerKycRecto')}
        </Typography>

        <div style={containerStyle}>
          {pathRecto ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/${pathRecto}`}
              alt={t('user.customerKycRectoAlt')}
              style={{
                width: 'auto',
                height: '80%'
              }}
            />
          ) : (
            <Typography variant="body2">
              {t('user.customerKycRectoNotAvailable')}
            </Typography>
          )}
        </div>

        <Typography 
          fontSize={14} 
          fontWeight={500} 
          style={{ 
            marginBottom: 10,
            color: baseStyles.text.default
        }}>
          {t('user.customerKycVerso')}
        </Typography>

        <div style={containerStyle}>
          {pathVerso ? (
            <img
              src={`${process.env.REACT_APP_API_URL}/${pathVerso}`}
              alt={t('user.customerKycVersoAlt')}
              style={{
                width: 'auto',
                height: '80%'
              }}
            />
          ) : (
            <Typography variant="body2">
              {t('user.customerKycVersoNotAvailable')}
            </Typography>
          )}
        </div>
        <Spacer height={20} />
        {
            pathRecto && pathVerso &&
            user?.askedAccountDeletion !== true && 
            user?.kycInformation?.isUserValidatedKyc !== true && 
            (
                <NeuromorphBtn
                    width={300}
                    disabled={false}
                    loading={loading}
                    label={t('user.customerValidateKyc')}
                    onClick={() => handleKycValidation(user?.id)}
                />
            )
        }
        {
          pathRecto && pathVerso &&
          user?.askedAccountDeletion !== true &&
          user?.kycInformation?.isUserValidatedKyc !== false &&
          <Button
            variant="text"
            style={{
              textTransform: "none",
              width: 300,
              color: baseStyles.color.error.color,
            }}
            onClick={handleOpenRejectionDrawer}
          >
            {t('user.customerRejectKyc')}
          </Button>
        }
        {user?.askedAccountDeletion === true && <Button
          variant="text"
          style={{
            textTransform: "none",
            width: 300,
            color: baseStyles.color.error.color,
          }}
          onClick={deleteAccount}
        >
          {t('user.deleteAccount')}
        </Button>}
      </Grid>

      {/* Rejection Drawer */}
      <KycRejectionDrawer
        open={isRejectionDrawerOpen}
        onClose={handleCloseRejectionDrawer}
        user={user} // You can pass user if needed
      />
    </Drawer>
  );
};

export default UserKycDrawer;
