import { CircularProgress, Typography } from "@mui/material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import Pagination from "components/Pagination/Pagination";
import useTransactionHooks from "hooks/useTransactionHooks";
import { FC, useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { lastTransactions } from "utils/fakedata";
import { ITransactionFilter } from "utils/interfaces";
import {
  formatDateToAppFromDate,
  formmatDateToApp,
  formmatDateToAppFromDate,
  parseDateFromData,
} from "utils/utils";
import BottomContentItem from "./BottomContentItem";
import BottomContentItemTitle from "./BottomContentItemTitle";
import { usePDF } from "react-to-pdf";
import { useTranslation } from "react-i18next";
interface IProps {
  filter: ITransactionFilter;
  exportToPdf?: number;
}
const BottomContentItems: FC<IProps> = ({ filter, exportToPdf }: IProps) => {
  const {
    totalPage,
    currentPage,
    setCurrentPage,
    loading,
    fetchTransactions,
    dateRange,
    setDateRange,
    transactions,
  } = useTransactionHooks();
  useEffect(() => {
    fetchTransactions(dateRange.minDate, dateRange.maxDate);
  }, []);
  const { toPDF, targetRef } = usePDF({
    filename: `PDF-Transaction-${formatDateToAppFromDate(new Date())}.pdf`,
  });
  const { t } = useTranslation();
  const [filteredTransactions, setFilteredTransactions] =
    useState(transactions);

  useEffect(() => {
    const filtered = transactions
      // .filter((item) => {
      //   const date = parseDateFromData(item.date);
      //   return date >= filter?.startDate && date <= filter?.endDate;
      // })
      // .filter((item) => {
      //   const amount = parseFloat(item.amount);
      //   return amount >= filter.fromAmount && amount <= filter.toAmount;
      // })
      .filter((item) => {
        const keyword = filter.keyword;
        return (
          item?.sender?.email?.toLowerCase()?.includes(keyword) ||
          item?.sender?.firstname?.toLowerCase()?.includes(keyword) ||
          item?.sender?.lastname?.toLowerCase()?.includes(keyword) ||
          item?.sender?.phone?.toLowerCase()?.includes(keyword) ||
          item?.sender?.country?.name?.toLowerCase()?.includes(keyword) ||
          item?.senderAccount?.country?.name
            ?.toLowerCase()
            ?.includes(keyword) ||
          item?.recipient?.firstname?.toLowerCase()?.includes(keyword) ||
          item?.recipient?.lastname?.toLowerCase()?.includes(keyword) ||
          item?.recipient?.owner?.email?.toLowerCase()?.includes(keyword) ||
          item?.receiverCountry?.name?.toLowerCase()?.includes(keyword) ||
          item?.recipient?.account?.country?.name
            ?.toLowerCase()
            ?.includes(keyword)
        );
      })
      .filter((item) => {
        const status = item.status;
        console.log("filter.transfertType", filter.transfertType);

        return filter.transfertType === "BANK" ||
          filter.transfertType === "MOBILE_MONEY"
          ? item.paymentMethod.code == filter.transfertType
          : true;
      });
    setFilteredTransactions(filtered);
  }, [filter]);

  useEffect(() => {
    setDateRange((old) => ({
      minDate: filter.startDate,
      maxDate: filter.endDate,
    }));
  }, [filter.startDate, filter.endDate]);

  useEffect(() => {
    transactions.sort((a, b) => b.id - a.id);
    setFilteredTransactions(transactions);
  }, [transactions]);

  useEffect(() => {
    if (exportToPdf) {
      toPDF();
    }
  }, [exportToPdf]);

  return (
    <div
      style={{
        paddingTop: 20,

        paddingInline: 5,
      }}
    >
      <div style={{ padding: 20 }} ref={targetRef}>
        <BottomContentItemTitle />

        <div
          style={{
            // overflow: "hidden",
            // overflowY: "scroll",
            // height: `calc(23vh + 2px)`,
            // padding: 5,
            marginTop: 20,
          }}
        >
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress style={{ margin: 50 }} />
            </div>
          ) : filteredTransactions.length > 0 ? (
            filteredTransactions?.map((transaction) => (
              <NeomorphContainer
                key={transaction.id}
                style={{ marginBottom: 20 }}
              >
                <BottomContentItem transaction={transaction} />
              </NeomorphContainer>
            ))
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20%",
              }}
            >
              <Typography
                style={{
                  color: baseStyles.text.default,
                  fontSize: 18,
                  textAlign: "center",
                }}
              >
                {t('alert.noTransaction')}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <section style={{ position: "relative", top: 20 }}>
        {totalPage > 1 && (
          <Pagination
            onChange={(current: number) => {
              setCurrentPage(current);
              fetchTransactions(dateRange.minDate, dateRange.maxDate, current);
            }}
            current={currentPage}
            max={totalPage}
          />
        )}
      </section>
    </div>
  );
};

export default BottomContentItems;
