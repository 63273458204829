import { AxiosError } from "axios";
import { AuthContext } from "providers/AuthProvider";
import React, { useEffect, PropsWithChildren } from "react";

import instance from "services/AxiosInstance";

const AxiosInterceptor = ({ children }: PropsWithChildren) => {
  const { logout } = React.useContext(AuthContext);

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };

    const errInterceptor = (error: AxiosError) => {
      console.log("AxiosError", JSON.stringify(error));

      if (error.response && error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => instance.interceptors.response.eject(interceptor);
  }, []);

  return <>{children}</>;
};

export default instance;
export { AxiosInterceptor };
