import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import BottomContentItem from "./BottomContentItem";
import BottomContentItemTitle from "./BottomContentItemTitle";
import { FC, useEffect, useState } from "react";
import CustomPagination from "pages/Journal/Pagination/CustomPagination";
import { CircularProgress, Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import useJournalHooks from "hooks/useJournalHooks";
import { IJournalFilter, JournalItem } from "utils/interfaces";

interface IProps {
  filter: IJournalFilter;
}

const BottomContentItems: FC<IProps> = ({ filter }: IProps) => {
  const [active, setActive] = useState(1);
  const [filteredJounal, setFilteredJournal] = useState<JournalItem[]>([]);
  const {
    dateRange,
    setDateRange,
    getDataJournal,
    journalesData,
    loading,
    paginationData,
  } = useJournalHooks();

  useEffect(() => {
    setDateRange((old) => ({
      minDate: filter.startDate,
      maxDate: filter.endDate,
    }));
  }, [filter.startDate, filter.endDate]);

  useEffect(() => {
    setFilteredJournal(journalesData);
  }, [journalesData]);

  useEffect(() => {
    const _copy = journalesData
      .filter((item) => {
        const k = filter?.keyword?.toLowerCase();
        if (k?.length === 0) {
          return true;
        } else {
          return (
            item?.activity?.toLowerCase()?.includes(k) ||
            item?.name?.toLowerCase()?.includes(k)
          );
        }
      })
      .filter((item) => {
        if (filter?.countryCode?.length === 0 || !filter?.countryCode) {
          return true;
        } else {
          return item?.countryCode === filter?.countryCode;
        }
      });
    setFilteredJournal(_copy);
  }, [filter]);

  // const getJournal = () => {
  //   setLoading(true);
  //   getHistory(active, 5)
  //     .then((res: any) => {
  //       const data = res.data.results;
  //       setPaginationData(res.data.pagination);
  //       if (data) {
  //         const newDataEntries = data.map((item: any) => ({
  //           id: uuidv4(),
  //           name: item.updatedUser.firstname + " " + item.updatedUser.lastname,
  //           profilePicture: item.updatedUser.profilePicture?.path ?? "",
  //           pays: item.country ?? "Non défini",
  //           activity: item.field,
  //           date: formatDateDifference(new Date(item.date)),
  //           status: item.updatedUser.active,
  //         }));
  //         setJournalesData(newDataEntries);
  //       }
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Erreur lors de la récupération des données:", error);
  //     });
  // };

  useEffect(() => {
    getDataJournal(dateRange.minDate, dateRange.maxDate, active);
  }, [active]);

  return (
    <div
      style={{
        paddingTop: 20,
        paddingInline: 5,
      }}
    >
      <BottomContentItemTitle />

      <div
        style={
          {
            // overflow: "hidden",
            // overflowY: "scroll",
            // height: `calc(23vh + 2px)`,
          }
        }
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <CircularProgress
              style={{ color: baseStyles.color.blue.primary }}
              disableShrink
            />
          </div>
        ) : (
          <div>
            {filteredJounal?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20%",
                }}
              >
                <Typography
                  style={{
                    color: baseStyles.text.default,
                    fontSize: 18,
                    textAlign: "center",
                  }}
                >
                  Aucun journal trouvé.
                </Typography>
              </div>
            ) : (
              filteredJounal?.map((journal) => (
                <NeomorphContainer
                  key={journal.id}
                  style={{ marginBottom: 20 }}
                >
                  <BottomContentItem journal={journal} />
                </NeomorphContainer>
              ))
            )}
          </div>
        )}
        {filteredJounal?.length > 0 && (
          <CustomPagination
            setActive={setActive}
            totalPage={paginationData.total_pages}
          />
        )}
      </div>
    </div>
  );
};

export default BottomContentItems;
