import { CameraAlt, Delete } from "@mui/icons-material";
import { ButtonBase, Grid } from "@mui/material";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";
import { wave } from "utils/fakedata";
import { PostPutOperatorPayload } from "utils/interfaces";
import { convertImageToBase64 } from "utils/utils";

interface IProps {
  operator?: any;
  setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
}

const OperatorLogoForm: FC<IProps> = ({ operator, setPayload }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      try {
        const base64String = await convertImageToBase64(fileUploaded);
        setPayload((prev) => ({ ...prev, logo: base64String ?? "" }));
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error("File Error Please provide an Image file");
      }
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <input
        type={"file"}
        hidden
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />

      {operator ? (
        <NeomorphContainer
          style={{
            background: baseStyles.color.grey,
            padding: 15,
            display: "flex",
            margin: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
          width={310}
          height={75}
        >
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              background: "transparent",
              justifyContent: "space-between",
            }}
          >
            <img src={wave} style={{ height: 40 }} alt="delete" />
            <CustomIconButton
              rounded={false}
              onClick={() => {}}
              icon={<Delete htmlColor={baseStyles.text.default} />}
            />
          </div>
        </NeomorphContainer>
      ) : (
        <ButtonBase
          onClick={handleClick}
          style={{ borderRadius: operator ? 85 : 5 }}
        >
          <NeomorphContainer
            style={{
              background: baseStyles.color.blue.primary40,
              padding: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            width={300}
            height={75}
          >
            <Grid display={"flex"} direction={"row"} alignItems="center">
              <NeomorphContainer
                elevation={0.7}
                style={{
                  background: baseStyles.color.blue.primary40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                width={40}
                height={40}
              >
                <CameraAlt htmlColor="white" />
              </NeomorphContainer>

              <p style={{ color: "white", marginLeft: 10 }}>
                {t('user.imageDown')}
              </p>
            </Grid>
          </NeomorphContainer>
        </ButtonBase>
      )}
    </div>
  );
};

export default OperatorLogoForm;
