import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#6E847E"
      fillRule="evenodd"
      d="M2.938 7.066v-1.54a5.063 5.063 0 0 1 10.124 0v1.54c.837.063 1.381.22 1.779.618.659.66.659 1.72.659 3.841 0 2.122 0 3.182-.659 3.841-.659.66-1.72.66-3.841.66H5c-2.121 0-3.182 0-3.841-.66C.5 14.707.5 13.646.5 11.526c0-2.122 0-3.183.659-3.842.398-.398.942-.555 1.778-.618Zm1.124-1.54a3.938 3.938 0 0 1 7.875 0v1.502c-.287-.003-.598-.003-.937-.003H5c-.339 0-.65 0-.938.003V5.525Zm5.438 6a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as PasswordIcon };
