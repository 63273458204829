import { FakeSelect } from "components/FakeSelect/FakeSelect";
import React, { useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import countryStyle from "../CountryStyle";
import Spacer from "components/Spacer/Spacer";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { useScreenType } from "utils/constants/UseScreenType";
import { AddBox } from "@mui/icons-material";
import CountryItems from "./CountryItems/CountryItems";
import useCountryHooks from "hooks/useCountryHooks";
import { Box, Dialog, DialogContent, Modal, Typography } from "@mui/material";
import { CustomInput } from "components/InputFieldSearch/CustomInput";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { CountryItem, IZone } from "utils/interfaces";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { reversedIso3 } from "utils/utils";
import { FaTrash } from "react-icons/fa";
import DeleteIcon from "components/Icons/DeleteIcon";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import { ZoneSelectOptions } from "components/FakeSelect/ZoneSelectOptions";
import { useTranslation } from "react-i18next";

const CountryList = () => {
  const classes = countryStyle();
  const { isTab } = useScreenType();
  const {t} = useTranslation();
  const [openCreateCountryModal, setOpenCreateCountryModal] = useState(false);
  const [openDetailCountryModal, setOpenDetailCountryModal] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [key, setKey] = useState("");
  const [selectedZone, setSelectedZone] = useState<IZone | undefined>(
    undefined
  );
  const {
    FetchCountries,
    FetchCountriesZones,
    loading,
    zones,
    countries,
    UpdateCountry,
  } = useCountryHooks();
  const [filteredModalCountries, setFilteredModalCountries] = useState<
    CountryItem[]
  >([]);
  const [filteredCountries, setFilteredCountries] = useState<CountryItem[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<
    CountryItem | undefined
  >(undefined);
  useEffect(() => {
    FetchCountries();
    FetchCountriesZones();
  }, []);

  useEffect(() => {
    setFilteredModalCountries(
      countries.filter((item) =>
        item.name?.toLowerCase().includes(key.toLowerCase())
      )
    );
  }, [key]);

  useEffect(() => {
    setFilteredModalCountries(countries);
    setFilteredCountries(countries);
  }, [countries]);

  const handleUpdateCountry = () => {
    if (!selectedCountry) {
      alert("No country to update");
      return;
    }

    UpdateCountry(selectedCountry, closeAllDialog);
  };

  useEffect(() => {
    console.log("selectedZone is", selectedZone);
    if (selectedZone) {
      const filtered = countries.filter((item) => {
        return item?.zone?.zoneCode === selectedZone?.zoneCode;
      });
      setFilteredCountries(filtered);
    } else {
      setFilteredCountries(countries);
    }
  }, [selectedZone]);

  const closeAllDialog = () => {
    setOpenConfirmDelete(false);
    setOpenDetailCountryModal(false);
    setOpenCreateCountryModal(false);
  };

  return (
    <div>
      <p className={classes.textTitle}>{t('country.different')}</p>
      <Spacer />
      <div className={classes.listHead}>
        <div style={{ width: "70%" }}>
          <ZoneSelectOptions
            label=""
            placeholder={t('country.all')}
            items={zones}
            onchange={(item: IZone) => {
              setSelectedZone(item);
            }}
            zoneId={selectedZone?.id}
          />
        </div>
        <div style={{ width: "29%" }}>
          <NeuromorphBtn
            disabled={loading}
            startIcon={<AddBox />}
            label={t('country.add')}
            onClick={() => {
              setSelectedCountry(undefined);
              setOpenCreateCountryModal(true);
            }}
            width={"100%"}
          />
        </div>
      </div>
      <Spacer />
      <div>
        <CountryItems
          loading={loading}
          handleSelect={(country: CountryItem) => {
            setSelectedCountry(country);
            setOpenDetailCountryModal(true);
          }}
          countries={filteredCountries?.filter((item) => item.supported)}
        />
      </div>
      <Modal
        style={{
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={openCreateCountryModal}
        onClose={() => {
          setOpenCreateCountryModal(false);
        }}
      >
        <Box
          sx={{
            width: 460,
            height: 530,
            borderRadius: 2,
            padding: 2,
            background: baseStyles.color.grey,
          }}
        >
          <div
            style={{
              background: baseStyles.color.grey,
            }}
          >
            <h2 id="parent-modal-title">{t('country.add')}</h2>
            <Spacer />
            <NeomorphInputWithIcon
              placeholder={t('input.search')}
              onChange={(e: any) => {
                setKey(e);
              }}
              withoutPlaceHolder={true}
            />
          </div>
          <Spacer />
          <div
            style={{
              paddingTop: 10,
              overflowY: "auto",
              height: 300,
            }}
          >
            <CountryItems
              horizontal
              activeCountry={selectedCountry}
              loading={loading}
              handleSelect={(country: CountryItem) =>
                setSelectedCountry({ ...country, supported: true })
              }
              countries={filteredModalCountries?.filter(
                (item) => !item.supported
              )}
            />
          </div>
          <Spacer />
          <div
            style={{
              background: baseStyles.color.grey,
            }}
          >
            <NeuromorphBtn
              disabled={!selectedCountry || loading}
              label={t('country.confirm')}
              onClick={() => {
                handleUpdateCountry();
              }}
              width={"100%"}
            />
          </div>
        </Box>
      </Modal>

      <Modal
        style={{
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={openDetailCountryModal}
        onClose={() => {
          setOpenDetailCountryModal(false);
        }}
      >
        <Box
          sx={{
            width: 400,
            // height: 300,
            overflowY: "auto",
            borderRadius: 2,
            padding: 2,
            background: baseStyles.color.grey,
          }}
        >
          <h2>{t('country.details')}</h2>
          <Spacer />
          <span style={{ color: baseStyles.text.default }}>{t('title.country')} : </span>
          <NeomorphContainer
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>
                <b>{selectedCountry?.name}</b>
              </span>
              <img
                src={`https://flagcdn.com/h20/${reversedIso3[
                  selectedCountry?.code ?? "AF"
                ]?.toLowerCase()}.png`}
                width={30}
                height={20}
              />
            </div>
          </NeomorphContainer>
          <Spacer />
          <span style={{ color: baseStyles.text.default }}>
            {t('title.zone')} :{" "}
          </span>
          <NeomorphContainer
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              marginTop: 10,
            }}
          >
            <span>
              <b>{t('input.undefined')}</b>
            </span>
          </NeomorphContainer>
          <Spacer />
          <span style={{ color: baseStyles.text.default }}>{t('title.money')} : </span>
          <NeomorphContainer
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 45,
              marginTop: 10,
            }}
          >
            <span>
              <b>{`${selectedCountry?.currency?.name ?? t('input.undefined')} (${
                selectedCountry?.currency?.code
              })`}</b>
            </span>
          </NeomorphContainer>
          <Spacer />
          <NeuromorphBtn
            background={baseStyles.color.grey}
            startIcon={<FaTrash />}
            textColor={baseStyles.color.error.color}
            width={370}
            label={t('country.delete')}
            onClick={() => {
              if (selectedCountry) {
                setOpenConfirmDelete(true);
              }
            }}
          ></NeuromorphBtn>
        </Box>
      </Modal>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={openConfirmDelete}
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{t('country.deleteOne')} </h3>
            <CustomIconButton
              disabled={loading}
              icon={<CloseIcon style={{ marginLeft: 2 }} />}
              onClick={() => {
                setOpenConfirmDelete(false);
              }}
            ></CustomIconButton>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyItems: "center",
              margin: 30,
            }}
          >
            <DeleteIcon />
          </div>

          <Typography textAlign={"center"} color={baseStyles.text.default}>
            {t('country.deleteQuest')}
          </Typography>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <NeuromorphBtn
              startIcon={<CloseIcon />}
              textColor={baseStyles.text.default}
              background={baseStyles.color.grey}
              width={160}
              disabled={loading}
              label={t('input.cancel')}
              onClick={() => {
                setOpenConfirmDelete(false);
              }}
            ></NeuromorphBtn>
            <NeuromorphBtn
              startIcon={<FaTrash />}
              background={baseStyles.color.error.color}
              width={160}
              label={t('input.delete')}
              loading={loading}
              disabled={loading}
              onClick={() => {
                if (selectedCountry) {
                  setOpenDetailCountryModal(false);
                  UpdateCountry(
                    { ...selectedCountry, supported: false },
                    closeAllDialog
                  );
                }
              }}
            ></NeuromorphBtn>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CountryList;
