import React from 'react';
import Flag from 'react-world-flags';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import { useTranslation } from 'react-i18next';
import { Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";

countries.registerLocale(en); // Register the locale for country names
countries.registerLocale(fr);

interface PhoneProps {
  phoneNumber?: string;
  showPhoneNumber?: boolean
}

const CountryPhoneNumber: React.FC<PhoneProps> = ({ phoneNumber, showPhoneNumber = true}) => {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const formatPhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return "";

    const cleaned = phoneNumber.replace(/[^\d+]/g, '');
    const match = cleaned.match(/^(\+\d{1,3})(\d+)$/);
  
    if (match) {
      const countryCode = match[1]; // Country code part
      const numberPart = match[2]; // Remaining digits
  
      let groupedNumber;

      if (numberPart.length === 9) {
        // Format as xx-xx-xxx-xx
        groupedNumber = `${numberPart.slice(0, 2)}-${numberPart.slice(2, 4)}-${numberPart.slice(4, 7)}-${numberPart.slice(7, 9)}`;
      } else {
        // Group the remaining digits into pairs if not 9 digits
        groupedNumber = numberPart.match(/.{1,2}/g)?.join('-');
      }
  
      return `${countryCode}-${groupedNumber}`;
    }
  
    // Return cleaned number if no match
    return phoneNumber;
  };

  if (phoneNumber) {

    const phoneNumberInfo = parsePhoneNumberFromString(phoneNumber);
    const countryCode = phoneNumberInfo?.country || '';
    
    // Use i18n-iso-countries to get the country name by code
    const countryName = countries.getName(countryCode, currentLanguage) || '';

      return (
          <>
              <div style={{ 
                display: 'flex', 
                alignItems: 
                'center', 
                marginTop: '5px'
              }}>
                  {countryCode && (
                      <Flag 
                          code={countryCode} 
                          style={{ width: 24, marginRight: 8 }} 
                          alt={countryName} 
                      />
                  )}
                    <Typography 
                      style={{ 
                        fontSize: 12, 
                        fontWeight: 500, 
                        color: baseStyles.text.default 
                      }}
                    >
                      {countryName ?? ""}
                    </Typography>
              </div>
              {showPhoneNumber && phoneNumber && (
                  <Typography 
                    style={{ 
                      fontSize: 12, 
                      fontWeight: 500,
                      color: baseStyles.text.
                      default, 
                      marginTop: 5 
                    }}>
                  {formatPhoneNumber(phoneNumber)}
                </Typography>
              )}
          </>
      );
    };
    return (<></>);
  }

export default CountryPhoneNumber;
