import { Dispatch, SetStateAction, useContext, useState } from "react";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import {
  PartialOperatorPayload,
  PostPutOperatorPayload,
} from "utils/interfaces";
import { OperatorService } from "services/Operators/OperatorService";
import { OperatorContext } from "providers/OperatorProvider";
import { useTranslation } from "react-i18next";
export const defaultOperatorPayload = {
  name: "",
  countryCode: "",
  active: 1,
  logo: "",
  phone: "",
  email: "",
};
const useOperatorsHooks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [deleting, setDeleting] = useState<boolean>(false);
  const { updateOperatorList, setUpdateOperatorList } =
    useContext(OperatorContext);
  const [operators, setOperators] = useState<any>([]);

  const CreateOperator = async ({
    payload,
    toggleDrawer,
    setPayload,
  }: {
    payload: PostPutOperatorPayload;
    toggleDrawer?: Function;
    setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
  }) => {
    setLoading(true);
    const copy = { ...payload } as PartialOperatorPayload;
    if (copy.email?.length === 0) {
      delete copy?.email;
    }

    if (copy.phone?.length === 0) {
      delete copy?.phone;
    }
    try {
      const response = await OperatorService.PostOperator(
        copy as PostPutOperatorPayload
      );
      if (response.status === 200) {
        toast.success(t('alert.successOperator'));
        setLoading(false);
        setPayload(defaultOperatorPayload);
        toggleDrawer?.();
        setUpdateOperatorList((prev) => !prev);
      }
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t('alert.errorCreate'));
      console.log(data);
      setLoading(false);
    }
  };

  const UpdateOperator = async ({
    operatorId,
    payload,
    toggleDrawer,
    setPayload,
  }: {
    operatorId: number;
    payload: PostPutOperatorPayload;
    toggleDrawer?: Function;
    setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
  }) => {
    setLoading(true);
    const copy = { ...payload } as PartialOperatorPayload;
    if (copy.email?.length === 0) {
      delete copy?.email;
    }

    if (copy.phone?.length === 0) {
      delete copy?.phone;
    }
    try {
      const response = await OperatorService.PutOperator({
        operatorId,
        payload: copy as PostPutOperatorPayload,
      });

      if (response.status === 200) {
        toast.success(t('alert.modifiedSuccess'));
        setUpdateOperatorList((prev) => !prev);
        setPayload(defaultOperatorPayload);
        setLoading(false);
        toggleDrawer?.();
      }
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t('alert.errorModify'));
      console.log(data);
      setLoading(false);
    }
  };

  const FetchOperator = async (providerCode?: string) => {
    setLoading(true);

    try {
      const response = await OperatorService.GetOperators(providerCode);
      if (response?.data) {
        setOperators(response?.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      setLoading(false);
    }
  };

  const FetchOperatorProviders = async () => {
    setLoading(true);

    try {
      const response = await OperatorService.GetOperatorsProviders();
      if (response?.data) {
        // setOperators(response?.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      setLoading(false);
    }
  };

  const DeleteOperator = async ({
    operatorId,
    toggleDrawer,
  }: {
    operatorId: number;
    toggleDrawer?: Function;
  }) => {
    setDeleting(true);

    try {
      const response = await OperatorService.DeleteOperator({
        operatorId,
      });

      if (response.status === 200) {
        toast.success(t('alert.successDelete'));
        setUpdateOperatorList((prev) => !prev);
        setDeleting(false);
        toggleDrawer?.();
      }
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t('alert.errorDelete'));
      console.log(data);
      setDeleting(false);
    }
  };

  return {
    CreateOperator,
    UpdateOperator,
    FetchOperator,
    DeleteOperator,

    loading,
    deleting,
    updateOperatorList,
    operators,
    FetchOperatorProviders,
    defaultOperatorPayload,
  };
};

export default useOperatorsHooks;
