import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
import { baseStyles } from "utils/constants/baseStyles";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    ref={ref}
    {...props}
  >
    <g>
      <path
        fill={props.color ?? "#6E847E"}
        d="M2.5 12.5c0-4.714 0-7.071 1.464-8.536C5.43 2.5 7.786 2.5 12.5 2.5c4.714 0 7.071 0 8.535 1.464C22.5 5.43 22.5 7.786 22.5 12.5c0 4.714 0 7.071-1.465 8.535C19.572 22.5 17.214 22.5 12.5 22.5s-7.071 0-8.536-1.465C2.5 19.572 2.5 17.214 2.5 12.5Z"
      />
      <path
        fill={props.color ? baseStyles.color.blue.primary : "#fff"}
        fillRule="evenodd"
        d="M12.5 7.75a.75.75 0 0 1 .75.75v3.69l2.28 2.28a.75.75 0 1 1-1.06 1.06l-2.5-2.5a.75.75 0 0 1-.22-.53v-4a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={24}
        height={25}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1147_854" />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as JournalIcon };
