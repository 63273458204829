import Spacer from "components/Spacer/Spacer";
import { FC } from "react";
import { TextField } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import "./input_simple.css";

interface IProps {
  label?: string;
  placeholder?: string;
  onChange: Function;
  width?: any;
  height?: number;
  isPassword?: boolean;
  isEmail?: boolean;
  withoutPlaceHolder?: boolean;
}

const NeomorphInput: FC<IProps> = ({
  label,
  placeholder,
  onChange,
  width,
  height,
  isPassword,
  isEmail,
  withoutPlaceHolder,
}) => {
  const styles = {
    width: width ?? 400,
    height: height ?? 50,
    fontSize: 12,
  };

  return (
    <div>
      {!withoutPlaceHolder && (
        <>
          <p
            style={{
              color: baseStyles.text.default,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {label} :{" "}
          </p>

          <Spacer height={10} />
        </>
      )}

      <TextField
        type={isPassword ? "password" : isEmail ? "email" : "text"}
        className="neomorphe-input"
        style={styles}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default NeomorphInput;
