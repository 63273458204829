import { OutlinedInput } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { CssTextField } from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { baseStyles } from "utils/constants/baseStyles";
import { getStartAndEndOfYear } from "utils/utils";

type DateRangeInputProps = {
  visible?: boolean;
  toggle?: Function;
  onchange?: (e: any) => any;
};

export const CustomDateRangePicker = ({
  visible,
  toggle,
  onchange,
}: DateRangeInputProps) => {
  const [range, setRange] = useState({
    end: new Date(),
    start: getStartAndEndOfYear().start,
  });
  useEffect(() => {
    onchange?.(range);
    toggle?.();
  }, [range]);

  return (
    <div
      style={{
        // display: visible ? "flex" : "none",
        position: "relative",
        background: baseStyles.color.grey,
      }}
    >
      {visible && (
        <NeomorphContainer
          height={90}
          style={{ position: "absolute", right: "20%", top: 70, width: 500 }}
        >
          <>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                borderRadius: 8,
                padding: 10,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <NeomorphContainer
                  width={200}
                  height={50}
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    padding: 0,
                  }}
                  inset
                >
                  <DatePicker
                    slots={{ textField: CssTextField }}
                    value={dayjs(range.start)}
                    maxDate={dayjs(range.end)}
                    onChange={(date) => {
                      if (date) {
                        setRange((old: any) => ({
                          ...old,
                          start: date.toDate(),
                        }));
                      }
                    }}
                  />
                </NeomorphContainer>
                <Spacer />
                <FaArrowRight />
                <Spacer />
                <NeomorphContainer
                  width={200}
                  height={50}
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    padding: 0,
                  }}
                  inset
                >
                  <DatePicker
                    slots={{ textField: CssTextField }}
                    minDate={dayjs(range.start)}
                    maxDate={dayjs(new Date())}
                    value={dayjs(range.end)}
                    onChange={(date) => {
                      if (date) {
                        setRange((old: any) => ({
                          ...old,
                          end: date.toDate(),
                        }));
                      }
                    }}
                  />
                </NeomorphContainer>
              </LocalizationProvider>
            </div>
          </>
        </NeomorphContainer>
      )}
    </div>
  );
};
