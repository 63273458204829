import { FC, useState } from "react";
import JournalStyles from "./JournalStyles";
import { Box } from "@mui/material";
import { CustomInput } from "components/InputField/CustomInput";
import NeomorphInput from "components/InputField/NeomorphInput";
import { Button } from "ui-neumorphism";
import NeomorphRechercheInput from "components/InputField/NeomorphRechercheInput";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import BottomContent from "./BottomContent/BottomContent";
import NeomorphInputSearch from "components/InputFieldSearch/NeomorphInputSearch";
import Pagination from "./Pagination/Pagination";
import JournalHeader, {
  defaultJournalFilter,
} from "./JournalHeader/JournalHeader";

interface IProps {}

const Journal: FC<IProps> = (props: IProps) => {
  const [filter, setFilter] = useState(defaultJournalFilter);

  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <JournalHeader handleChangeFilter={setFilter} />
      <div>
        <BottomContent filter={filter} />
      </div>
    </div>
  );
};

export default Journal;
