import { FC, ReactElement } from "react";
import Popover from "@mui/material/Popover";

interface IProps {
  open: boolean;
  handleClose: any;
  children: ReactElement;
  anchorEl: null | HTMLElement;
  position?: "left" | "right" | "center";
}

const MenuPopover: FC<IProps> = ({
  position,
  anchorEl,
  open,
  handleClose,
  children,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: position ?? "center",
      }}
      style={{ borderRadius: 10 }}
    >
      {children}
    </Popover>
  );
};

export default MenuPopover;
