import axios from "services/AxiosInstance";
import { CountryItem } from "utils/interfaces";
import { capitalizeFirstLetter } from "utils/utils";

const GetCountries = () => {
  return axios.get("/api/countries/");
};

const GetCurrencies = () => {
  return axios.get("/api/currencies/");
};

const UpdateCountry = (country: CountryItem) => {
  return axios.put(`/api/countries/${country.id}`, {
    supported: `${capitalizeFirstLetter(country.supported.toString())}`,
  });
};

const GetCountriesZone = () => {
  return axios.get("/api/countries/zones");
};

export const CountryService = {
  GetCountries,
  UpdateCountry,
  GetCurrencies,
  GetCountriesZone,
};
