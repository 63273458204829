export const baseStyles = {
  color: {
    primaire: "#52C3FF",
    secondaire: "#7383C0",
    tertiaire: "#5AE2E2",
    quaternaire: "#F9AE2B",

    grey: "#E7EBF0",
    blue: {
      primary: "#52C3FF",
      primary40: "#011D7B66",
      background: "#011D7B",
      background80: "rgba(1,29,123,0.7)",
    },
    green: {
      primary: "#0CD97B",
      dark: "#15bf71",
    },
    white: "#fff",
    success: {
      main: "#0CD97B",
      border: "#0CD97B33",
      color: "#0CD97B",
      bg: "#0CD97B1A",
    },
    warning: {
      main: "#FFB800",
      border: "#FFB80033",
      color: "#FFB800",
      bg: "#FFB8001A",
      icon: "#F9AE2B",
    },
    error: {
      main: "#FF5A60",
      bg: "#FF5A601A",
      color: "#FF5A60",
      border: "#FF5A6033",
    },
    info: {
      main: "#293F8A",
      border: "#293F8A33",
      color: "#293F8A",
      bg: "#293F8A1A",
    },
  },
  text: {
    default: "#6E847E",
    black: "#131A21",
  },
};
