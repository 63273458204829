import { baseStyles } from "utils/constants/baseStyles";

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh)",
    minHeight: 800,
  },
  left: {
    display: "flex",
    flexDirection: "column",
    flex: 1 / 2,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  right: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: baseStyles.color.grey,
    flex: 1 / 2,
    padding: 20,
  },
  content: {},
  title: {
    color: baseStyles.text.black,
    fontSize: 32,
    fontWeight: 900,
    textAlign: "center",
  },
  description: {
    color: baseStyles.text.default,
    fontSize: 18,
    textAlign: "center",
  },
  goBack: {
    cursor: "pointer",
    position: "absolute",
    left: "52%",
    top: "2%",
  },
  
};

export default styles;
