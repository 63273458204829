import React, { useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";

type ChoisLanguageProps = {
  key: number | string;
  flag: any;
  title: string;
  active: boolean;
  onClick: any;
};
const ChoisLanguage: React.FC<ChoisLanguageProps> = ({
  key,
  flag,
  title,
  active,
  onClick,
}) => {
  

  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        height: "42px",
        width: "132px",
        border: "2px solid",
        borderColor: active ? "#000" : "#131A2133",
        borderRadius: "80px",
        padding: "0px 15px 0px 5px"
      }}
      onClick={onClick}
      key={key}
    >
      {flag}
      <span style={{ color: active ? "#000" : "#131A2133", fontSize: "18px", fontWeight: 500 }}>
        {title}
      </span>
    </div>
  );
};

export default ChoisLanguage;
