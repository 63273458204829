import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { Avatar, ButtonBase } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { baseStyles } from "utils/constants/baseStyles";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { convertImageToBase64 } from "utils/utils";
import toast from "react-hot-toast";
import { EditConnectedUserInfoPayload } from "utils/interfaces";
import { UserContext } from "providers/UserProvider";

interface IProps {
  setPayload: Dispatch<SetStateAction<EditConnectedUserInfoPayload>>;
}

const MyAvatarContent: FC<IProps> = ({ setPayload }) => {
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { currentUser } = useContext(UserContext);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      try {
        const base64String = await convertImageToBase64(fileUploaded);
        setProfilePicture(base64String);
        setPayload((prev) => ({ ...prev, profilePicture: base64String ?? "" }));
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error("File Error Please provide an Image file");
      }
    }
  };

  useEffect(() => {
    if (currentUser?.profilePicture) {
      setProfilePicture(
        `${process.env.REACT_APP_API_URL}/${currentUser?.profilePicture?.path}`
      );
    }
  }, [currentUser]);

  return (
    <div
      style={{
        position: "relative",
        marginInlineEnd: 40,
        marginTop: 20,
      }}
    >
      <input
        type={"file"}
        hidden
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />

      <NeomorphContainer
        elevation={0}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 180,
          background: "white",
        }}
        width={190}
        height={190}
      >
        <Avatar src={profilePicture} sx={{ height: 180, width: 180 }} />
      </NeomorphContainer>

      <ButtonBase
        onClick={handleClick}
        style={{
          borderRadius: 32,
          position: "absolute",
          left: 150,
          top: 140,
          borderWidth: 2,
          borderColor: "red",
          background: "white",
          padding: 3,
        }}
      >
        <Edit
          style={{
            background: baseStyles.color.blue.primary40,
            borderRadius: 20,
            padding: 5,
          }}
          htmlColor={"white"}
          sx={{ width: 32, height: 32 }}
        />
      </ButtonBase>
    </div>
  );
};
export default MyAvatarContent;
