import { Drawer, Grid, Typography } from "@mui/material";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import Spacer from "components/Spacer/Spacer";
import { FC, useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { IOperatorFilter } from "utils/interfaces";
import OperatorForm from "./OperatorForm/OperatorForm";
import OperatorFilter from "./OperatorsFilter/OperatorFilters";
import OperatorItems from "./UserItems/OperatorItems";
import { useTranslation } from "react-i18next";
export const defaultOperatorFilter: IOperatorFilter = {
  keyword: "",
  operator: null,
  zone: null,
};
interface IProps {}

const Operators: FC<IProps> = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [selectedOperator, setSelectedOperator] = useState<any>(null);
  const [filter, setFilter] = useState<IOperatorFilter>(defaultOperatorFilter);
  useEffect(() => {
    if (selectedOperator) {
      setOpenModal(true);
    }
  }, [selectedOperator]);

  const toggleDrawer = () => setOpenModal(!openModal);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 20,
      }}
    >
      <section>
        <OperatorFilter
          handleChangeFilter={setFilter}
          onClickCreateBtn={() => {
            setSelectedOperator(null);
            setOpenModal(true);
          }}
        />
      </section>

      <Spacer height={35} />

      <section>
        <OperatorItems
          filter={filter}
          onClickDetails={(data: any) => {
            setSelectedOperator(data);
          }}
        />
      </section>

      {/* <section style={{ position: "relative", top: 20 }}>
        <Pagination />
      </section> */}

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setSelectedOperator(null);
          setOpenModal(false);
        }}
      >
        <Grid
          style={{
            width: 400,
            background: baseStyles.color.grey,
            paddingBottom: 40,
            padding: 25,
          }}
        >
          <Grid
            style={{ display: "flex" }}
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontSize={18} fontWeight={600}>
              {selectedOperator ? t('operator.details') : t('operator.add')}
            </Typography>

            <CustomIconButton
              icon={<CloseIcon />}
              onClick={() => setOpenModal(false)}
            />
          </Grid>
          <OperatorForm
            operator={selectedOperator}
            toggleDrawer={toggleDrawer}
          />
        </Grid>
      </Drawer>
    </div>
  );
};

export default Operators;
