import { FC, useContext, useEffect, useMemo, useState } from "react";
import UserItems from "./UserItems/UserItems";
import UserInputFilters from "./UserInputFilters/UserInputFilters";
import Spacer from "components/Spacer/Spacer";
import Pagination from "../../components/Pagination/Pagination";
import { ButtonBase, CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import useUsersHooks from "hooks/useUsersHooks";
import { IUserItem } from "utils/interfaces";
import { UserContext } from "providers/UserProvider";
import { useTranslation } from "react-i18next";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import UserKycDrawer from "./UserDrawers/UserKycDrawer";

interface IProps { }

const Count = ({ count }: { count: number }) => <div style={{
  marginLeft: 5,
  padding: 5,
  backgroundColor: baseStyles.color.quaternaire,
  borderRadius: 50,
  boxShadow: `
  5px 5px 10px 0px #AEAEC04D,
  -5px -5px 10px 0px #FFFFFF,
  2px 2px 4px 0px #FFFFFF inset,
  -2px -2px 4px 0px #0000001A inset
`,
}}>
  <Typography
    fontSize={11}
    fontWeight={"600"}
    color={baseStyles.color.white}
  >
    {count}
  </Typography>
</ div>

const Customers: FC<IProps> = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<IUserItem | undefined>(
    undefined
  );

  const [openKycDrawer, setOpenKycDrawer] = useState(false);

  const {
    activeFilter,
    setActiveFilter,
    countData,
    loading,
    currentPage,
    performGetUserList,
    totalPage,
    setCurrentPage,
    perfGetUserStatusStat,
  } = useUsersHooks({});
  const { filteredUsers } = useContext(UserContext);
  useEffect(() => {
    if (selectedUser) {
      setOpenKycDrawer(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    perfGetUserStatusStat();
    performGetUserList(1, "&role=customer");
  }, []);

  const filtersCustomers = useMemo(() => [
    {
      key: "all",
      title: t('user.all-customers'),
      onClick: () => performGetUserList(1, "&role=customer"),
    },
    {
      key: "new",
      title: t('user.new-customers'),
      count: countData.new,
      onClick: () => performGetUserList(1, "&role=customer&status=new"),
    },
    {
      key: "dmd_delete",
      title: t('user.dmd-delete-compte-customers'),
      count: countData.annulation,
      onClick: () => performGetUserList(1, "&role=customer&status=annulation"),
    }
  ], [countData.annulation, countData.new, t]);


  return (
    <div>
      <Grid
        display={"flex"}
        direction={"row"}
        alignContent="center"
        justifyContent={"flex-start"}
        style={{ marginLeft: 20 }}
      >
        {filtersCustomers.map((item) =>
          item.key === activeFilter ? (
            <div key={item.key} style={{ display: "flex" }} onClick={item.onClick}>
              <NeomorphContainer
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 5,
                }}
                // width={120}
                height={40}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  <Typography
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 11,
                    }}
                    fontWeight={"600"}
                    color={baseStyles.text.black}
                  >
                    {item.title}
                  </Typography>
                </div>

              </NeomorphContainer>

            </div>
          ) : (
            <ButtonBase
              key={item.title}
              onClick={() => {
                setActiveFilter(item.key);
                item.onClick()
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 5,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <Typography
                  fontSize={11}
                  fontWeight={"600"}
                  color={baseStyles.text.default}
                >
                  {item.title}
                </Typography>
                {item.count && Number(item.count) > 0 ? <Count count={item.count} /> : ''}
              </div>
            </ButtonBase>
          )
        )}
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: 20,
        }}
      >
        <section>
          <UserInputFilters
            onClickCreateBtn={() => {
              setSelectedUser(undefined);
              setOpenModal(true);
            }}
          />
        </section>

        <Spacer />

        <section>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              <CircularProgress
                style={{ color: baseStyles.color.blue.primary }}
                disableShrink
              />
            </div>
          ) : (
            <UserItems
              users={filteredUsers}
              onClickDetails={(user: any) => {
                setSelectedUser(user);
              }}
            />
          )}
        </section>
        <section style={{ position: "relative", top: 20 }}>
          {totalPage > 1 && (
            <Pagination
              onChange={(current: number) => {
                setCurrentPage(current);
                performGetUserList(current);
              }}
              current={currentPage}
              max={totalPage}
            />
          )}
        </section>
        <Drawer
          anchor={"right"}
          open={openModal}
          onClose={() => {
            setSelectedUser(undefined);
            setOpenModal(false);
          }}
          variant={loading ? "persistent" : "temporary"}
        >
          <Grid
            style={{
              width: 350,
              background: baseStyles.color.grey,
              paddingBottom: 40,
              padding: 25,
            }}
          >
            {/* header */}
            <Grid
              style={{ display: "flex" }}
              flexDirection="row"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography fontSize={18} fontWeight={600}>
                {selectedUser ? t('user.account') : t('user.createOne')}
              </Typography>
              <CustomIconButton
                disabled={loading}
                icon={<CloseIcon />}
                onClick={() => {
                  setSelectedUser(undefined);
                  setOpenModal(false);
                }}
              ></CustomIconButton>
            </Grid>
          </Grid>

        </Drawer>
        <UserKycDrawer
          open={openKycDrawer}
          onClose={() => {
            setSelectedUser(undefined);
            setOpenKycDrawer(false);
            performGetUserList(currentPage);
            performGetUserList(1, "&role=customer");
          }}
          user={selectedUser}
        />
      </div>
    </div>
  );
};

export default Customers;
