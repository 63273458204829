import { useState } from "react";
import { AxiosError } from "axios";
import { CountryService } from "services/Country/Country.service";
import { CountryItem, Currency } from "utils/interfaces";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const useCurrencyHooks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const { t } = useTranslation();

  const fetchCurrencies = async () => {
    setLoading(true);

    try {
      const response = await CountryService.GetCurrencies();
      if (response?.data) {
        setCurrencies(response?.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      toast.error(
        t('alert.errorDevise'),
        { id: "ErrorDevise" }
      );
      setLoading(false);
    }
  };

  return {
    fetchCurrencies,
    loading,
    currencies,
  };
};

export default useCurrencyHooks;
