import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import useAuthenticationHooks from "hooks/useAuthenticationHooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { PostLoginPayload } from "services/Login/LoginService";
import Spacer from "components/Spacer/Spacer";
import { baseStyles } from "utils/constants/baseStyles";
import { isValidEmail, minLengthValidator } from "utils/utils";
import loginLogo from "assets/images/login-img.png";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { useScreenType } from "utils/constants/UseScreenType";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";

const LoginMobile = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();
  const { performLogin, loading } = useAuthenticationHooks();
  const [payload, setPayload] = useState<PostLoginPayload>({
    username: "", //admin@admin.com
    password: "", //123456
  });

  const disableBtn =
    !isValidEmail(payload.username) || !minLengthValidator(payload.password, 6);

  const onEmailChanged = (e: any) => {
    setPayload((prev) => ({ ...prev, username: e }));
  };
  const onPasswordChanged = (e: any) => {
    setPayload((prev) => ({ ...prev, password: e }));
  };

  const handleLogin = () => performLogin(payload);
  return (
    <CustomAuthContent
      btnLabel={t("login.connect")}
      logo={loginLogo}
      title={t("login.title")}
      onBtnClick={handleLogin}
      loading={loading || disableBtn}
      isLoginMobile={true}
    >
      <>
        <div
          style={{
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <div
            style={{
              display: "grid",
              gap: "10px",
              marginBottom: "15px",
              width: isMobile ? "90%" : 400,
              justifyContent: "left"
            }}
          >
            <span
              style={{
                color: baseStyles.text.default,
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              {t("lang")} :
            </span>
            <SwitchLanguage />
          </div>

          <div style={{ width: isMobile ? "90%" : 400 }}>
            <NeomorphInputWithIcon
              isEmail
              label={t("login.email")}
              placeholder={t('login.emailPlaceholder')}
              onChange={onEmailChanged}
              withoutIcon={true}
            />
          </div>

          <Spacer height={10} />

          <div style={{ width: isMobile ? "90%" : 400 }}>
            <NeomorphInputWithIcon
              isPassword
              withoutIcon={true}
              label={t("login.password")}
              placeholder={t('login.passwordPlaceholder')}
              onChange={onPasswordChanged}
            />
          </div>

          <Spacer height={10} />

          <div style={{ width: isMobile ? "90%" : 400, textAlign: "right" }}>
            <Link
              style={{ color: baseStyles.text.black }}
              to={ROUTES.forgetPassword}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  textAlign: "right",
                  textDecorationLine: "underline",
                }}
              >
                {t("login.forgotenPassword")}
              </p>
            </Link>
          </div>
        </div>
      </>
    </CustomAuthContent>
  );
};

export default LoginMobile;
