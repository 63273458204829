import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {}

const BottomContentItemTitle: FC<IProps> = () => {
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        width: "100%",
        padding: 10,
      }}
    >
      <div style={{ width: isTab ? "25%" : "14.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t("transaction.date")}
        </p>
      </div>
      <div style={{ width: isTab ? "25%" : "18.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t("transaction.sender")}
        </p>
      </div>
      <div style={{ width: isTab ? "25%" : "10.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t("transaction.amountSent")}
        </p>
      </div>
      {!isTab && (
        <div style={{ width: "10.2%" }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.default,
            }}
          >
            {t("transaction.commission")}
          </p>
        </div>
      )}
      <div style={{ width: isTab ? "25%" : "18.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
         {t("transaction.recipient")}
        </p>
      </div>
      {!isTab && (
        <>
          <div style={{ width: "14.2%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {t("transaction.reference")}
            </p>
          </div>
          <div style={{ width: "14.2%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {t("transaction.transferAmount")}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BottomContentItemTitle;
