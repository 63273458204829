import { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { CountryService } from "services/Country/Country.service";
import { TransactionService } from "services/Transaction/Transaction.service";
import { ITransaction } from "utils/interfaces";
import { formatDate, formmatDateToAPI } from "utils/utils";
import { toast } from "react-hot-toast";
import { UserContext } from "providers/UserProvider";
import { useTranslation } from "react-i18next";

const useDashboardHooks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { currentCurrency } = useContext(UserContext);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  const fetchTransactions = async (
    _minDate: Date = new Date(),
    _maxDate: Date = new Date(),
    currency: string = "EUR"
  ) => {
    setLoading(true);

    try {
      const response = await TransactionService.GetDashboardTransactions(
        formmatDateToAPI(_minDate),
        formmatDateToAPI(_maxDate),
        currency
      );
      const { total_records, current_page, total_pages } =
        response.data?.pagination;
      console.log("transactions", response.data.results);

      setTotalPage(total_pages);
      if (response?.data) {
        setTransactions(response?.data?.results as ITransaction[]);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      toast.error(
        t('alert.errorTransaction'),
        { id: "TransactionError" }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(dateRange.minDate, dateRange.maxDate, currentCurrency);
  }, [dateRange, currentCurrency]);

  return {
    fetchTransactions,
    loading,
    transactions,
    setCurrentPage,
    totalPage,
    currentPage,
    dateRange,
    setDateRange,
  };
};

export default useDashboardHooks;
