import axios from "services/AxiosInstance";

export interface PostLoginPayload {
  username: string;
  password: string;
}

const PostLogin = (payload: PostLoginPayload) => {
  return axios.post("api/auth/token/", { ...payload });
};

const AuthService = {
  PostLogin,
};

export default AuthService;
