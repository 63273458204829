import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={13}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#6E847E"
      fillRule="evenodd"
      d="M1.379 1.404C.5 2.283.5 3.697.5 6.525c0 2.829 0 4.243.879 5.122.878.878 2.293.878 5.121.878h3c2.828 0 4.243 0 5.121-.878.879-.879.879-2.293.879-5.122 0-2.828 0-4.242-.879-5.12-.878-.88-2.293-.88-5.121-.88h-3c-2.828 0-4.243 0-5.121.88Zm11.553 1.761a.562.562 0 0 1-.072.793L11.213 5.33c-.665.554-1.204 1.003-1.68 1.31-.495.318-.977.519-1.533.519-.556 0-1.038-.201-1.534-.52-.475-.306-1.014-.755-1.679-1.309L3.14 3.958a.562.562 0 1 1 .72-.865l1.62 1.35c.699.583 1.185.986 1.595 1.25.397.255.666.341.925.341s.528-.086.925-.341c.41-.264.896-.667 1.596-1.25l1.619-1.35a.562.562 0 0 1 .792.072Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as EmailIcon };
