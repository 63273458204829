import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    ref={ref}
    {...props}
    style={{ marginTop: 6, marginLeft: 2 }}
  >
    <g fill={props.color ?? "#6E847E"}>
     <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 0.5H12.5C16.2712 0.5 18.1569 0.5 19.3284 1.67157C20.5 2.84315 20.5 4.72876 20.5 8.5C20.5 12.2712 20.5 14.1569 19.3284 15.3284C18.1569 16.5 16.2712 16.5 12.5 16.5H8.5C4.72876 16.5 2.84315 16.5 1.67157 15.3284C0.5 14.1569 0.5 12.2712 0.5 8.5C0.5 4.72876 0.5 2.84315 1.67157 1.67157C2.84315 0.5 4.72876 0.5 8.5 0.5ZM11.75 5.5C11.75 5.08579 12.0858 4.75 12.5 4.75H17.5C17.9142 4.75 18.25 5.08579 18.25 5.5C18.25 5.91421 17.9142 6.25 17.5 6.25H12.5C12.0858 6.25 11.75 5.91421 11.75 5.5ZM12.75 8.5C12.75 8.08579 13.0858 7.75 13.5 7.75H17.5C17.9142 7.75 18.25 8.08579 18.25 8.5C18.25 8.91421 17.9142 9.25 17.5 9.25H13.5C13.0858 9.25 12.75 8.91421 12.75 8.5ZM13.75 11.5C13.75 11.0858 14.0858 10.75 14.5 10.75H17.5C17.9142 10.75 18.25 11.0858 18.25 11.5C18.25 11.9142 17.9142 12.25 17.5 12.25H14.5C14.0858 12.25 13.75 11.9142 13.75 11.5ZM9.5 5.5C9.5 6.60457 8.60457 7.5 7.5 7.5C6.39543 7.5 5.5 6.60457 5.5 5.5C5.5 4.39543 6.39543 3.5 7.5 3.5C8.60457 3.5 9.5 4.39543 9.5 5.5ZM7.5 13.5C11.5 13.5 11.5 12.6046 11.5 11.5C11.5 10.3954 9.70914 9.5 7.5 9.5C5.29086 9.5 3.5 10.3954 3.5 11.5C3.5 12.6046 3.5 13.5 7.5 13.5Z"
     stroke={props.color ?? "#6E847E"} />
    </g>
    <defs>
      <filter
        id="a"
        width={24}
        height={25}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1147_833" />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as CustomerIcon };

