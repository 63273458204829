import { UserContext } from "providers/UserProvider";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { UserService } from "services/User/UserService";
import { EditConnectedUserInfoPayload } from "utils/interfaces";
import { useTranslation } from "react-i18next";

function useUserHooks() {
  const { t } = useTranslation();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  const UpdateConnectedUserInfos = (payload: EditConnectedUserInfoPayload) => {
    const finalPayload = {
      userId: currentUser?.id ?? 0,
      payload: {
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
        role: payload.role,
        email: payload.email,
        profilePicture: payload.profilePicture,
      },
    };
    setUpdating(true);

    UserService.PutConnectedUserInfos(finalPayload)
      .then((res) => {
        const newData = res?.data;
        if (newData.id) {
          setCurrentUser({
            ...newData,
          });
        }
        setUpdating(false);
        toast.success(t('alert.modifiedSuccess'));
      })
      .catch((e) => {
        toast.error(t('alert.incorrectIdentification'));
        console.log("error ", e);
        setUpdating(false);
      });
  };

  return {
    UpdateConnectedUserInfos,
    updating,
  };
}

export default useUserHooks;
