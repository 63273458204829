import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={29}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color ?? "#fff"}
      fillRule="evenodd"
      d="M28.116 28.217a1.25 1.25 0 0 0 1.768 0l3.333-3.333a1.25 1.25 0 1 0-1.767-1.768l-1.2 1.2v-6.983a1.25 1.25 0 0 0-2.5 0v6.983l-1.2-1.2a1.25 1.25 0 1 0-1.767 1.768l3.333 3.333Z"
      clipRule="evenodd"
    />
    <path
      fill={props.color ?? "#fff"}
      d="M14 .667h6.667c6.285 0 9.428 0 11.38 1.952 1.406 1.406 1.8 3.43 1.91 6.798H.71c.11-3.369.503-5.392 1.91-6.798C4.571.667 7.714.667 14 .667Z"
    />
    <path
      fill={props.color ?? "#fff"}
      fillRule="evenodd"
      d="M14 27.333h6.667c1.099 0 2.102 0 3.02-.01l-.672-.671a3.75 3.75 0 0 1 2.235-6.379v-2.94a3.75 3.75 0 0 1 7.5 0v2.94c.373.042.741.139 1.09.292C34 18.862 34 16.727 34 14c0-.736 0-1.43-.003-2.083H.67C.667 12.57.667 13.264.667 14c0 6.285 0 9.428 1.952 11.38C4.572 27.334 7.715 27.334 14 27.334Zm-6.667-7.916a1.25 1.25 0 0 0 0 2.5H14a1.25 1.25 0 1 0 0-2.5H7.333Zm10.834 0a1.25 1.25 0 0 0 0 2.5h2.5a1.25 1.25 0 0 0 0-2.5h-2.5Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as MoneyIcon };
