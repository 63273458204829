import React, { FC, ReactElement } from "react";
import { Button, IconButton } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {
  onClick: (event: MouseEvent) => void;
  icon?: ReactElement;
  style?: React.CSSProperties;
  rounded?: boolean;
  disabled?: boolean;
}

const CustomIconButton: FC<IProps> = ({
  onClick,
  icon,
  disabled,
  style,
  rounded = true,
}) => {
  const CustomizedBtn: any = IconButton;

  return (
    <CustomizedBtn
      disabled={disabled}
      onClick={onClick}
      inset={false}
      bgColor={baseStyles.color.grey}
      color={baseStyles.color.white}
      rounded={rounded}
      text={false}
    >
      {icon}
    </CustomizedBtn>
  );
};

export default CustomIconButton;
