import React from "react";

const ExportIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3517 5.61665L13.3929 2.05375C12.2651 1.03868 11.7012 0.531143 11.0092 0.265625L11 3.00011C11 5.35713 11 6.53564 11.7322 7.26787C12.4645 8.00011 13.643 8.00011 16 8.00011H19.5801C19.2175 7.29588 18.5684 6.71164 17.3517 5.61665Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 20H12C15.7712 20 17.6569 20 18.8284 18.8284C20 17.6569 20 15.7712 20 12V11.5629C20 10.6901 20 10.0344 19.9574 9.50011H16L15.9051 9.50011C14.808 9.5002 13.8385 9.50027 13.0569 9.3952C12.2098 9.28131 11.3628 9.0198 10.6716 8.32853C9.9803 7.63726 9.7188 6.79028 9.60491 5.94316C9.49983 5.16164 9.49991 4.19207 9.5 3.09497L9.50923 0.260566C9.50951 0.178128 9.51656 0.0965928 9.53001 0.0166612C9.1214 -4.26546e-07 8.6358 0 8.02979 0C4.23869 0 2.34315 0 1.17157 1.17157C0 2.34315 0 4.22876 0 8V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20ZM5.98704 17.0472C6.27554 17.3176 6.72446 17.3176 7.01296 17.0472L9.01296 15.1722C9.31514 14.8889 9.33045 14.4142 9.04715 14.112C8.76385 13.8099 8.28923 13.7945 7.98704 14.0778L7.25 14.7688L7.25 11.5C7.25 11.0858 6.91421 10.75 6.5 10.75C6.08579 10.75 5.75 11.0858 5.75 11.5V14.7688L5.01296 14.0778C4.71077 13.7945 4.23615 13.8099 3.95285 14.112C3.66955 14.4142 3.68486 14.8889 3.98704 15.1722L5.98704 17.0472Z"
        fill="white"
      />
    </svg>
  );
};

export default ExportIcon;
