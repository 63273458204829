import { ContactMail, Mail, Phone } from "@mui/icons-material";
import { Button } from "@mui/material";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import useOperatorsHooks from "hooks/useOperatorsHooks";
import { FC, useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import OperatorLogoForm from "./OperatorLogoForm";
import {
  handleInputValueChange,
  handleSetDefaultOperatorPayload,
} from "../Operator.utils";
import OperatorCountryForm from "./OperatorCountryForm";
import { useTranslation } from "react-i18next";
import { getStatus } from "utils/constants/constants";

interface IProps {
  operator?: any;
  toggleDrawer: Function;
}

const OperatorForm: FC<IProps> = ({ toggleDrawer, operator }) => {
  const {
    defaultOperatorPayload,
    CreateOperator,
    UpdateOperator,
    DeleteOperator,
    loading,
    deleting,
  } = useOperatorsHooks();
  const [payload, setPayload] = useState(defaultOperatorPayload);
  const { t } = useTranslation();
  const status = getStatus(t);
  const onBtnClick = () => {
    if (operator?.id > 0) {
      UpdateOperator({
        operatorId: operator?.id,
        payload,
        toggleDrawer,
        setPayload,
      });
    } else {
      CreateOperator({
        payload,
        toggleDrawer,
        setPayload,
      });
    }
  };

  const handleDelete = () =>
    DeleteOperator({ toggleDrawer, operatorId: operator?.id ?? 0 });

  useEffect(() => {
    handleSetDefaultOperatorPayload({
      setPayload,
      operator,
    });
  }, [operator]);

  return (
    <div
      style={{
        marginTop: 20,
        paddingBottom: 10,
        overflow: "auto",
        background: baseStyles.color.grey,
        height: "calc(100vh - 110px)",
      }}
    >
      <NeomorphInputWithIcon
        width={300}
        label={t('operator.name')}
        placeholder={t('operator.name')}
        icon={<ContactMail htmlColor={baseStyles.text.default} />}
        onChange={(value: string) =>
          handleInputValueChange({ setPayload, value, keyValue: "name" })
        }
        value={payload.name}
      />

      <Spacer height={20} />

      <OperatorLogoForm setPayload={setPayload} />

      <Spacer height={20} />

      <OperatorCountryForm
        operator={operator}
        setPayload={setPayload}
        payload={payload}
      />

      <Spacer height={20} />

      <NeomorphInputWithIcon
        width={300}
        label={t('user.phone')}
        placeholder={t('user.phone')}
        icon={<Phone htmlColor={baseStyles.text.default} />}
        onChange={(value: string) =>
          handleInputValueChange({ setPayload, value, keyValue: "phone" })
        }
        value={payload.phone}
      />

      <Spacer height={20} />

      <NeomorphInputWithIcon
        width={300}
        label={t('user.email')}
        placeholder={t('user.email')}
        icon={<Mail htmlColor={baseStyles.text.default} />}
        onChange={(value: string) =>
          handleInputValueChange({ setPayload, value, keyValue: "email" })
        }
        value={payload.email}
      />

      <Spacer height={20} />

      <FakeSelect
        items={status}
        value={payload.active}
        label="Status"
        placeholder={t('input.active')}
        onchange={(status) =>
          setPayload((prev) => ({ ...prev, active: status }))
        }
      />

      <Spacer height={20} />

      <NeuromorphBtn
        loading={loading}
        width={330}
        label={
          operator ? t('operator.saveModif') : t('operator.add')
        }
        onClick={onBtnClick}
      />

      {operator && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="text"
            style={{
              textTransform: "none",
              width: 300,
              color: baseStyles.color.error.color,
            }}
            onClick={handleDelete}
          >
            {deleting ? t('user.deleteAccountIn'): t('operator.delete') }
          </Button>
        </div>
      )}
    </div>
  );
};

export default OperatorForm;
