import { Box, ButtonBase, Grid, Menu, Typography } from "@mui/material";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import Spacer from "components/Spacer/Spacer";
import { useEffect, useState } from "react";
import { Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import { CountryItem, IOperator, IZone } from "utils/interfaces";
import { truncate } from "utils/utils";

type SelectProps = {
  id?: string;
  placeholder?: string;
  label?: string;
  width?: any;
  operatorId?: number;
  onchange?: (e: any) => any;
  handleClick?: any;
  disabled?: boolean;
  items?: IOperator[];
};

export const OperatorProviderOptions = (props: SelectProps) => {
  const {
    handleClick,
    placeholder,
    onchange,
    label,
    items,
    disabled,
    operatorId,
  } = props;
  const Content: any = Card;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selected: IOperator) => {
    setAnchorEl(null);
    onchange?.(selected);
  };

  const valueLabel = (_zoneId: any) => {
    const founded = items?.find((item) => item?.id === _zoneId);
    return founded ? founded.name : "Non défini";
  };

  const [filteredItems, setFilteredItems] = useState<IOperator[] | undefined>(
    []
  );
  const [search, setSearch] = useState("");
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  useEffect(() => {
    const _copy = items?.filter((item) => {
      if (search?.length === 0) {
        return true;
      } else {
        return item?.name?.toLowerCase()?.includes(search?.toLowerCase());
      }
    });
    setFilteredItems(_copy);
  }, [search]);
  return (
    <div style={{ flex: 1 }}>
      {label && (
        <>
          <p
            style={{
              color: baseStyles.text.default,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {label} :{" "}
          </p>
          <Spacer height={10} />
        </>
      )}
      <ButtonBase
        disabled={disabled}
        onClick={(e) => {
          handleClick?.(e);
          onClickOpen(e);
        }}
        style={{
          flex: 1,
          display: "flex",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Content
          inset
          style={{
            flex: 1,
            height: 50,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            backgroundColor: baseStyles.color.grey,
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Grid
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <p
              style={{
                display: "inline-block",
                fontSize: 12,
                paddingTop: 11,
                fontWeight: 500,
                color: baseStyles.text.black,
              }}
            >
              {operatorId ? valueLabel(operatorId) : placeholder}
            </p>
            <ArrowDownIcon
              style={{
                display: "inline-block",
                paddingTop: 5,
                alignSelf: "flex-end",
              }}
            />
          </Grid>
        </Content>
      </ButtonBase>

      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 300,
            padding: 10,
            background: baseStyles.color.grey,
          },
        }}
      >
        <Box>
          <NeomorphInputWithIcon
            withoutPlaceHolder
            label=""
            placeholder="Rechercher ..."
            onChange={(text: string) => {
              setSearch(text);
            }}
          />
        </Box>
        <Spacer />
        {filteredItems?.map((item, i) => (
          <NeomorphButtonBase
            key={item?.id}
            height={40}
            background={
              item?.id === operatorId
                ? baseStyles.color.blue.primary
                : baseStyles.color.grey
            }
            style={{ marginBottom: i < filteredItems.length - 1 ? 15 : 0 }}
            onClick={() => handleClose(item)}
          >
            <Typography style={{ textTransform: "none" }}>
              {truncate(item?.name, 25)}
            </Typography>
          </NeomorphButtonBase>
        ))}
      </Menu>
    </div>
  );
};
