import { Typography } from "@mui/material";
import LinearChart from "components/Chart/LinearChart";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { FC } from "react";
import { Button } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import CardChartHeader from "./CardChartHeader";
import { useTranslation } from "react-i18next";

interface IProps {
  series: any;
  categories: any;
}
const TodayCardChart: FC<IProps> = ({ series, categories }: IProps) => {
  const ChartBtn: any = Button;
  const { t } = useTranslation();

  return (
    <div style={{}}>
      {categories?.length > 0 ? (
        <NeomorphContainer height={345}>
          <div>
            {/* <CardChartHeader /> */}
            <LinearChart series={series} categories={categories} />
          </div>
        </NeomorphContainer>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <Typography
            style={{
              color: baseStyles.text.default,
              fontSize: 18,
              textAlign: "center",
            }}
          >
            {t('alert.noTransaction')}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TodayCardChart;
