import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    ref={ref}
    {...props}
  >
    <g>
      <path
        fill={props.color ?? "#6E847E"}
        fillRule="evenodd"
        d="M4.672 3.672C3.5 4.843 3.5 6.729 3.5 10.5v4c0 3.771 0 5.657 1.172 6.828C5.843 22.5 7.729 22.5 11.5 22.5h2c3.771 0 5.657 0 6.828-1.172C21.5 20.157 21.5 18.271 21.5 14.5v-4c0-3.771 0-5.657-1.172-6.828C19.157 2.5 17.271 2.5 13.5 2.5h-2c-3.771 0-5.657 0-6.828 1.172ZM7.75 8.5a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8a.75.75 0 0 1-.75-.75Zm0 4a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8a.75.75 0 0 1-.75-.75Zm.75 3.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5h-5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="a"
        width={24}
        height={25}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1147_872" />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as OperateurIcon };
