import { Grid } from "@mui/material";
import { FC } from "react";
import MyAccountForm from "./MyAccountForm/MyAccountForm";

interface IProps {}

const MyAccount: FC<IProps> = (props: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 20,
      }}
    >
      <MyAccountForm />
    </div>
  );
};

export default MyAccount;
