import { FC } from "react";
import TopContent from "./TopContent/TopContent";
import Spacer from "components/Spacer/Spacer";
import BottomContent from "pages/Dashboard/BottomContent/BottomContent";

interface IProps {}

const Commissions: FC<IProps> = (props: IProps) => {
  return (
    <div
      style={{
        padding: 20,
      }}
    >
      <section style={{}}>
        <TopContent />
      </section>

      <Spacer />

      <section style={{}}>
        <BottomContent />
      </section>
    </div>
  );
};

export default Commissions;
