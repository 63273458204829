import NeuromorphBtn from "components/Button/NeuromorphBtn";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import ArrowRightIcon from "components/Icons/ArrowRightIcon";
import ExportIcon from "components/Icons/ExportIcon";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import React, { FC, useState } from "react";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";

interface Props {
  page : any,
}

const TopContent : FC<Props>  = (props) => {
  const { page } = props;
  const { isTab } = useScreenType();
  const [recherche, setRecherche] = useState("");

  const changeRecherche = (e: any) => {
    setRecherche(e);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
          alignItems: "center",
        }}
      >
        <div>
          <h2
            style={{
              fontSize: 18,
              fontWeight: 700,
              color: baseStyles.text.black,
            }}
          >
            Liste des commissions
          </h2>
        </div>
        <div>
          <NeuromorphBtn
            label="Créer une commision"
            onClick={() => page(true)}
            width={194}
          />
        </div>
      </div>
      <NeomorphContainer style={{ padding: "30px", height: "auto" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
              <NeomorphInputWithIcon
                placeholder="Recherche ..."
                onChange={changeRecherche}
                withoutPlaceHolder={true}
                label="Recherche"
              />
            </div>
            <Spacer />
            <div style={{ display: "flex", width: "50%" }}>
              <div style={{ width: "50%" }}>
                <FakeSelect
                  placeholder="Mode de transfert"
                  label="Mode de transfert"
                />
              </div>
              <Spacer />
              <div style={{ width: "50%" }}>
                <FakeSelect
                  placeholder="Palier transaction"
                  label="Palier transaction"
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 700,
                  color: baseStyles.text.black,
                  fontSize: "14px",
                }}
              >
                Expéditeur
              </p>
            </div>
            <Spacer />
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: 700,
                  color: baseStyles.text.black,
                  fontSize: "14px",
                }}
              >
                Destinataire
              </p>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "50%" }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <FakeSelect placeholder="Sélectionner un pays" label="Pays" />
                </div>
                <Spacer />
                <div style={{ width: "50%" }}>
                  <FakeSelect placeholder="Orange" label="Opérateur" />
                </div>
              </div>
            </div>
            <Spacer />
            <div style={{ marginTop: "25px" }}>
              <ArrowRightIcon />
            </div>
            <Spacer />
            <div style={{ width: "50%", display: "flex" }}>
              <div style={{ width: "50%" }}>
                <FakeSelect placeholder="Sélectionner un pays" label="Pays" />
              </div>
              <Spacer />
              <div style={{ width: "50%" }}>
                <FakeSelect placeholder="Orange" label="Opérateur" />
              </div>
            </div>
          </div>
        </div>
      </NeomorphContainer>
    </div>
  );
};

export default TopContent;
