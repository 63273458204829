import { makeStyles } from "@mui/styles";
import React from "react";
import { baseStyles } from "utils/constants/baseStyles";

const countryStyle = makeStyles(() => {
  return {
    content: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: 20,
      gap: 20,
    },
    contentList: {
      width: "80%",
    },
    contentStat: {
      width: "320px",
    },
    textTitle: {
      color: baseStyles.text.black,
      fontSize: 18,
      fontWeight: 700,
    },
    listHead : {
        display: "flex",
        gap: 10
    }
  };
});

export default countryStyle;
