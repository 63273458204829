import { FC, ReactElement } from "react";
import "./styles.css";
import { Button, Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {
  children: ReactElement;
  onClick?: Function;
  height?: number;
  style?: React.CSSProperties;
  elevation?: number;
  background?: string;
  disabled?: boolean;
}

const NeomorphButtonBase: FC<IProps> = ({
  style,
  children,
  height,
  elevation,
  onClick,
  disabled,
  background,
}) => {
  const Content: any = Button;
  return (
    <Content
      disabled={disabled}
      elevation={elevation ?? 1}
      style={{
        width: "100%",
        padding: 10,
        height: height ?? 61,
        borderRadius: 5,
        backgroundColor: background ?? baseStyles.color.grey,
        ...style,
      }}
      onClick={() => onClick?.()}
      bgColor={background ?? baseStyles.color.grey}
    >
      {children}
    </Content>
  );
};

export default NeomorphButtonBase;
