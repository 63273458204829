import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomAuthenticationWrapper from "components/CustomAuthenticationWrapper/CustomAuthenticationWrapper";
import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import NeomorphInput from "components/InputField/NeomorphInput";
import Spacer from "components/Spacer/Spacer";
import logoValidationCode from "assets/images/validation-code.png";
import { baseStyles } from "utils/constants/baseStyles";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "routes/routes";
import ValidationCodeMobile from "./ValidationCodeMobile";
import { useScreenType } from "utils/constants/UseScreenType";
import { useForgotPassword } from "hooks/useForgotPassword";

export const ValidationCode = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isTab } = useScreenType();
  const phoneNumber = location?.state?.phone;
  const email = location?.state?.email;
  const [codeValidation, setCodeValidation] = useState("");
  const {
    SendConfirmationCodeToPhone,
    CheckConfirmationCode,
    sendingForgotCode,
  } = useForgotPassword();
  const onCodeChange = (e: any) => {
    setCodeValidation(e.value);
  };

  const withoutRedirection = true;

  const ResendCode = () => {
    SendConfirmationCodeToPhone(phoneNumber, withoutRedirection);
  };

  const handleValidate = () =>
    CheckConfirmationCode({
      phone: phoneNumber ?? undefined,
      code: codeValidation,
      email: email ?? undefined,
    });

  return !isTab ? (
    <CustomAuthenticationWrapper>
      <CustomAuthContent
        loading={sendingForgotCode}
        logo={logoValidationCode}
        title={t('resetPassword.enterCode')}
        subtitle={t('resetPassword.enterCodeMessage')}
        phoneNumber={phoneNumber}
        onBtnClick={handleValidate}
      >
        <div>
          <NeomorphInput
            isEmail
            label={t('resetPassword.enterCodeLabel')}
            placeholder={t('resetPassword.enterCodePlaceholder')}
            onChange={onCodeChange}
          />

          <Spacer height={10} />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              onClick={ResendCode}
              style={{
                color: baseStyles.color.blue.primary,
                fontWeight: "500",
                fontSize: 14,
                cursor: "pointer",
              }}
            >
              {t('resetPassword.resend')}
            </p>

            <Spacer />

            <Link
              style={{ textDecoration: "none" }}
              to={ROUTES.emailCodeValidation}
            >
              <p
                style={{
                  color: baseStyles.color.blue.primary,
                  fontWeight: "500",
                  fontSize: 14,
                }}
              >
                {t('resetPassword.notReceived')}
              </p>
            </Link>
          </div>

          <Spacer />
        </div>
      </CustomAuthContent>
    </CustomAuthenticationWrapper>
  ) : (
    <ValidationCodeMobile />
  );
};
