import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import CreateUserIcon from "components/Icons/UserCreateIcon";
import NeomorphInput from "components/InputField/NeomorphInput";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import { UserContext } from "providers/UserProvider";
import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScreenType } from "utils/constants/UseScreenType";
import { IUserFilter } from "utils/interfaces";

interface IProps {
  onClickCreateBtn?: Function;
}

const UserInputFilters: FC<IProps> = ({ onClickCreateBtn }) => {
  const { isTab } = useScreenType();
  const { t } = useTranslation();
  const { filteredUsers, setFilteredUsers, userList } = useContext(UserContext);
  const handleInputValueChange = (value: any) => {
    setFilter((old) => ({ ...old, name: value }));
  };
  const onBtnClick = () => {
    onClickCreateBtn?.();
  };
  const roles = [
    { label: "ADMIN", value: "ADMIN" },
    { label: "USER", value: "USER" },
    { label: t('input.allRole'), value: "ALL" },
  ];
  const status = [
    { label: t('input.active'), value: 1 },
    { label: t('input.noActive'), value: 0 },
    { label: t('input.allStatus'), value: "ALL" },
  ];

  const [filter, setFilter] = useState<IUserFilter>({
    status: "ALL",
    role: "ALL",
    name: "",
  });

  useEffect(() => {
    const filtered = userList
      .filter((item) => {
        if (!filter.role || typeof filter.role !== "string") {
          return true;
        }
        if (filter.role === "ALL") {
          return true;
        } else {
          return item.groups?.includes(filter.role);
        }
      })
      .filter((item) => {
        if (filter.name.length === 0) {
          return true;
        } else {
          return (
            item.firstname
              ?.toLocaleLowerCase()
              ?.includes(filter.name.toLocaleLowerCase()) ||
            item.lastname
              ?.toLocaleLowerCase()
              ?.includes(filter.name.toLocaleLowerCase())
          );
        }
      })
      .filter((item) => {
        if (isNaN(filter.status)) {
          return true;
        }
        if (filter.status === "ALL") {
          return true;
        } else {
          return item.active === parseInt(filter.status);
        }
      });

    setFilteredUsers(filtered);
    console.log(filter, filtered);
  }, [filter]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isTab ? "column" : "row",
        justifyContent: isTab ? "center" : "space-between",
        alignItems: "center",
      }}
    >
      {isTab && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <FakeSelect
            value={filter.role}
            onchange={(value) => {
              setFilter((old) => ({ ...old, role: value }));
            }}
            items={roles}
            placeholder={t('input.allRole')}
          />
          <Spacer width={20}></Spacer>
          <FakeSelect
            value={filter.status}
            onchange={(value) => {
              setFilter((old) => ({ ...old, status: value }));
            }}
            items={status}
            placeholder={t('input.allStatus')}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div style={{ width: isTab ? "50%" : "20%" }}>
          <NeomorphInputWithIcon
            withoutPlaceHolder
            label=""
            placeholder={t('input.search')}
            onChange={handleInputValueChange}
          />
        </div>
        {!isTab && (
          <>
            <Spacer width={20}></Spacer>
            <FakeSelect
              value={filter.role}
              onchange={(value) => {
                setFilter((old) => ({ ...old, role: value }));
              }}
              items={roles}
              placeholder={t('input.allRole')}
            />
            <Spacer width={20}></Spacer>
            <FakeSelect
              value={filter.status}
              onchange={(value) => {
                setFilter((old) => ({ ...old, status: value }));
              }}
              items={status}
              placeholder={t('input.allStatus')}
            />
          </>
        )}
        <Spacer width={20}></Spacer>
        <NeuromorphBtn
          startIcon={<CreateUserIcon />}
          label={t('user.create')}
          onClick={onBtnClick}
          width={isTab ? "50%" : 400}
        />
      </div>
    </div>
  );
};

export default UserInputFilters;
