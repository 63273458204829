import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spacer } from "ui-neumorphism";
import forgotLogo from "assets/images/forgot-pwd-img.png";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { useScreenType } from "utils/constants/UseScreenType";
import { Ghana } from "utils/fakedata";
import { useForgotPassword } from "hooks/useForgotPassword";

const ForgetPasswordMobile = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();
  const [phone, setPhone] = useState("");

  const { SendConfirmationCodeToPhone, sendingForgotCode } =
    useForgotPassword();

  const onPhoneChanged = (value: string) => {
    setPhone(value);
  };

  const handleValidate = () => {
    SendConfirmationCodeToPhone(phone);
  };

  const disableBtn = phone?.trim()?.length < 7;

  return (
    <CustomAuthContent
      logo={forgotLogo}
      loading={sendingForgotCode || disableBtn}
      title={t("forgotPassword.forgotenPassword")}
      subtitle={t("forgotPassword.info")}
      onBtnClick={handleValidate}
    >
      <>
        <Spacer />

        <div style={{ width: isMobile ? "90vw" : 400, marginTop: "20px" }}>
          <NeomorphInputWithIcon
            width={400}
            label={t("forgotPassword.phone")}
            placeholder={t("forgotPassword.placeholder")}
            icon={
              <img
                alt="country"
                style={{ marginTop: 3, width: 30, height: 25 }}
                src={Ghana}
              />
            }
            onChange={onPhoneChanged}
            value={phone}
          />
        </div>

        <Spacer />
      </>
    </CustomAuthContent>
  );
};

export default ForgetPasswordMobile;
