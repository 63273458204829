import { CircularProgress } from "@mui/material";
import React, { FC, ReactElement } from "react";
import { Button } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

interface IProps {
  label?: string;
  onClick: (event: MouseEvent) => void;
  loading?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  width?: any;
  background?: string;
  textColor?: string;
  disabled?: boolean;
}

const NeuromorphBtn: FC<IProps> = ({
  onClick,
  label,
  loading,
  startIcon,
  width,
  background,
  textColor,
  disabled,
  endIcon,
}) => {
  const CustomizedBtn: any = Button;
  const { t } = useTranslation();
  return (
    <CustomizedBtn
      disabled={disabled}
      animation={loading}
      onClick={onClick}
      bgColor={background ?? baseStyles.color.blue.primary}
      color={textColor ?? baseStyles.color.white}
      style={{
        width: width ?? 400,
        height: 50,
        borderRadius: 10,
        position: "relative",
      }}
    >
      <div style={{display: "flex",gap: 10, alignItems: "center"}}>
        {startIcon && <div>{startIcon}</div>}
        <p
          style={{
            textTransform: "none",
            display: "inline-block",
            marginTop: -2,
            fontSize: 14,
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {label || t("forgotPassword.btn")}
        </p>
        {endIcon && <div>{endIcon}</div>}
        {loading && (
          <CircularProgress
            style={{
              color: disabled
                ? baseStyles.color.blue.primary
                : textColor ?? baseStyles.color.white,
            }}
            size={25}
          />
        )}
      </div>
    </CustomizedBtn>
  );
};

export default NeuromorphBtn;
