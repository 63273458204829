import { Divider } from "@mui/material";
import MenuPopover from "pages/AppHeader/MenuPopover/MenuPopover";
import React, { Dispatch, FC, Fragment, SetStateAction } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { roles } from "utils/constants/constants";
import { EditConnectedUserInfoPayload, UserRole } from "utils/interfaces";

interface IProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  togglePopover: (event: React.MouseEvent<HTMLElement> | any) => void;
  setUserRole: Dispatch<SetStateAction<UserRole>>;
  setPayload: Dispatch<SetStateAction<EditConnectedUserInfoPayload>>;
}

const UserRolePopover: FC<IProps> = ({
  setUserRole,
  open,
  anchorEl,
  togglePopover,
  setPayload,
}) => {
  const handleChooseRole = (role: UserRole) => {
    setUserRole(role);
    togglePopover(role.id);
    setPayload((prev) => ({ ...prev, role: role.value }));
  };

  return (
    <MenuPopover
      position="left"
      open={open}
      anchorEl={anchorEl}
      handleClose={togglePopover}
    >
      <div
        style={{
          width: 300,
          display: "flex",
          flexDirection: "column",
          backgroundColor: baseStyles.color.grey,
        }}
      >
        {roles.map((role) => (
          <Fragment key={role.id}>
            <div
              style={{
                margin: "10px 0 10px 10px",
                cursor: "pointer",
              }}
              onClick={() => handleChooseRole(role)}
            >
              <p
                style={{
                  color: baseStyles.text.black,
                  fontSize: 12,
                  fontWeight: 700,
                }}
              >
                {role.value}
              </p>
            </div>

            <Divider />
          </Fragment>
        ))}
      </div>
    </MenuPopover>
  );
};

export default UserRolePopover;
