import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#131A21"
      fillRule="evenodd"
      d="M11.094 8a.562.562 0 0 0-.563-.563h-8.23l1.471-1.26a.562.562 0 1 0-.732-.854L.415 7.573a.562.562 0 0 0 0 .854l2.625 2.25a.563.563 0 0 0 .732-.854l-1.47-1.26h8.23c.31 0 .562-.252.562-.563Z"
      clipRule="evenodd"
    />
    <path
      fill="#131A21"
      d="M6.031 5c0 .527 0 .79.127.98a.75.75 0 0 0 .207.206c.189.126.452.126.979.126h3.187a1.687 1.687 0 1 1 0 3.375H7.344c-.527 0-.79 0-.98.127a.75.75 0 0 0-.206.207c-.127.189-.127.452-.127.979 0 2.121 0 3.182.66 3.841.658.659 1.719.659 3.84.659h.75c2.121 0 3.182 0 3.841-.659.66-.659.66-1.72.66-3.841V5c0-2.121 0-3.182-.66-3.841C14.463.5 13.402.5 11.282.5h-.75C8.41.5 7.348.5 6.69 1.159c-.659.659-.659 1.72-.659 3.841Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as LogoutIcon };
