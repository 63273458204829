import { Mail, PersonRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import { FC, useContext, useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { Ghana } from "utils/fakedata";
import MyAvatarContent from "../MyAvatarContent/MyAvatarContent";
import { UserContext } from "providers/UserProvider";
import { EditConnectedUserInfoPayload, UserRole } from "utils/interfaces";
import useUserHooks from "hooks/useUserHooks";
import UserRolePopover from "./UserRolePopover";
import { roles } from "utils/constants/constants";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";
import { useTranslation } from "react-i18next";

const defaultUserInfosPayload: EditConnectedUserInfoPayload = {
  firstname: "",
  lastname: "",
  phone: "",
  email: "",
  role: "ADMIN",
};

interface IProps {
  onClickSaveBtn?: Function;
  user?: any;
}

const MyAccountForm: FC<IProps> = ({ user }) => {
  const { UpdateConnectedUserInfos, updating } = useUserHooks();
  const { currentUser } = useContext(UserContext);
  const [payload, setPayload] = useState<EditConnectedUserInfoPayload>(
    defaultUserInfosPayload
  );
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState<UserRole>({
    id: 1,
    label: "Admin",
    value: "ADMIN",
  });
  const handleInputValueChange = (keyValue: string) => (value: string) => {
    setPayload((prev) => ({
      ...prev,
      [keyValue]: value,
    }));
  };
  const onBtnClick = () => {
    console.log({ payload });
    UpdateConnectedUserInfos(payload);
  };

  useEffect(() => {
    if (currentUser.id) {
      const role = roles.find(
        (item) => item.value === currentUser?.groups?.[0]
      ) as UserRole;

      setPayload({
        firstname: currentUser.firstname,
        lastname: currentUser.lastname,
        phone: currentUser.phone,
        email: currentUser.email,
        role: role.value ?? "USER",
      });

      setUserRole(role);
    }
  }, [currentUser]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openUserRole, setOpenUserRole] = useState(false);
  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
    setOpenUserRole(!openUserRole);
  };

  return (
    <div
      style={{
        marginTop: 20,
        overflow: "auto",
        background: baseStyles.color.grey,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <MyAvatarContent setPayload={setPayload} />

        <div>
          <Spacer height={20} />

          <NeomorphInputWithIcon
            width={300}
            label={t('user.lastName')}
            placeholder={t('user.lastName')}
            icon={<PersonRounded htmlColor={baseStyles.text.default} />}
            onChange={handleInputValueChange("firstname")}
            value={payload.firstname}
          />

          <Spacer height={20} />

          <NeomorphInputWithIcon
            width={300}
            label={t('user.firstName')}
            placeholder={t('user.firstName')}
            icon={<PersonRounded htmlColor={baseStyles.text.default} />}
            onChange={handleInputValueChange("lastname")}
            value={payload.lastname}
          />

          <Spacer height={20} />

          <NeomorphInputWithIcon
            width={300}
            label={t('user.email')}
            isEmail
            placeholder={t('user.email')}
            icon={<Mail htmlColor={baseStyles.text.default} />}
            onChange={handleInputValueChange("email")}
            value={payload.email}
          />

          <Spacer height={20} />

          <FakeSelect
            handleClick={togglePopover}
            label={t('user.role')}
            placeholder={userRole.label}
          />

          <Spacer height={20} />

          <NeomorphInputWithIcon
            width={300}
            label={t('user.phone')}
            placeholder={t('user.phone')}
            icon={
              <img
                alt="country"
                style={{ marginTop: 3, width: 30, height: 25 }}
                src={Ghana}
              />
            }
            onChange={handleInputValueChange("phone")}
            value={payload.phone}
          />

          <Spacer height={20} />
          <div style={{ display: "grid", gap: "10px", marginBottom: "15px" }}>
            <span
              style={{
                color: baseStyles.text.default,
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              {t("lang")} :
            </span>
            <SwitchLanguage />
          </div>

          <NeuromorphBtn
            loading={updating}
            width={300}
            label={t('button.modifySave')}
            onClick={onBtnClick}
          />

          <Spacer height={20} />
          

          {user && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <Button
                variant="text"
                style={{
                  textTransform: "none",
                  width: 300,
                  color: baseStyles.color.error.color,
                }}
              >
                {t('user.deleteAccount')}
              </Button>
            </div>
          )}
        </div>
      </div>

      <UserRolePopover
        open={openUserRole}
        anchorEl={anchorEl}
        togglePopover={togglePopover}
        setUserRole={setUserRole}
        setPayload={setPayload}
      />
    </div>
  );
};

export default MyAccountForm;
