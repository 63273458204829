import { SVGProps, Ref, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={79}
    height={79}
    fill="none"
    ref={ref}
    {...props}
  >
    <g filter="url(#a)">
      <rect width={50} height={50} x={12} y={12} fill="#52C3FF" rx={10} />
      <rect
        width={52}
        height={52}
        x={11}
        y={11}
        stroke="url(#b)"
        strokeWidth={2}
        rx={11}
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M45 37H29m0 0 6-6m-6 6 6 6"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={12}
        x2={62}
        y1={12}
        y2={62}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity={0.05} />
        <stop offset={1} stopColor="#fff" stopOpacity={0.05} />
      </linearGradient>
      <filter
        id="a"
        width={79}
        height={79}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={5} dy={5} />
        <feGaussianBlur stdDeviation={5} />
        <feColorMatrix values="0 0 0 0 0.682353 0 0 0 0 0.682353 0 0 0 0 0.752941 0 0 0 0.2 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_625_1903"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-5} dy={-5} />
        <feGaussianBlur stdDeviation={2.5} />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend
          in2="effect1_dropShadow_625_1903"
          result="effect2_dropShadow_625_1903"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect2_dropShadow_625_1903"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={2} dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend in2="shape" result="effect3_innerShadow_625_1903" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-2} dy={-2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          in2="effect3_innerShadow_625_1903"
          result="effect4_innerShadow_625_1903"
        />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as GoBackIcon };
