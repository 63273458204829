import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

let instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, authentication tokens)

    const accessToken = window.sessionStorage.getItem("token");
    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      // if (config.headers) config.headers.token = accessToken;
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

export default instance;
