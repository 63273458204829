import React from "react";

const ArrowRightIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.46447 18.5355C2.92893 20 5.28595 20 10 20C14.714 20 17.0711 20 18.5355 18.5355C20 17.0711 20 14.714 20 10C20 5.28595 20 2.92893 18.5355 1.46447C17.0711 -2.38419e-07 14.714 -2.05906e-07 10 1.50976e-10C5.28595 2.06208e-07 2.92893 2.38419e-07 1.46447 1.46447C-2.38419e-07 2.92893 -2.05903e-07 5.28595 1.54159e-10 10C2.06212e-07 14.714 2.38419e-07 17.0711 1.46447 18.5355ZM5.25 10C5.25 9.58579 5.58579 9.25 6 9.25H12.1893L10.4697 7.53033C10.1768 7.23744 10.1768 6.76256 10.4697 6.46967C10.7626 6.17678 11.2374 6.17678 11.5303 6.46967L14.5303 9.46967C14.671 9.61032 14.75 9.80109 14.75 10C14.75 10.1989 14.671 10.3897 14.5303 10.5303L11.5303 13.5303C11.2374 13.8232 10.7626 13.8232 10.4697 13.5303C10.1768 13.2374 10.1768 12.7626 10.4697 12.4697L12.1893 10.75H6C5.58579 10.75 5.25 10.4142 5.25 10Z"
        fill="#6E847E"
      />
    </svg>
  );
};

export default ArrowRightIcon;
