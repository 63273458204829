import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
function CloseIcon(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_i_807_3998)">
        <path
          d="M16 2.256L13.744 0 8 5.744 2.256 0 0 2.256 5.744 8 0 13.744 2.256 16 8 10.256 13.744 16 16 13.744 10.256 8 16 2.256z"
          fill="#6E847E"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_807_3998"
          x={0}
          y={0}
          width={16}
          height={17}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
          <feBlend in2="shape" result="effect1_innerShadow_807_3998" />
        </filter>
      </defs>
    </svg>
  );
}

export default CloseIcon;
