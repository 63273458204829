import "./App.css";
import "ui-neumorphism/dist/index.css";
import { Provider } from "providers";
import AppRoutes from "routes/AppRoutes";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    window.addEventListener("unload", handleTabClosing);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      window.removeEventListener("unload", handleTabClosing);
    };
  });

  const handleTabClosing = () => {
    // localStorage.clear();
  };

  const alertUser = (event: any) => {
    event.preventDefault();
    event.returnValue = "Voulez-vous vraiment quitter cette page ?";
  };

  return (
    <Provider>
      <AppRoutes />
      <Toaster />
    </Provider>
  );
}

export default App;
