import { FC, createContext, useEffect, useState } from "react";
import { SessionStorageService } from "utils/localStorage";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";

type AuthType = {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
  // registerPayload: RegisterPayload;
  // setRegisterPayload: (r: RegisterPayload) => void;
};

export const AuthContext = createContext<AuthType>({
  token: null,
  login: () => {},
  logout: () => {},
  // registerPayload: () => {},
  // setRegisterPayload: () => {},
});

interface AuthProviderProps {
  children: any;
}

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const { children } = props;
  const [token, setToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    SessionStorageService.GetSessionToken().then((session) => {
      if (session) {
        const redirectTo =
          pathname === ROUTES.home ? ROUTES.dashboard : pathname;
        setToken(session);
        navigate(redirectTo);
      } else {
        navigate(ROUTES.login);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        login: (loginToken: string) => {
          setToken(loginToken);
          window.sessionStorage.setItem("token", loginToken);
        },
        logout: () => {
          setToken(null);
          window.sessionStorage.removeItem("token");
          navigate(ROUTES.login);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
