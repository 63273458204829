import React, { FC } from "react";
import UserItem from "./UserItem/UserItem";
import { IUserItem } from "utils/interfaces";
import { Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";

interface IProps {
  onClickDetails?: Function;
  users?: IUserItem[];
}

const UserItems: FC<IProps> = ({ onClickDetails, users }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: 20,
      }}
    >
      {users && users.length > 0 ? (
        users?.map((item) => (
          <UserItem
            onClickDetails={() => onClickDetails?.(item)}
            key={item.id}
            email={item.email}
            profile={`${process.env.REACT_APP_API_URL}/${item.profilePicture?.path}`}
            role={
              item.groups && item.groups.length > 0
                ? item.groups[0]
                : t("input.undefined")
            }
            isActive={item.active}
            name={`${item.firstname ?? ""} ${item.lastname ?? ""}`}
          />
        ))
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
            margin: 50,
          }}
        >
          <Typography fontSize={18} color={baseStyles.text.default}>
            {t('user.error')}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserItems;
