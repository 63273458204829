import { FC, useEffect, useState } from "react";
import { isValidEmail, minLengthValidator } from "utils/utils";

import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import CustomAuthenticationWrapper from "components/CustomAuthenticationWrapper/CustomAuthenticationWrapper";
import { EmailIcon } from "components/Icons/EmailIcon";
import { Link } from "react-router-dom";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { PasswordIcon } from "components/Icons/PasswordIcon";
import { PostLoginPayload } from "services/Login/LoginService";
import { ROUTES } from "routes/routes";
import Spacer from "components/Spacer/Spacer";
import { baseStyles } from "utils/constants/baseStyles";
import loginLogo from "assets/images/login-img.png";
import useAuthenticationHooks from "hooks/useAuthenticationHooks";
import { useTranslation } from "react-i18next";
import { FrIcon } from "components/Icons/FrIcon";
import ChangeLanguage from "../../components/SwitchLanguage/ChangeLanguage";
import { EngIcon } from "components/Icons/EngIcon";
import SwitchLanguage from "components/SwitchLanguage/SwitchLanguage";

interface IProps {}

const LoginWeb: FC<IProps> = () => {
  const { t } = useTranslation();
  const { performLogin, loading } = useAuthenticationHooks();
  const [payload, setPayload] = useState<PostLoginPayload>({
    username: "admin@admin.com", //admin@admin.com
    password: "123456", //123456
  });

  const disableBtn =
    !isValidEmail(payload.username) || !minLengthValidator(payload.password, 6);

  const onEmailChanged = (e: any) => {
    setPayload({ ...payload, username: e });
  };
  const onPasswordChanged = (e: any) => {
    setPayload({ ...payload, password: e });
  };

  const handleLogin = () => performLogin(payload);

  useEffect(() => {
    console.log(
      "disableBtn",
      isValidEmail(payload.username),
      minLengthValidator(payload.password, 6),
      disableBtn
    );
  }, [payload]);

  return (
    <CustomAuthenticationWrapper isLogin>
      <CustomAuthContent
        btnLabel={t("login.connect")}
        logo={loginLogo}
        title={t("login.title")}
        onBtnClick={handleLogin}
        loading={loading}
      >
        <div>
          <div style={{ display: "grid", gap: "10px", marginBottom: "15px" }}>
            <span
              style={{
                color: baseStyles.text.default,
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              {t("lang")} :
            </span>
            <SwitchLanguage />
          </div>

          <NeomorphInputWithIcon
            width={400}
            label={t("login.email")}
            placeholder={t('login.emailPlaceholder')}
            icon={<EmailIcon style={{ marginTop: 5 }} />}
            onChange={onEmailChanged}
            value={payload.username}
          />

          <Spacer height={10} />

          <NeomorphInputWithIcon
            width={400}
            isPassword
            label={t("login.password")}
            placeholder={t('login.passwordPlaceholder')}
            icon={<PasswordIcon style={{ marginTop: 2 }} />}
            onChange={onPasswordChanged}
            value={payload.password}
          />

          <Spacer height={10} />

          <Link
            style={{ color: baseStyles.text.black }}
            to={ROUTES.forgetPassword}
          >
            <p
              style={{
                fontSize: 12,
                fontWeight: 500,
                textAlign: "right",
                textDecorationLine: "underline",
              }}
            >
              {t('login.forgotenPassword')}
            </p>
          </Link>
        </div>
      </CustomAuthContent>
    </CustomAuthenticationWrapper>
  );
};

export default LoginWeb;
