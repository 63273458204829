import { CountrySelectOptions } from "components/FakeSelect/CountrySelectOptions";
import useCountryHooks from "hooks/useCountryHooks";
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { CountryItem, PostPutOperatorPayload } from "utils/interfaces";

interface IProps {
  operator?: any;
  withoutLabel?: boolean;
  payload: PostPutOperatorPayload;
  setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
}

const OperatorCountryForm: FC<IProps> = ({
  withoutLabel,
  operator,
  setPayload,
}) => {
  const [country, setCountry] = useState<CountryItem>({} as CountryItem);
  const { FetchCountries, loading, countries } = useCountryHooks();
  const { t } = useTranslation();

  useEffect(() => {
    FetchCountries();
  }, []);

  useEffect(() => {
    if (operator?.id > 0) {
      setCountry({
        supported: operator?.country?.supported,
        currency: operator?.country?.currency,
        zone: operator?.country?.zone,
        id: operator?.country?.id,
        name: operator?.country?.name,
        code: operator?.country?.code,
        phonePrefix: operator?.country?.phonePrefix,
      });
    }
  }, [operator]);

  return (
    <Fragment>
      <CountrySelectOptions
        label={withoutLabel ? undefined : "Pays"}
        disabled={loading}
        placeholder={t('input.selectCountry')}
        items={countries}
        countryId={country.id}
        onchange={(countryValue: CountryItem) => {
          setPayload((prev) => ({
            ...prev,
            countryCode: countryValue.code,
          }));
          setCountry(countryValue);
        }}
      />
    </Fragment>
  );
};

export default OperatorCountryForm;
