import React, { FC } from "react";
import UserItem from "./UserItem/UserItem";
import { IUserItem } from "utils/interfaces";
import { Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import { useTranslation } from "react-i18next";
import CustomerItemTitle from "./CustomerItemTitle";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";

interface IProps {
  onClickDetails?: Function;
  users?: IUserItem[];
}

const UserItems: FC<IProps> = ({ onClickDetails, users }) => {
  const { t } = useTranslation();
  return (
    <div>
      <CustomerItemTitle />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        {users && users.length > 0 ? (
          users?.map((item) => (
            <NeomorphContainer
              key={item.id}
              style={{ marginBottom: 10 }}
            >
              <UserItem
                onClickDetails={() => onClickDetails?.(item)}
                key={item.id}
                userId={item.id}
                phone={item.phone}
                profile={`${process.env.REACT_APP_API_URL}/${item.profilePicture?.path}`}
                country={item.country}
                name={`${item.firstname ?? ""} ${item.lastname ?? ""}`}
                isUserValidatedKyc={item?.kycInformation?.isUserValidatedKyc}
                askedAccountDeletion={item?.askedAccountDeletion}
                registrationDate={item?.registrationDate}
              />
            </NeomorphContainer>

          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              margin: 50,
            }}
          >
            <Typography fontSize={18} color={baseStyles.text.default}>
              {t('user.error')}
            </Typography>
          </div>
        )}
      </div>
    </div>

  );
};

export default UserItems;
