import { FC, useState } from "react";
import TopContent from "./TopContent/TopContent";
import Spacer from "components/Spacer/Spacer";
import BottomContent from "./BottomContent/BottomContent";

interface IProps {}

const Dashboard: FC<IProps> = (props: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 20,
      }}
    >
      <section style={{}}>
        <TopContent />
      </section>

      <Spacer />

      <section style={{}}>
        <BottomContent />
      </section>
    </div>
  );
};

export default Dashboard;
