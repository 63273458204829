import React from "react";

const WalletBanq = () => {
  return (
    <svg
      width="43"
      height="34"
      viewBox="0 0 43 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6667 0.333496H17.3334C9.47667 0.333496 5.5483 0.333496 3.10752 2.77427C1.35015 4.53165 0.858095 7.06021 0.720324 11.271H42.2798C42.1421 7.06021 41.65 4.53165 39.8926 2.77427C37.4519 0.333496 33.5235 0.333496 25.6667 0.333496Z"
        fill="#F9AE2B"
      />
      <path
        d="M17.3334 33.6668H25.6667C33.5235 33.6668 37.4519 33.6668 39.8926 31.2261C42.3334 28.7853 42.3334 24.8569 42.3334 17.0002C42.3334 16.0795 42.3334 15.2127 42.3295 14.396H0.670676C0.666748 15.2127 0.666748 16.0795 0.666748 17.0002C0.666748 24.8569 0.666748 28.7853 3.10752 31.2261C5.5483 33.6668 9.47667 33.6668 17.3334 33.6668Z"
        fill="#F9AE2B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.43758 25.3335C7.43758 24.4706 8.13714 23.771 9.00008 23.771H17.3334C18.1964 23.771 18.8959 24.4706 18.8959 25.3335C18.8959 26.1964 18.1964 26.896 17.3334 26.896H9.00008C8.13714 26.896 7.43758 26.1964 7.43758 25.3335Z"
        fill="#E7EBF0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9792 25.3335C20.9792 24.4706 21.6788 23.771 22.5417 23.771H25.6667C26.5297 23.771 27.2292 24.4706 27.2292 25.3335C27.2292 26.1964 26.5297 26.896 25.6667 26.896H22.5417C21.6788 26.896 20.9792 26.1964 20.9792 25.3335Z"
        fill="#E7EBF0"
      />
    </svg>
  );
};

export default WalletBanq;
