import { FC } from "react";
import UserProvider from "./UserProvider";
import AuthProvider from "./AuthProvider";
import PermissionProvider from "./PermissionProvider";
import OperatorProvider from "./OperatorProvider";
import { AxiosInterceptor } from "services/Interceptor/AxiosInterceptor";

interface IProps {
  children: any;
}

export const Provider: FC<IProps> = (props: IProps) => {
  const { children } = props;

  return (
    <AuthProvider>
      <PermissionProvider>
        <UserProvider>
          <OperatorProvider>
            <AxiosInterceptor>{children}</AxiosInterceptor>
          </OperatorProvider>
        </UserProvider>
      </PermissionProvider>
    </AuthProvider>
  );
};
