import React, { useState } from "react";
import TopContent from "./TopContent/TopContent";
import BottomContent from "./BottomContent/BottomContent";
import CreateCommission from "./CreateCommission/CreateCommission";

const ManageCommission = () => {
  const [pageManage, setPageManage] = useState(false);
  return (
    <div
      style={{
        padding: 20,
        display: "flex",
        flexDirection: "column",
      }}
    >
      { !pageManage ? (
        <>
          <div>
            <TopContent page={setPageManage} />
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <BottomContent />
          </div>
        </>
      ) : (
        <CreateCommission setPage={setPageManage} />
      )}
    </div>
  );
};

export default ManageCommission;
