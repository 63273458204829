import { Avatar } from "@mui/material";
import { FC } from "react";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";
import { Benin, Moov } from "utils/fakedata";
import { TransactionItem } from "utils/interfaces";

interface IProps {
  transaction: TransactionItem;
}

const BottomContentItem: FC<IProps> = ({ transaction }) => {
  const { isTab } = useScreenType();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <section style={{ width: "10%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
            marginLeft: 15,
          }}
        >
          {transaction.date}
        </p>
      </section>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 20,
          width: "18%",
        }}
      >
        <img
          src={Moov}
          style={{
            height: 30,
            marginTop: 5,
            justifyContent: "center",
            alignContent: "center",
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "flex-start",
              textAlign: "center",
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.black,
            }}
          >
            {transaction.expeditor}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={Benin} style={{ height: 20 }} />
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                textAlign: "center",
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.black,
              }}
            >
              {"Benin"}
            </p>
          </div>
        </div>
      </div>

      <div style={{ width: "8%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {transaction.montant} €
        </p>
      </div>

      { !isTab && (<section
        style={{
          width: "15%",
        }}
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            textAlign: "center",
            color: baseStyles.text.default,
            marginBottom: 5,
            marginLeft: 15,
          }}
        >
          {"2500 CFA"}
        </p>
      </section>)}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginLeft: 20,
          width: "20%",
        }}
      >
        <img
          src={Moov}
          style={{
            height: 30,
            marginTop: 5,
            justifyContent: "center",
            alignContent: "center",
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", marginLeft: 10 }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "flex-start",
              textAlign: "center",
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.black,
            }}
          >
            {transaction.destinator}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={Benin} style={{ height: 20 }} />
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                textAlign: "center",
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.black,
              }}
            >
              {"Benin"}
            </p>
          </div>
        </div>
      </div>

      {!isTab && (
        <>
          <div style={{ width: "10%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {transaction.referance}
            </p>
          </div>
          <div style={{ width: "10%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {transaction.transfertMode}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BottomContentItem;
