import { FC, useState } from "react";
import HeaderContent, {
  defaultTransactionFilter,
} from "./HeaderContent/HeaderContent";
import BottomContentItems from "./BottomContent/BottomContentItems/BottomContentItems";

interface IProps {}

const Transactions: FC<IProps> = (props: IProps) => {
  const [filter, setFilter] = useState(defaultTransactionFilter);
  const [exportToPdf, setExportToPDF] = useState(0);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // padding: 20,
      }}
    >
      <div>
        <HeaderContent
          withExport
          onExport={() => setExportToPDF(exportToPdf + 1)}
          handleChangeFilter={setFilter}
        />
      </div>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <BottomContentItems exportToPdf={exportToPdf} filter={filter} />
        </div>
      </div>
    </div>
  );
};

export default Transactions;
