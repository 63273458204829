import { Card } from "@mui/material";
import Spacer from "components/Spacer/Spacer";
import { UserContext } from "providers/UserProvider";
import React, { FC, useContext } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { TodayCardItem } from "utils/interfaces";
import { currencySymbol, formatCash } from "utils/utils";

interface IProps {
  content: TodayCardItem;
  width?: any;
}

const TodayCard: FC<IProps> = ({ content, width }) => {
  const { currentCurrency } = useContext(UserContext);
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: width ?? 210,
        height: 155,
        backgroundColor: content?.bgColor ?? baseStyles.color.tertiaire,
        padding: 20,
        borderRadius: "10px",
        boxShadow: "0px 20px 20px 0px #7383C080",
      }}
    >
      <span style={{ marginBottom: 10 }}>{content?.icon}</span>

      <p
        style={{
          color: baseStyles.color.white,
          fontSize: 24,
          fontWeight: 900,
          marginBottom: 10,
        }}
      >
        {`${formatCash(parseFloat(content?.value))} ${
          currencySymbol[currentCurrency]?.symbol_native ?? ""
        }`}
      </p>

      <p
        style={{
          color: baseStyles.color.white,
          fontSize: 14,
          fontWeight: 500,
          width: 70,
          textAlign: "center",
        }}
      >
        {content?.description}
      </p>
    </Card>
  );
};

export default TodayCard;
