import { useState } from "react";
import { AxiosError } from "axios";
import AuthenticationService from "services/Auth/AuthenticationService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { CheckCodePayload, ResetPasswordPayload } from "utils/interfaces";
import { useTranslation } from "react-i18next";

const useForgotPassword = () => {
  const { t } = useTranslation();
  const navigate: any = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);

  const SendConfirmationCodeToPhone = async (
    phone: string,
    withoutRedirection?: boolean
  ) => {
    setLoading(true);

    try {
      const response = await AuthenticationService.sendConfirmationCodeToPhone({
        phone,
      });
      if (response.status === 200) {
        toast.success(t('alert.sendCodeSuccess'));
        if (!withoutRedirection) {
          navigate(ROUTES.validationCode, {
            state: {
              phone,
            },
          });
        }

        setLoading(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t('alert.sendCodeError'));
      console.log(data);
      setLoading(false);
    }
  };

  const SendConfirmationCodeToEmail = async (
    email: string,
    withoutRedirection?: boolean
  ) => {
    setLoading(true);

    try {
      const response = await AuthenticationService.forgotPasswordByEmail(email);
      if (response.status === 200) {
        toast.success(t('alert.sendCodeSuccess'));
        if (!withoutRedirection) {
          navigate(ROUTES.validationCode, {
            state: {
              email,
            },
          });
        }

        setLoading(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      toast.error(t('alert.sendCodeError'));
      console.log(data);
      setLoading(false);
    }
  };

  const CheckConfirmationCode = async (payload: CheckCodePayload) => {
    setLoading(true);

    try {
      const response = await AuthenticationService.checkConfirmationCode(
        payload
      );
      if (response.status === 200) {
        toast.success(t('alert.codeCoform'));
        navigate(ROUTES.resetPassword, {
          state: { codeVerification: payload.code },
        });
        setLoading(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error("Code incorrecte");
      const data = error.response?.data;
      console.log(data);
      setLoading(false);
    }
  };

  const ResetPassword = async (payload: ResetPasswordPayload) => {
    setLoading(true);

    try {
      const response = await AuthenticationService.resetPassword(payload);

      if (response.status === 200) {
        toast.success("Votre mot de passe à été changé avec succès");
        navigate(ROUTES.login);
        setLoading(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error("Code incorrecte");
      setLoading(false);
    }
  };

  return {
    CheckConfirmationCode,
    SendConfirmationCodeToPhone,
    SendConfirmationCodeToEmail,
    sendingForgotCode: loading,
    ResetPassword,
  };
};

export { useForgotPassword };
