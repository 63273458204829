import {
  ArrowBack,
  ArrowLeft,
  ChevronLeft,
  ChevronRight,
} from "@mui/icons-material";
import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import React, { useEffect, useState } from "react";
import { Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";

const Pagination = (props: any) => {
  const { onChange, current = 1, max } = props;
  const [active, setActive] = useState(current);
  const pages = Array.from(Array(max ? max + 1 : 5).keys());
  const Content: any = Card;
  useEffect(() => {
    console.log("Pagination", "total", max, "current", current);
  }, []);
  return (
    <Grid
      display={"flex"}
      style={{ paddingTop: 40 }}
      direction={"row"}
      alignContent="flex-end"
      justifyContent={"flex-end"}
    >
      <Button
        onClick={() => {
          onChange?.(current - 1);
          setActive(current - 1);
        }}
        disabled={current === pages[1]}
        startIcon={<ChevronLeft htmlColor={baseStyles.text.default} />}
        variant="text"
        style={{ textTransform: "none", color: baseStyles.text.default }}
      >
        Previous
      </Button>
      {pages
        .filter((item) => item > 0)
        .map((item) =>
          item === active ? (
            <NeomorphContainer
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 5,
              }}
              width={40}
              height={40}
              key={item}
            >
              <Typography color={baseStyles.color.blue.primary}>
                {item}
              </Typography>
            </NeomorphContainer>
          ) : (
            <ButtonBase
              key={item}
              onClick={() => {
                onChange?.(item);
                setActive(item);
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  margin: 5,
                }}
              >
                <Typography>{item}</Typography>
              </div>
            </ButtonBase>
          )
        )}
      <Button
        disabled={current === pages.length - 1}
        endIcon={<ChevronRight htmlColor={baseStyles.text.default} />}
        variant="text"
        onClick={() => {
          onChange?.(current + 1);
          setActive(current + 1);
        }}
        style={{ textTransform: "none", color: baseStyles.text.default }}
      >
        Next
      </Button>
    </Grid>
  );
};

export default Pagination;
