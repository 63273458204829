import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import NeomorphInput from "components/InputField/NeomorphInput";
import React, { useState } from "react";
import Spacer from "components/Spacer/Spacer";
import loginLogo from "assets/images/login-img.png";
import { ROUTES } from "routes/routes";
import { useNavigate } from "react-router";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { useScreenType } from "utils/constants/UseScreenType";
import { useTranslation } from "react-i18next";

const ResetPasswordMobile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile } = useScreenType();

  const [password, setPassword] = useState({
    newPasswrd: "",
    confirm: "",
  });

  const [error, setError] = useState({
    show: false,
    message: "Les mots de passes ne sont pas identiques",
  });

  const onNewPasswordChanged = (e: any) => {
    // setPassword((prev: any) => ({ ...prev, newPasswrd: e.value }));
  };

  const onConfirmPasswordChanged = (e: any) => {
    // setPassword((prev: any) => ({ ...prev, confirm: e.value }));
  };

  const handleValidate = () => navigate(ROUTES.login);

  return (
    <CustomAuthContent
      logo={loginLogo}
      title={t('resetPassword.modifyPassword')}
      onBtnClick={handleValidate}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div style={{ width: isMobile ? "90%" : 400 }}>
          <NeomorphInputWithIcon
            isPassword
            withoutIcon={true}
            label={t('resetPassword.createNewPassword')}
            placeholder={t('resetPassword.createNewPassPlaceholder')}
            onChange={onNewPasswordChanged}
          />
        </div>
        <Spacer height={10} />

        <div style={{ width: isMobile ? "90%" : 400 }}>
          <NeomorphInputWithIcon
            isPassword
            withoutIcon={true}
            label={t('resetPassword.confirm')}
            placeholder={t('resetPassword.confirm')}
            onChange={onConfirmPasswordChanged}
          />
        </div>
        <Spacer height={10} />
      </div>
    </CustomAuthContent>
  );
};

export default ResetPasswordMobile;
