import { Avatar } from "@mui/material";
import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { DashboardJournalItem } from "utils/interfaces";

interface IProps {
  journal: any;
}

const JournalItem: FC<IProps> = ({ journal }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <section
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Avatar src={`${process.env.REACT_APP_API_URL}/${journal.profilePicture}`}/>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 15,
          }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.black,
              marginBottom: 5,
            }}
          >
            {journal.name}
          </p>

          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.default,
            }}
          >
            {journal.activity}
          </p>
        </div>
      </section>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.black,
            marginBottom: 5,
          }}
        >
          {journal.time}
        </p>

        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {journal.date}
        </p>
      </section>
    </div>
  );
};

export default JournalItem;
