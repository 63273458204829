import { defaultJournalFilter } from "pages/Journal/JournalHeader/JournalHeader";
import { useState } from "react";
import JournalHeader from "./JournalHeader/JournalHeader";
import JournalItems from "./JournalItems/JournalItems";

const ActivityJournal = () => {
  const [filter, setFilter] = useState(defaultJournalFilter);

  return (
    <section style={{ flex: 1.5 / 4 }}>
      <JournalHeader handleChangeFilter={setFilter} />

      <JournalItems filter={filter} />
    </section>
  );
};

export default ActivityJournal;
