import { FakeSelect } from "components/FakeSelect/FakeSelect";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { defaultOperatorPayload } from "hooks/useOperatorsHooks";
import { defaultJournalFilter } from "pages/Journal/JournalHeader/JournalHeader";
import OperatorCountryForm from "pages/Operators/OperatorForm/OperatorCountryForm";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";
import { IJournalFilter, PostPutOperatorPayload } from "utils/interfaces";

interface IProps {
  handleChangeFilter?: Function;
  withExport?: boolean;
}

const JournalHeader: FC<IProps> = (props: IProps) => {
  const [recherche, setRecherche] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filter, setFilter] = useState<IJournalFilter>(defaultJournalFilter);
  const { t } = useTranslation();

  const [payload, setPayload] = useState<PostPutOperatorPayload>(
    defaultOperatorPayload
  );

  const changeRecherche = (e: any) => {
    setRecherche(e);
    setFilter((old: any) => ({ ...old, keyword: e }));
  };

  useEffect(() => {
    props?.handleChangeFilter?.(filter);
  }, [filter]);

  useEffect(() => {
    if (payload) {
      setFilter((old: any) => ({ ...old, countryCode: payload.countryCode }));
    }
  }, [payload]);

  return (
    <header
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <p
          style={{
            color: baseStyles.text.black,
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {t('activity.log')}
        </p>

        <p
          style={{
            color: baseStyles.text.black,
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {t('activity.all')}
        </p>
      </div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "48%" }}>
          <NeomorphInputWithIcon
            onChange={changeRecherche}
            placeholder={t('input.search')}
          />
        </div>
        <div style={{ width: "48%" }}>
          <OperatorCountryForm
            withoutLabel
            payload={payload}
            setPayload={setPayload}
          />
        </div>
      </div>
    </header>
  );
};

export default JournalHeader;
