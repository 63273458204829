import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { EngIcon } from "components/Icons/EngIcon";
import { FrIcon } from "components/Icons/FrIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import ChoisLanguage from "./ChangeLanguage";

const SwitchLanguage = () => {
    const locales = {
        "fr": { title: 'Français', flag: <FrIcon />, },
        "en": { title: 'Anglais', flag: <EngIcon />, },
    }
    const [ lang, setLang ] = useState(localStorage.getItem('appLang') || "fr");
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(lang);
        localStorage.setItem('appLang', lang);
    }, [lang]);
    return (
        <div style={{ display: "flex", gap: "10px" }}>
            {Object.entries(locales).map(([code, { title, flag }]) => (
                <ChoisLanguage
                    key={code}
                    flag={flag}
                    title={title}
                    active={lang === code}
                    onClick={() => setLang(code)}
                />
            ))}
        </div>
    )
}

export default SwitchLanguage;