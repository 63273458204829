import { FC, ReactElement } from "react";
import styles from "./styles";
import { LogoTCash } from "components/Logo/LogoTCash";
import Spacer from "components/Spacer/Spacer";
import { LogoDashboard } from "components/Logo/LogoDashboard";
import { GoBackIcon } from "components/Icons/GoBackIcon";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { useTranslation } from "react-i18next";

interface IProps {
  children: ReactElement;
  isLogin?: boolean;
}

const CustomAuthenticationWrapper: FC<IProps> = ({ isLogin, children }) => {
  const { t } = useTranslation();
  const navigation: any = useNavigate();
  const handleGoBack = () => (!isLogin ? navigation(-1) : undefined);

  return (
    <div style={styles.container}>
      <section style={styles.left}>
        <Link to={ROUTES.login}>
          <LogoTCash />
        </Link>

        <Spacer height={50} />

        <LogoDashboard />

        <Spacer />

        <>
          <p style={styles.title}>{t('login.welcome')}</p>
          <Spacer />
          <p style={styles.description}>
          {t('login.info')}
          </p>
        </>
      </section>

      <section style={styles.right}>
        <div onClick={handleGoBack} style={styles.goBack}>
          {!isLogin && <GoBackIcon />}
        </div>

        <div style={styles.content}>{children}</div>
      </section>
    </div>
  );
};

export default CustomAuthenticationWrapper;
