import { FC, ReactElement } from "react";
import "./styles.css";
import { Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {
  children: ReactElement;
  width?: any;
  height?: number;
  style?: React.CSSProperties;
  elevation?: number;
  inset?: boolean;
}

const NeomorphContainer: FC<IProps> = ({
  style,
  children,
  width,
  height,
  inset,
  elevation,
}) => {
  const Content: any = Card;
  return (
    <Content
      elevation={elevation ?? 1}
      inset={inset}
      style={{
        width: width ?? "100%",
        padding: 10,
        height: height ?? 61,
        backgroundColor: baseStyles.color.grey,
        ...style,
      }}
    >
      {children}
    </Content>
  );
};

export default NeomorphContainer;
