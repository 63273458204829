import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import NeomorphInput from "components/InputField/NeomorphInput";
import React, { ChangeEvent } from "react";
import Spacer from "components/Spacer/Spacer";
import { baseStyles } from "utils/constants/baseStyles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";
import logoValidationCode from "assets/images/validation-code.png";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { useScreenType } from "utils/constants/UseScreenType";
import { useTranslation } from "react-i18next";

const ValidationCodeMobile = () => {
  const navigate: any = useNavigate();
  const { t } = useTranslation();
  const {isMobile} = useScreenType();

  const onEmailChanged = (e: ChangeEvent<HTMLInputElement>) => {
    // setEmail(e.target.value);
  };

  const handleValidate = () => navigate(ROUTES.resetPassword);

  return (
    <CustomAuthContent
      logo={logoValidationCode}
      title={t('resetPassword.enterCode')}
      subtitle={t('resetPassword.enterCodeMessage')}
      phoneNumber="+1 206-312-9956"
      onBtnClick={handleValidate}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div style={{ width: isMobile ? "90%" : 400 }}>
          <NeomorphInputWithIcon
            isEmail
            withoutIcon={true}
            label={t('resetPassword.enterCodeLabel')}
            placeholder={t('resetPassword.enterCodePlaceholder')}
            onChange={onEmailChanged}
          />
        </div>
        <Spacer height={10} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              color: baseStyles.color.blue.primary,
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {t('resetPassword.resend')}
          </p>

          <Spacer />

          <p
            style={{
              color: baseStyles.color.blue.primary,
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {t('resetPassword.notReceived')}
          </p>
        </div>

        <Spacer />
      </div>
    </CustomAuthContent>
  );
};

export default ValidationCodeMobile;
