import { Avatar, CircularProgress, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";
import { Benin, Moov, orange } from "utils/fakedata";
import { ITransaction, TransactionItem } from "utils/interfaces";
import { Drawer, Grid } from "@mui/material";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import Spacer from "components/Spacer/Spacer";
import CardDetails from "./CardDetails/CardDetails";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import ExportIcon from "components/Icons/ExportIcon";
import { fromNowDate, humanizeDate, reversedIso3 } from "utils/utils";

interface IProps {
  transaction: ITransaction;
}

const BottomContentItem: FC<IProps> = ({ transaction }) => {
  const { isTab } = useScreenType();
  const [openModal, setOpenModal] = useState(false);
  const toggleDrawer = () => setOpenModal(!openModal);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "5px",
        cursor: "pointer",
      }}
      onClick={toggleDrawer}
    >
      <section style={{ width: isTab ? "25%" : "14.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {humanizeDate(transaction.date)}
        </p>
      </section>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: isTab ? "25%" : "18.2%",
        }}
      >
        <img
          src={Moov}
          style={{
            height: 30,
            marginTop: 5,
            justifyContent: "center",
            alignContent: "center",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
            width: "60%",
          }}
        >
          <p
            style={{
              display: "inline-block",
              alignItems: "flex-start",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.black,
            }}
          >
            {`${transaction?.sender?.firstname} ${transaction?.sender?.lastname}`}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            {transaction?.senderAccount?.country && (
              <img
                src={`https://flagcdn.com/w80/${reversedIso3[
                  transaction?.senderAccount?.country?.code
                ]?.toLowerCase()}.png`}
                style={{ height: 20, marginTop: 4 }}
              />
            )}
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                textAlign: "center",
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.black,
              }}
            >
              {`${transaction?.senderAccount?.country?.name ?? "Non défini"}`}
            </p>
          </div>
        </div>
      </div>

      <div style={{ width: isTab ? "25%" : "10.2%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {`${transaction?.amount} ${transaction?.currency?.code}`}
        </p>
      </div>

      {!isTab && (
        <section
          style={{
            width: "10.2%",
          }}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.default,
            }}
          >
            {`${transaction?.commission} ${transaction?.currency?.code}`}
          </p>
        </section>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: isTab ? "25%" : "18.2%",
        }}
      >
        <img
          src={Moov}
          style={{
            height: 30,
            marginTop: 5,
            justifyContent: "center",
            alignContent: "center",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: 10,
            width: "60%",
          }}
        >
          <p
            style={{
              display: "inline-block",
              alignItems: "flex-start",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.black,
            }}
          >
            {`${transaction?.recipient?.lastname} ${transaction?.recipient?.firstname}`}
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 4,
            }}
          >
            {transaction?.receiverCountry && (
              <img
                src={`https://flagcdn.com/w80/${reversedIso3[
                  transaction?.receiverCountry?.code
                ]?.toLowerCase()}.png`}
                style={{ height: 20, marginTop: 4 }}
              />
            )}
            <p
              style={{
                display: "flex",
                alignItems: "flex-start",
                textAlign: "center",
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.black,
              }}
            >
              {transaction?.receiverCountry?.name ?? "Non défini"}
            </p>
          </div>
        </div>
      </div>

      {!isTab && (
        <>
          <div style={{ width: "14.2%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {`TMXITE-TR-0000${transaction?.id}`}
            </p>
          </div>
          <div style={{ width: "14.2%" }}>
            <p
              style={{
                fontWeight: 500,
                fontSize: 12,
                color: baseStyles.text.default,
              }}
            >
              {`${
                transaction?.paymentMethod?.code === "MOBILE_MONEY"
                  ? "MOMO"
                  : "CB"
              }`}
            </p>
          </div>
        </>
      )}

      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Grid
          style={{
            width: 350,
            height: "auto",
            background: baseStyles.color.grey,
            paddingBottom: 40,
            padding: 25,
          }}
        >
          <Grid
            style={{ display: "flex" }}
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontSize={18} fontWeight={600}>
              {"Détails de la transaction"}
            </Typography>

            <CustomIconButton
              icon={<CloseIcon />}
              onClick={() => setOpenModal(false)}
            />
          </Grid>
          <Spacer />
          <Spacer />
          <NeomorphContainer style={{ height: "auto", padding: 20 }}>
            <div style={{ gap: 5 }}>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                Mode de transfert
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: baseStyles.text.black,
                }}
              >
                {transaction?.paymentMethod?.code === "MOBILE_MONEY"
                  ? `MOMO -> MOMO`
                  : `CB -> CB`}
              </p>

              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                Date
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: baseStyles.text.black,
                }}
              >{`${humanizeDate(transaction?.date)}`}</p>

              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                Montant envoyé
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: baseStyles.text.black,
                }}
              >{`${transaction?.amount} ${transaction?.currency?.code}`}</p>

              <p
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  color: baseStyles.text.default,
                }}
              >
                Référence
              </p>
              <p
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  color: baseStyles.text.black,
                }}
              >{`TMIXTE-TR-0000${transaction?.id}`}</p>
            </div>
          </NeomorphContainer>
          <Spacer height={40} />
          <Grid>
            <Typography fontSize={18} fontWeight={600}>
              {"Expéditeur"}
            </Typography>
            <Spacer />
            {transaction?.senderAccount ? (
              <CardDetails
                email={transaction?.sender?.email}
                fullName={`${transaction?.sender?.firstname} ${transaction?.sender?.lastname}`}
                operator={`${
                  transaction?.senderAccount?.operator?.name ?? "Non défini"
                }`}
                countryCode={
                  transaction?.senderAccount?.country?.code ?? "Non défini"
                }
                countryName={
                  transaction?.senderAccount?.country?.name ?? "Non défini"
                }
              />
            ) : (
              <Typography>Non défini</Typography>
            )}
          </Grid>
          <Spacer />
          <Grid>
            <Typography fontSize={18} fontWeight={600}>
              {"Destinataire"}
            </Typography>
            <Spacer />
            {transaction?.recipient && (
              <CardDetails
                countryName={transaction?.receiverCountry?.name}
                email={transaction?.recipient?.owner?.email}
                fullName={`${transaction?.recipient?.firstname} ${transaction?.recipient?.lastname}`}
                operator={`${
                  transaction?.recipient?.account?.operator?.name ??
                  "Non défini"
                }`}
                countryCode={transaction?.receiverCountry?.code}
              />
            )}
          </Grid>
          <Spacer height={40} />
          <NeuromorphBtn
            endIcon={<ExportIcon />}
            label="Export"
            onClick={() => ""}
            width={"100%"}
          />
        </Grid>
      </Drawer>
    </div>
  );
};

export default BottomContentItem;
