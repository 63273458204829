import axios from "services/AxiosInstance";
import {
  CheckCodePayload,
  ResetPasswordPayload,
  SendCodeToEmailPayload,
  SendCodeToMobilePayload,
  UserInfo,
} from "utils/interfaces";

const getUserInfo = () => {
  return axios.get(`api/users/me`);
};

const sendConfirmationCodeToPhone = (payload: SendCodeToMobilePayload) => {
  return axios.post(`api/users/requestPhoneConfirmationCode`, {
    ...payload,
  });
};

const sendConfirmationCodeToEmail = (payload: SendCodeToEmailPayload) => {
  return axios.post(`api/users/requestEmailConfirmationCode`, {
    ...payload,
  });
};

const forgotPasswordByEmail = (email: string) => {
  console.log("forgotPassword with email : ", email);

  return axios.post(`api/users/forgotPassword`, {
    email: email,
    type: "CODE",
  });
};

const forgotPasswordByPhone = (phone: string) => {
  console.log("forgotPassword with phone : ", phone);

  return axios.post(`api/users/forgotPassword`, {
    phone: phone,
    type: "CODE",
  });
};

const resetPassword = (payload: ResetPasswordPayload) => {
  return axios.post(`api/users/resetPassword`, {
    ...payload,
  });
};

const checkForgotPasswordCodeByEmail = (email: string, code: string) => {
  console.log("checkForgotPasswordCode with email : ", email, code);

  return axios.post(`api/users/checkPasswordReinitialisationCode`, {
    email: email,
    code: code,
  });
};

const checkConfirmationCode = (payload: CheckCodePayload) => {
  return axios.post(`api/users/checkPasswordReinitialisationCode`, {
    ...payload,
  });
};

const updateUserInfo = (userInfo: UserInfo) => {
  return axios.put(`api/users/${userInfo?.id}`, {
    firstname: userInfo.firstname,
    lastname: userInfo.lastname,
  });
};

const updateUserPhone = (
  userInfo: UserInfo,
  phone: string,
  secureUpdateCode: string
) => {
  console.log("perform updat phone with", phone, secureUpdateCode);

  return axios.put(`api/users/${userInfo?.id}`, {
    phone: phone,
    secureUpdateCode: secureUpdateCode,
  });
};

const updateUserPassword = (
  userInfo: UserInfo,
  actualPassword: string,
  newPassword: string
) => {
  return axios.put(`api/users/${userInfo?.id}`, {
    actualPassword: actualPassword,
    newPassword: newPassword,
  });
};

const AuthenticationService = {
  getUserInfo,
  forgotPasswordByEmail,
  forgotPasswordByPhone,
  updateUserInfo,
  checkForgotPasswordCodeByEmail,
  checkConfirmationCode,
  updateUserPhone,
  updateUserPassword,
  resetPassword,
  sendConfirmationCodeToPhone,
  sendConfirmationCodeToEmail,
};

export default AuthenticationService;
