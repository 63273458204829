import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2169_3963"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="matrix(1 0 0 -1 0 32)"
        fill="#D9D9D9"
      />
    </mask>
    <g mask="url(#mask0_2169_3963)">
      <rect
        width="10.6667"
        height="32"
        transform="matrix(1 0 0 -1 3.05176e-05 32)"
        fill="#004692"
      />
      <rect
        width="10.6667"
        height="32"
        transform="matrix(1 0 0 -1 10.6668 32)"
        fill="#F5F7F8"
      />
      <rect
        width="10.6667"
        height="32"
        transform="matrix(1 0 0 -1 21.3335 32)"
        fill="#DD2033"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as FrIcon };
