import { FC, useEffect, useState } from "react";
import OperatorItem from "./OperatorItem/OperatorItem";
import useOperatorsHooks from "hooks/useOperatorsHooks";
import { CircularProgress } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import { IOperatorFilter } from "utils/interfaces";

interface IProps {
  onClickDetails?: Function;
  filter: IOperatorFilter;
}

const OperatorItems: FC<IProps> = ({ onClickDetails, filter }) => {
  const [filteredOperators, setFilteredOperators] = useState([]);
  const {
    FetchOperator,
    operators,
    loading,
    updateOperatorList,
    FetchOperatorProviders,
  } = useOperatorsHooks();

  useEffect(() => {
    setFilteredOperators(operators?.results);
  }, [operators]);

  useEffect(() => {
    FetchOperator(filter?.operator);
  }, [updateOperatorList, filter?.operator]);

  const items = operators?.results ?? [];

  useEffect(() => {
    let _filtered = operators?.results
      ?.filter((item: any) => {
        if (filter.keyword?.length === 0) {
          return true;
        } else {
          return (
            item?.name?.toLowerCase()?.includes(filter.keyword.toLowerCase()) ||
            item?.country?.name
              ?.toLowerCase()
              ?.includes(filter.keyword.toLowerCase())
          );
        }
      })
      .filter((item: any) => {
        if (!filter.zone) {
          return true;
        } else {
          return item?.country?.zone?.zoneCode === filter?.zone?.zoneCode;
        }
      });
    setFilteredOperators(_filtered);
  }, [filter]);

  useEffect(() => {}, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <CircularProgress
          style={{ color: baseStyles.color.blue.primary }}
          disableShrink
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: 20,
      }}
    >
      {filteredOperators?.map((item: any) => (
        <OperatorItem
          onClickDetails={(data: any) => onClickDetails?.(data)}
          item={item}
          key={item.id}
        />
      ))}
    </div>
  );
};

export default OperatorItems;
