import { CameraAlt, Edit, Mail, PersonRounded } from "@mui/icons-material";
import { Avatar, Button, ButtonBase, Grid, Typography } from "@mui/material";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import NeomorphPhoneInput from "components/PhoneInput/NeomorphPhoneInput";
import Spacer from "components/Spacer/Spacer";
import useUsersHooks from "hooks/useUsersHooks";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { baseStyles } from "utils/constants/baseStyles";
import { getStatus, roles } from "utils/constants/constants";
import { IUserItem } from "utils/interfaces";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  convertImageToBase64,
  isValidEmail,
  minLengthValidator,
} from "utils/utils";
import { useTranslation } from "react-i18next";

interface IProps {
  onClickSaveBtn?: Function;
  user?: IUserItem;
  toggleModal?: Function;
}

const UserForm: FC<IProps> = ({ toggleModal, user }) => {
  const {
    userPayload,
    setUserPayload,
    loading,
    performCreateUser,
    performUpdateUser,
    messageError,
    isValidPayload,

    DeleteUser,
    deleting,
  } = useUsersHooks({ onOperationSuccess: () => toggleModal?.() });
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const status = getStatus(t);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      // handleFile(fileUploaded);
      try {
        const base64String = await convertImageToBase64(fileUploaded);
        console.log("Base64 string:", base64String);
        setProfilePicture(base64String);
      } catch (error) {
        console.error("Error converting image to base64:", error);
        toast.error("File Error Please provide an Image file");
      }
    }
  };

  const [profilePicture, setProfilePicture] = useState<any>(null);
  useEffect(() => {
    if (profilePicture) {
      setUserPayload((old) => ({ ...old, profilePicture: profilePicture }));
    }
  }, [profilePicture]);

  useEffect(() => {
    if (user) {
      setUserPayload((old) => ({
        ...old,
        id: user.id,
        active: user.active ?? 0,
        firstname: user.firstname ?? "",
        lastname: user.lastname ?? "",
        email: user.email,
        phone: user?.phone ?? "",
        role:
          user?.groups && user?.groups?.length > 0
            ? (user.groups[0] as "USER" | "ADMIN")
            : "USER",
        profilePicture: user.profilePicture
          ? `${process.env.REACT_APP_API_URL}/${user?.profilePicture?.path}`
          : "",
      }));
      if (user.profilePicture) {
        setProfilePicture(
          `${process.env.REACT_APP_API_URL}/${user?.profilePicture?.path}`
        );
      }
    }
  }, [user]);

  return (
    <div
      style={{
        marginTop: 20,
        overflow: "auto",
        background: baseStyles.color.grey,
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <input
          type={"file"}
          hidden
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
        {profilePicture ? (
          <div style={{ position: "relative" }}>
            <Avatar src={profilePicture} sx={{ height: 150, width: 150 }} />
            <NeomorphContainer
              elevation={0}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 40,
                background: "white",
                position: "absolute",
                left: 90,
                bottom: -10,
              }}
              width={40}
              height={40}
            >
              <ButtonBase
                onClick={handleClick}
                disabled={loading}
                style={{ borderRadius: user ? 32 : 5 }}
              >
                <Edit
                  style={{
                    background: baseStyles.color.blue.primary40,
                    borderRadius: 20,
                    padding: 5,
                  }}
                  htmlColor={"white"}
                  sx={{ width: 32, height: 32 }}
                />
              </ButtonBase>
            </NeomorphContainer>
          </div>
        ) : (
          <ButtonBase
            onClick={handleClick}
            disabled={loading}
            style={{ borderRadius: user ? 85 : 5 }}
          >
            <NeomorphContainer
              style={{
                background: baseStyles.color.blue.primary40,
                padding: 15,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              width={300}
              height={75}
            >
              <Grid display={"flex"} direction={"row"} alignItems="center">
                <NeomorphContainer
                  elevation={0}
                  style={{
                    background: baseStyles.color.blue.primary40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  width={40}
                  height={40}
                >
                  <CameraAlt htmlColor="white" />
                </NeomorphContainer>

                <p style={{ color: "white", marginLeft: 10 }}>
                  {t('user.imageDown')}
                </p>
              </Grid>
            </NeomorphContainer>
          </ButtonBase>
        )}
      </div>
      <Spacer height={20} />

      <NeomorphInputWithIcon
        width={300}
        label={t('user.lastName')}
        placeholder={t('user.lastName')}
        disabled={loading}
        validatorMessage="Entrez 3 caractères minimum."
        valid={
          userPayload.firstname.length === 0 ||
          minLengthValidator(userPayload.firstname, 3)
        }
        icon={<PersonRounded htmlColor={baseStyles.text.default} />}
        value={userPayload.firstname}
        onChange={(text: string) => {
          setUserPayload((old) => ({ ...old, firstname: text }));
        }}
      />
      <Spacer height={20} />

      <NeomorphInputWithIcon
        width={300}
        disabled={loading}
        label={t('user.firstName')}
        placeholder={t('user.firstName')}
        validatorMessage="Entrez 3 caractères minimum."
        valid={
          userPayload.lastname.length === 0 ||
          minLengthValidator(userPayload.lastname, 3)
        }
        icon={<Mail htmlColor={baseStyles.text.default} />}
        value={userPayload.lastname}
        onChange={(text: string) => {
          setUserPayload((old) => ({ ...old, lastname: text }));
        }}
      />
      <Spacer height={20} />
      <NeomorphInputWithIcon
        width={300}
        disabled={loading}
        label={t('user.email')}
        placeholder={t('user.email')}
        icon={<Mail htmlColor={baseStyles.text.default} />}
        value={userPayload.email}
        onChange={(text: string) => {
          setUserPayload((old) => ({ ...old, email: text }));
        }}
        validatorMessage="Email invalide."
        valid={
          userPayload.email.length === 0 || isValidEmail(userPayload.email)
        }
      />
      <Spacer height={20} />
      <FakeSelect
        items={roles}
        disabled={loading}
        value={userPayload.role}
        label={t('user.role')}
        placeholder="Admin"
        onchange={(role) => setUserPayload((old) => ({ ...old, role: role }))}
      />
      <Spacer height={20} />
      {/* <NeomorphInputWithIcon
        width={300}
        disabled={loading}
        label="Téléphone"
        placeholder="Téléphone"
        validatorMessage="Numéro de téléphone invalide."
        valid={
          userPayload.phone.length === 0 ||
          isValidPhoneNumber(userPayload.phone)
        }
        icon={<Phone htmlColor={baseStyles.text.default} />}
        value={userPayload.phone}
        onChange={(text: string) => {
          setUserPayload((old) => ({ ...old, phone: text }));
        }}
      /> */}
      <NeomorphPhoneInput
        valid={
          userPayload?.phone?.length === 0 ||
          (userPayload?.phone?.length > 0 &&
            isValidPhoneNumber(userPayload.phone))
        }
        value={userPayload.phone}
        onChange={(text: string) => {
          setUserPayload((old) => ({ ...old, phone: text }));
        }}
        validatorMessage="Numéro de téléphone invalide."
      />
      <Spacer height={20} />
      <FakeSelect
        disabled={loading}
        items={status}
        value={userPayload.active}
        label={t('user.status')}
        placeholder={t('input.active')}
        onchange={(_status) =>
          setUserPayload((old) => ({ ...old, active: _status }))
        }
      />
      <Spacer height={20} />
      <NeuromorphBtn
        width={300}
        disabled={!isValidPayload || loading}
        loading={loading}
        label={
          user ? "Enregistrer les modifications" : t('user.create')
        }
        onClick={() => {
          user ? performUpdateUser() : performCreateUser();
        }}
      />
      {messageError && (
        <Typography
          style={{
            textAlign: "center",
            marginTop: 30,
            color: baseStyles.color.error.color,
          }}
        >
          {messageError}
        </Typography>
      )}
      {user && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Button
            variant="text"
            style={{
              textTransform: "none",
              width: 300,
              color: baseStyles.color.error.color,
            }}
            onClick={() => DeleteUser(userPayload.id ?? 0)}
          >
            {deleting ? t('user.deleteAccountIn') : t('user.deleteAccount')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UserForm;
