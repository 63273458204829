import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";

const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    ref={ref}
    {...props}
  >
    <g fill={props.color ?? "#6E847E"}>
      <path d="M2.5 7c0-2.121 0-3.182.659-3.841C3.818 2.5 4.879 2.5 7 2.5c2.121 0 3.182 0 3.841.659.659.659.659 1.72.659 3.841 0 2.121 0 3.182-.659 3.841-.659.659-1.72.659-3.841.659-2.121 0-3.182 0-3.841-.659C2.5 10.182 2.5 9.121 2.5 7ZM13.5 18c0-2.121 0-3.182.659-3.841.659-.659 1.72-.659 3.841-.659 2.121 0 3.182 0 3.841.659.659.659.659 1.72.659 3.841 0 2.121 0 3.182-.659 3.841-.659.659-1.72.659-3.841.659-2.121 0-3.182 0-3.841-.659-.659-.659-.659-1.72-.659-3.841ZM2.5 18c0-2.121 0-3.182.659-3.841C3.818 13.5 4.879 13.5 7 13.5c2.121 0 3.182 0 3.841.659.659.659.659 1.72.659 3.841 0 2.121 0 3.182-.659 3.841-.659.659-1.72.659-3.841.659-2.121 0-3.182 0-3.841-.659C2.5 21.182 2.5 20.121 2.5 18ZM13.5 7c0-2.121 0-3.182.659-3.841C14.818 2.5 15.879 2.5 18 2.5c2.121 0 3.182 0 3.841.659.659.659.659 1.72.659 3.841 0 2.121 0 3.182-.659 3.841-.659.659-1.72.659-3.841.659-2.121 0-3.182 0-3.841-.659C13.5 10.182 13.5 9.121 13.5 7Z"
      stroke={props.color ?? "#6E847E"} />
      
    </g>
    <defs>
      <filter
        id="a"
        width={24}
        height={25}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1147_1604" />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as DashboardIcon };
