import { AddBox, AddCard } from "@mui/icons-material";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import CreateUserIcon from "components/Icons/UserCreateIcon";
import NeomorphInput from "components/InputField/NeomorphInput";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import React, { FC, useEffect, useState } from "react";
import "./input.css";
import { useScreenType } from "utils/constants/UseScreenType";
import { IOperator, IOperatorFilter, IZone } from "utils/interfaces";
import { defaultOperatorFilter } from "../Operators";
import { ZoneSelectOptions } from "components/FakeSelect/ZoneSelectOptions";
import useCountryHooks from "hooks/useCountryHooks";
import { CircularProgress } from "@mui/material";
import { OperatorProviderOptions } from "components/FakeSelect/OperatorProviderOptions";
import { useTranslation } from "react-i18next";
interface IProps {
  onClickCreateBtn?: Function;
  handleChangeFilter?: Function;
}

const OperatorFilter: FC<IProps> = ({
  onClickCreateBtn,
  handleChangeFilter,
}) => {
  const {
    FetchCountries,
    FetchCountriesZones,
    loading,
    zones,
    countries,
    operatorProviders,
    FetchOperatorProviders,
    UpdateCountry,
  } = useCountryHooks();
  const [selectedZone, setSelectedZone] = useState<IZone | undefined>(
    undefined
  );

  const [selectedOperatorProvider, setSelectedOperatorProvider] = useState<
    IOperator | undefined
  >(undefined);
  const [filter, setFilter] = useState<IOperatorFilter>(defaultOperatorFilter);
  const handleInputValueChange = (value: string) => {
    setFilter((old) => ({ ...old, keyword: value }));
  };
  const { isTab } = useScreenType();
  const onBtnClick = () => {
    onClickCreateBtn?.();
  };
  const { t } = useTranslation();

  useEffect(() => {
    setFilter((item) => ({
      ...item,
      operator: selectedOperatorProvider?.code,
    }));
  }, [selectedOperatorProvider]);

  useEffect(() => {
    setFilter((item) => ({
      ...item,
      zone: selectedZone,
    }));
  }, [selectedZone]);

  useEffect(() => {
    handleChangeFilter?.(filter);
  }, [filter]);

  useEffect(() => {
    FetchCountriesZones();
    FetchOperatorProviders();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isTab ? "column" : "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isTab && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <ZoneSelectOptions
              label=""
              placeholder={t('country.all')}
              items={zones}
              onchange={(item: IZone) => {
                setSelectedZone(item);
              }}
              zoneId={selectedZone?.id}
            />
          )}
          <Spacer width={20}></Spacer>
          {loading ? (
            <CircularProgress />
          ) : (
            <OperatorProviderOptions
              label=""
              placeholder={t('operator.all')}
              items={operatorProviders}
              onchange={(item: IOperator) => {
                setSelectedOperatorProvider(item);
              }}
              operatorId={selectedOperatorProvider?.id}
            />
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {!isTab && (
          <>
            <div style={{ width: "25%" }}>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <ZoneSelectOptions
                  label=""
                  placeholder={t('country.all')}
                  items={zones}
                  onchange={(item: IZone) => {
                    setSelectedZone(item);
                  }}
                  zoneId={selectedZone?.id}
                />
              )}
            </div>
            <Spacer width={20}></Spacer>
            <div style={{ width: "25%" }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <OperatorProviderOptions
                  label=""
                  placeholder={t('operator.all')}
                  items={operatorProviders}
                  onchange={(item: IOperator) => {
                    setSelectedOperatorProvider(item);
                  }}
                  operatorId={selectedOperatorProvider?.id}
                />
              )}
            </div>
            <Spacer width={20}></Spacer>
          </>
        )}

        <div style={{ width: isTab ? "50%" : "35%" }}>
          <NeomorphInputWithIcon
            withoutPlaceHolder
            label=""
            placeholder={t('input.search')}
            onChange={handleInputValueChange}
          />
        </div>

        <Spacer width={20}></Spacer>
        <NeuromorphBtn
          width={isTab ? "50%" : 280}
          startIcon={<AddBox />}
          label={t('operator.add')}
          onClick={onBtnClick}
        />
      </div>
    </div>
  );
};

export default OperatorFilter;
