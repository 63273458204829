import React from "react";
import { baseStyles } from "utils/constants/baseStyles";
import countryStyle from "../CountryStyle";
import Spacer from "components/Spacer/Spacer";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import { useTranslation } from "react-i18next";

const CountryStat = () => {
  const classes = countryStyle();
  const { t } = useTranslation();
  return (
    <div>
      <p className={classes.textTitle}>{t('country.transactions')}</p>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>{t('country.africaO')}</p>

            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value="2"
                label={t('country.sameMoney')}
                onChange={() => ""}
                withoutPlaceHolder={true}
                unite="%"
              />
              <NeomorphInputWithIcon
                withoutIcon={true}
                value="2"
                label={t('country.diffMoney')}
                onChange={() => ""}
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>{t('country.africaC')}</p>

            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value="2"
                label={t('country.sameMoney')}
                onChange={() => ""}
                withoutPlaceHolder={true}
                unite="%"
              />
              <NeomorphInputWithIcon
                withoutIcon={true}
                value="3"
                label={t('country.diffMoney')}
                onChange={() => ""}
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto" }}>
          <div>
            <p className={classes.textTitle}>
              {t('country.two')}
            </p>
            <Spacer />
            <div>
              <NeomorphInputWithIcon
                withoutIcon={true}
                value="2"
                onChange={() => ""}
                withoutPlaceHolder={true}
                unite="%"
              />
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeuromorphBtn
          label={t('country.majPercentage')}
          onClick={() => ""}
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default CountryStat;
