import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { IJournalFilter } from "utils/interfaces";
import BottomContentItems from "./BottomContentItems/BottomContentItems";

interface IProps {
  filter: IJournalFilter;
}

const BottomContent: FC<IProps> = ({ filter }: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <BottomContentItems filter={filter} />
    </div>
  );
};

export default BottomContent;
