import { FC } from "react";
import { useTranslation } from "react-i18next";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {}

const BottomContentItemTitle: FC<IProps> = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        width: "100%",
        padding: 10,
      }}
    >
      <div style={{ width: "25%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t('title.user')}
        </p>
      </div>
      <div style={{ width: "15%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t('title.country')}
        </p>
      </div>
      <div style={{ width: "30%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t('title.activity')}
        </p>
      </div>
      <div style={{ width: "15%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          {t('title.date')}
        </p>
      </div>
      <div style={{ width: "10%" }}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        ></p>
      </div>
    </div>
  );
};

export default BottomContentItemTitle;
