import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M33.333 17c0 9.205-7.462 16.667-16.666 16.667C7.462 33.667 0 26.205 0 17 0 7.795 7.462.333 16.667.333 25.87.333 33.333 7.795 33.333 17Zm-21.717-5.05a1.25 1.25 0 0 1 1.768 0l3.283 3.282 3.282-3.283a1.25 1.25 0 1 1 1.768 1.768L18.434 17l3.283 3.283a1.25 1.25 0 0 1-1.768 1.767l-3.282-3.282-3.283 3.282a1.25 1.25 0 1 1-1.768-1.767L14.9 17l-3.283-3.283a1.25 1.25 0 0 1 0-1.768Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as CloseCircleIcon };
