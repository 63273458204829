import { FC, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import { useScreenType } from "utils/constants/UseScreenType";
import BottomContentItems from "pages/Transactions/BottomContent/BottomContentItems/BottomContentItems";
import HeaderContent, {
  defaultTransactionFilter,
} from "pages/Transactions/HeaderContent/HeaderContent";
import Spacer from "components/Spacer/Spacer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {}

const BottomContent: FC<IProps> = (props: IProps) => {
  const { isMobile, isTab } = useScreenType();
  const [filter, setFilter] = useState(defaultTransactionFilter);
  const [exportToPdf, setExportToPDF] = useState(0);
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginTop: isTab ? 0 : 80,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexGrow: 1,
        }}
      >
        <p
          style={{
            color: baseStyles.text.black,
            fontSize: 18,
            fontWeight: 700,
          }}
        >
          {t('title.lastTransaction')}
        </p>

        <Link to={"/transactions"}>
          <p
            style={{
              color: baseStyles.text.black,
              fontSize: 12,
              fontWeight: 400,
              textDecorationLine: "underline",
            }}
          >
            {t('title.allTransaction')}
          </p>
        </Link>
      </div>
      <Spacer />
      <HeaderContent
        handleChangeFilter={setFilter}
        onExport={() => {
          setExportToPDF(exportToPdf + 1);
        }}
      />

      <BottomContentItems exportToPdf={exportToPdf} filter={filter} />
    </div>
  );
};

export default BottomContent;
