import * as React from "react";
import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { ButtonBase, Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

interface IProps {
  setActive?: any;
  totalPage: number;
}

const CustomPagination: React.FC<IProps> = (props: IProps) => {
  const { setActive, totalPage } = props;
  const { items } = usePagination({
    count: totalPage,
  });

  const activePageItem = items.find((item) => item.selected);

  const activePage = activePageItem ? activePageItem.page : 1;

  useEffect(() => {
    setActive(activePage);
  }, [activePage]);

  return (
    <nav style={{ display: "flex", justifyContent: "flex-end" }}>
      <List style={{ gap: 5, display: "flex", alignItems: "center" }}>
        {items?.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = selected ? (
              <NeomorphContainer
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 5,
                }}
                width={40}
                height={40}
                key={page}
              >
                <Typography color={baseStyles.color.blue.primary}>
                  {page}
                </Typography>
              </NeomorphContainer>
            ) : (
              <button
                type="button"
                {...item}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  margin: 5,
                  border: "none",
                  background: "transparent",
                }}
              >
                <Typography>{page}</Typography>
              </button>
            );
          } else {
            children = (
              <ButtonBase type="button" {...item}>
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "auto",
                    height: 40,
                    margin: 5,
                  }}
                >
                  {type === "previous" && (
                    <ChevronLeft htmlColor={baseStyles.text.default} />
                  )}
                  <Typography style={{ color: baseStyles.text.default }}>
                    {type}
                  </Typography>
                  {type === "next" && (
                    <ChevronRight htmlColor={baseStyles.text.default} />
                  )}
                </div>
              </ButtonBase>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default CustomPagination;
