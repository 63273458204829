import React, { useEffect, useState } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import Spacer from "components/Spacer/Spacer";
import { CloseCircleIcon } from "components/Icons/CloseCircleIcon";
import { ClockCircleIcon } from "components/Icons/ClockCircleIcon";
import { MoneyIcon } from "components/Icons/MoneyIcon";
import { ITransaction, TodayCardItem } from "utils/interfaces";
import { ButtonBase, CircularProgress, Grid, Typography } from "@mui/material";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import useDashboardHooks from "hooks/useDashboardHooks";
import {
  formatToCalendarDate,
  getStartAndEndOfMonth,
  getStartAndEndOfYear,
  getWeeks,
} from "utils/utils";
import { Button } from "ui-neumorphism";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import TransactionsStyles from "pages/Transactions/TransactionsStyles";
import { CustomDateRangePicker } from "components/DateRangeInput/DateRangeInput";
import { addDays } from "date-fns";
import TodayCardChart from "pages/Dashboard/TopContent/TodayContent/TodayCardChart/TodayCardChart";
import TodayCard from "pages/Dashboard/TopContent/TodayContent/TodayCard/TodayCard";
import { useTranslation } from "react-i18next";
const CalendarBtn: any = Button;
interface SeriesData {
  name: string;
  data: number[];
}

interface CategoriesData {
  [date: string]: boolean;
}
const TodayContent = () => {
  const { t } = useTranslation();
  const _cardItems: TodayCardItem[] = [
    {
      id: 1,
      description: t('commission.earnedCommissions'),
      value: "6540",
      icon: <MoneyIcon />,
      bgColor: baseStyles.color.tertiaire,
    },
    {
      id: 2,
      description: t('commission.pendingCommissions'),
      value: "539",
      icon: <ClockCircleIcon />,
      bgColor: baseStyles.color.secondaire,
    },
    {
      id: 3,
      description: t('commission.cancelledCommissions'),
      value: "1256",
      icon: <CloseCircleIcon />,
      bgColor: baseStyles.color.quaternaire,
    },
  ];

  const periods = [
    {
      key: "today",
      title: t('periods.today'),
    },
    {
      key: "this_week",
      title: t('periods.week'),
    },
    {
      key: "this_month",
      title: t('periods.month'),
    },
    {
      key: "this_year",
      title: t('periods.year'),
    },
  ];
  const [cardItems, setCardItems] = useState<TodayCardItem[]>(_cardItems);
  const [activePeriod, setActivePeriod] = useState<any>("today");
  const { fetchTransactions, transactions, loading, dateRange, setDateRange } =
    useDashboardHooks();
  const [total, setTotal] = useState({
    success: 0,
    canceled: 0,
    waiting: 0,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);

  const classes = TransactionsStyles();

  function processTransactions(transactions: ITransaction[]): {
    series: SeriesData[];
    categories: string[];
  } {
    const series: SeriesData[] = [];
    const categories: CategoriesData = {};

    // Extract unique dates and initialize series data
    transactions.forEach((transaction) => {
      categories[transaction.date] = true;
    });
    // Extract unique status codes and initialize series data
    const statusCodes = Array.from(
      new Set(transactions.map((transaction) => transaction.status.code))
    );
    statusCodes.forEach((statusCode) => {
      const data: number[] = Array(Object.keys(categories).length).fill(0);
      series.push({ name: statusCode, data });
    });

    // Fill series data with cumulative amounts for each status code
    transactions.forEach((transaction) => {
      const dateIndex = Object.keys(categories).indexOf(transaction.date);
      const amount = parseFloat(transaction.commissionConverted);
      const seriesIndex = statusCodes.indexOf(transaction.status.code);
      if (!isNaN(amount) && seriesIndex !== -1) {
        series[seriesIndex].data[dateIndex] += Number(amount.toFixed(1));
      }
    });

    // Convert categories object to array
    series.map((item) => {
      if (item.name === "WAITING") {
        item.name = "En attente";
      } else if (item.name === "SUCCESS") {
        item.name = "Gagnées";
      } else {
        item.name = "Annulées";
      }
      return item;
    });
    const categoryKeys = Object.keys(categories).sort();
    const categoriesArray = categoryKeys.map((date) => date);

    return { series, categories: categoriesArray };
  }

  const updateChart = (_transactions: ITransaction[]) => {
    const success = _transactions
      .filter((item) => item.status.code === "SUCCESS")
      .map((item) => parseFloat(item.commissionConverted))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const waiting = _transactions
      .filter((item) => item.status.code === "WAITING")
      .map((item) => parseFloat(item.commissionConverted))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const cancelled = _transactions
      .filter((item) => item.status.code === "CANCELED")
      .map((item) => parseFloat(item.commissionConverted))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    cardItems[0].value = success.toString();
    cardItems[1].value = waiting.toString();
    cardItems[2].value = cancelled.toString();
    setCardItems(cardItems);
    setTotal({ success: success, waiting: waiting, canceled: cancelled });
  };
  const [chartData, setChartData] = useState<any>({
    series: [],
    categories: [],
  });

  useEffect(() => {
    const { series, categories } = processTransactions(transactions);

    console.log("Series:", series);
    console.log("Categories:", categories);
    updateChart(transactions);
    setChartData({ series: series, categories: categories });
  }, [transactions]);

  useEffect(() => {
    setActivePeriod("today");
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (activePeriod) {
      let range = {
        minDate: new Date(),
        maxDate: new Date(),
      };
      switch (activePeriod) {
        case "today":
          break;
        case "this_week":
          range.minDate = getWeeks().start;
          range.maxDate = new Date();
          break;
        case "this_month":
          range.minDate = getStartAndEndOfMonth().start;
          range.maxDate = new Date();

          break;
        case "this_year":
          range.minDate = getStartAndEndOfYear().start;
          range.maxDate = new Date();

          break;
      }

      setDateRange(range);
    }
  }, [activePeriod]);

  // useEffect(() => {
  //   setActivePeriod(null);
  //   setDateRange({ maxDate: range.end, minDate: range.start });
  //   setShowDatePicker(false);
  // }, [range]);

  return (
    <section style={{ flex: 4 }}>
      <Grid
        display={"flex"}
        direction={"row"}
        alignContent="center"
        justifyContent={"space-between"}
      >
        <Grid
          display={"flex"}
          direction={"row"}
          alignContent="center"
          justifyContent={"space-between"}
        >
          {periods.map((item) =>
            item.key === activePeriod ? (
              <div key={item.key} style={{ display: "flex" }}>
                <NeomorphContainer
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 5,
                  }}
                  // width={120}
                  height={40}
                >
                  <Typography
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 11,
                    }}
                    fontWeight={"600"}
                    color={baseStyles.text.black}
                  >
                    {item.title}
                  </Typography>
                </NeomorphContainer>
              </div>
            ) : (
              <ButtonBase
                key={item.title}
                onClick={() => setActivePeriod(item.key)}
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <Typography
                    fontSize={11}
                    fontWeight={"600"}
                    color={baseStyles.text.default}
                  >
                    {item.title}
                  </Typography>
                </div>
              </ButtonBase>
            )
          )}
        </Grid>
        <div
          style={{
            flex: 1,
            justifyContent: "flex-end",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "12px",
              fontWeight: "600",
              marginLeft: "10px",
            }}
          >
            {t('periods.set')}
          </p>
          <CalendarBtn
            onClick={() => setShowDatePicker(!showDatePicker)}
            style={{
              marginLeft: 10,
              height: "45px",
              borderRadius: "10px",
              padding: "15px",
            }}
          >
            <CalendarIcon />{" "}
            {!activePeriod && (
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "600",
                  marginLeft: "10px",
                }}
              >
                {`${formatToCalendarDate(
                  dateRange.minDate
                )} - ${formatToCalendarDate(dateRange.maxDate)}`}
              </p>
            )}
          </CalendarBtn>
        </div>
        <CustomDateRangePicker
          visible={showDatePicker}
          toggle={() => setShowDatePicker(false)}
          onchange={(range) => {
            setActivePeriod(null);
            setDateRange({ minDate: range.start, maxDate: range.end });
          }}
        />
      </Grid>

      <Spacer />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {cardItems?.map((content) => (
          <TodayCard width={"32%"} key={content.id} content={content} />
        ))}
      </div>

      <Spacer />

      {loading ? (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <TodayCardChart
          categories={chartData.categories}
          series={chartData.series}
        />
      )}
    </section>
  );
};

export default TodayContent;
