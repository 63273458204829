import LoginWeb from "./LoginWeb";
import LoginMobile from "./LoginMobile";
import { useScreenType } from "utils/constants/UseScreenType";

const Login = () => {
  const { isTab } = useScreenType();

  return !isTab ? <LoginWeb /> : <LoginMobile />;
};

export default Login;
