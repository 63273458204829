import { Box, Typography } from "@mui/material";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import NeomorphInputWithIcon, {
  CssTextField,
} from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import React, { FC, useEffect, useRef, useState } from "react";
import { Button } from "ui-neumorphism";
import { useScreenType } from "utils/constants/UseScreenType";
import TransactionsStyles from "../TransactionsStyles";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import { baseStyles } from "utils/constants/baseStyles";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import CreateUserIcon from "components/Icons/UserCreateIcon";
import ExportIcon from "components/Icons/ExportIcon";
import { addDays } from "date-fns";
import { formatToCalendarDate, getStartAndEndOfYear } from "utils/utils";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  ArrowRight,
  ChevronRight,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { FaArrowRight } from "react-icons/fa";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { CustomInput } from "components/InputField/CustomInput";
import { ITransactionFilter } from "utils/interfaces";
import { CustomDateRangePicker } from "components/DateRangeInput/DateRangeInput";
import { usePDF } from "react-to-pdf";
import { useTranslation } from "react-i18next";
export const defaultTransactionFilter: ITransactionFilter = {
  endDate: new Date(),
  startDate: getStartAndEndOfYear().start,
  keyword: "",
  transfertType: "MOBILE_MONEY",
};

interface IProps {
  handleChangeFilter?: Function;
  withExport?: boolean;
  onExport?: Function;
}
const HeaderContent: FC<IProps> = (props: IProps) => {
  const CalendarBtn: any = Button;
  const { t } = useTranslation();
  const classes = TransactionsStyles();
  const { isTab } = useScreenType();
  const [recherche, setRecherche] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);

  const modes = [
    {
      value: "BANK",
      label: t('mode.bank'),
    },
    {
      value: "MOBILE_MONEY",
      label: t('mode.mobile'),
    },
  ];
  const [filter, setFilter] = useState<ITransactionFilter>(
    defaultTransactionFilter
  );
  const changeRecherche = (e: any) => {
    setFilter((old) => ({ ...old, keyword: e }));
  };

  useEffect(() => {
    props?.handleChangeFilter?.(filter);
    console.log("Filter change", filter);
    setShowDatePicker(false);
  }, [filter]);

  return (
    <div
      className={isTab ? classes.contentOneMobile : classes.contentOne}
      style={{ padding: 20 }}
    >
      <div style={{ width: isTab ? "91vw" : "40%" }}>
        <NeomorphInputWithIcon
          placeholder={t('input.search')}
          onChange={changeRecherche}
          value={filter.keyword}
          withoutPlaceHolder={true}
        />
      </div>
      <Spacer />
      <div style={{ display: "flex", width: isTab ? "91vw" : "60%" }}>
        <div style={{ width: isTab ? "50%" : "50%" }}>
          <FakeSelect
            onchange={(item) => {
              setFilter((old) => ({ ...old, transfertType: item }));
            }}
            value={filter.transfertType}
            items={modes}
            placeholder="Mode de transfert"
          />
        </div>
        <Spacer />
        <Box sx={{ display: "flex", gap: "20px" }}>
          <CalendarBtn
            onClick={() => setShowDatePicker(!showDatePicker)}
            className={classes.btnCalendar}
          >
            <CalendarIcon />{" "}
            <p
              style={{
                fontSize: "10px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              {`${formatToCalendarDate(
                filter.startDate
              )} - ${formatToCalendarDate(filter.endDate)}`}
            </p>
          </CalendarBtn>
          {
            <NeuromorphBtn
              endIcon={<ExportIcon />}
              label="Export"
              onClick={() => {
                props?.onExport?.();
              }}
              width={isTab ? "50%" : 140}
            />
          }
        </Box>
        <CustomDateRangePicker
          visible={showDatePicker}
          toggle={() => setShowDatePicker(false)}
          onchange={(range) => {
            setFilter((old) => ({
              ...old,
              startDate: range.start,
              endDate: range.end,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default HeaderContent;
