import {
  Css,
  Phone,
  RemoveRedEye,
  Search,
  Visibility,
  VisibilityOff,
  VisibilityOffTwoTone,
} from "@mui/icons-material";
import { ButtonBase, TextField } from "@mui/material";
import { styled } from "@mui/styles";
import Spacer from "components/Spacer/Spacer";
import { FC, ReactElement, useState } from "react";
import { Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import "./input.css";
import "react-phone-number-input/style.css";
import PhoneInput, { Value } from "react-phone-number-input";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
      // background: baseStyles.color.error.bg,
      display: "flex",
      flex: 1,
      height: 50,
      marginLeft: 30,
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },
});

interface IProps {
  label?: string;
  placeholder?: string;
  onChange: Function;
  width?: number;
  height?: number;
  isPassword?: boolean;
  isEmail?: boolean;
  withoutPlaceHolder?: boolean;
  withoutIcon?: boolean;
  icon?: ReactElement;
  value?: string;
  valid?: boolean;
  validatorMessage?: string;
  disabled?: boolean;
  unite?: string;
}

const NeomorphPhoneNumberInput: FC<IProps> = ({
  label,
  placeholder,
  onChange,
  icon,
  withoutPlaceHolder,
  withoutIcon,
  validatorMessage,
  value,
  width,
  disabled,
  valid = true,
  unite,
  isPassword,
}) => {
  const Content: any = Card;
  const [show, setShow] = useState(false);

  return (
    <div>
      {label && (
        <>
          <p
            style={{
              color: baseStyles.text.default,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {label} :{" "}
          </p>

          <Spacer height={10} />
        </>
      )}

      <div style={{ position: "relative", display: "flex", flex: 1 }}>
        <Content
          inset
          style={{
            flex: 1,
            height: 50,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            backgroundColor: valid
              ? baseStyles.color.grey
              : baseStyles.color.error.bg,
            alignItems: "center",
            cursor: "pointer",
            width: width ?? undefined,
          }}
        >
          <PhoneInput
            placeholder="Enter phone number"
            // inputComponent={() => <CssTextField />}
            value={value ? (value as Value) : undefined}
            defaultCountry="FR"
            style={{ borderWidth: 0 }}
            international
            onChange={(data) => {
              onChange?.(data);
            }}
            internationalIcon={() => (
              <Phone
                style={{ marginTop: -5 }}
                htmlColor={baseStyles.text.default}
              />
            )}
          />
        </Content>
      </div>
      {!valid && (
        <p
          style={{
            color: baseStyles.color.error.color,
            marginTop: 10,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {validatorMessage}
        </p>
      )}
    </div>
  );
};

export default NeomorphPhoneNumberInput;
