import { FC, useContext, useEffect, useState } from "react";
import UserItems from "./UserItems/UserItems";
import UserInputFilters from "./UserInputFilters/UserInputFilters";
import Spacer from "components/Spacer/Spacer";
import Pagination from "../../components/Pagination/Pagination";
import { CircularProgress, Drawer, Grid, Typography } from "@mui/material";
import { baseStyles } from "utils/constants/baseStyles";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import UserForm from "./UserForm/UserForm";
import useUsersHooks from "hooks/useUsersHooks";
import { IUserItem } from "utils/interfaces";
import { UserContext } from "providers/UserProvider";
import { useTranslation } from "react-i18next";

interface IProps {}

const Users: FC<IProps> = (props: IProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<IUserItem | undefined>(
    undefined
  );
  const {
    loading,
    currentPage,
    performGetUserList,
    totalPage,
    setCurrentPage,
  } = useUsersHooks({});
  const { filteredUsers } = useContext(UserContext);
  useEffect(() => {
    if (selectedUser) {
      setOpenModal(true);
      console.log(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    performGetUserList();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 20,
      }}
    >
      <section>
        <UserInputFilters
          onClickCreateBtn={() => {
            setSelectedUser(undefined);
            setOpenModal(true);
          }}
        />
      </section>

      <Spacer />

      <section>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            <CircularProgress
              style={{ color: baseStyles.color.blue.primary }}
              disableShrink
            />
          </div>
        ) : (
          <UserItems
            users={filteredUsers}
            onClickDetails={(user: any) => {
              setSelectedUser(user);
            }}
          />
        )}
      </section>
      <section style={{ position: "relative", top: 20 }}>
        {totalPage > 1 && (
          <Pagination
            onChange={(current: number) => {
              setCurrentPage(current);
              performGetUserList(current);
            }}
            current={currentPage}
            max={totalPage}
          />
        )}
      </section>
      <Drawer
        anchor={"right"}
        open={openModal}
        onClose={() => {
          setSelectedUser(undefined);
          setOpenModal(false);
        }}
        variant={loading ? "persistent" : "temporary"}
      >
        <Grid
          style={{
            width: 350,
            background: baseStyles.color.grey,
            paddingBottom: 40,
            padding: 25,
          }}
        >
          {/* header */}
          <Grid
            style={{ display: "flex" }}
            flexDirection="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography fontSize={18} fontWeight={600}>
              {selectedUser ? t('user.account') : t('user.createOne')}
            </Typography>
            <CustomIconButton
              disabled={loading}
              icon={<CloseIcon />}
              onClick={() => {
                setSelectedUser(undefined);
                setOpenModal(false);
              }}
            ></CustomIconButton>
          </Grid>
          <UserForm
            toggleModal={() => {
              setOpenModal(false);
              setSelectedUser(undefined);
            }}
            user={selectedUser}
          />
        </Grid>
      </Drawer>
    </div>
  );
};

export default Users;
