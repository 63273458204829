import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_2169_3966"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="32"
      height="32"
    >
      <circle cx="16" cy="16" r="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2169_3966)">
      <rect width="32" height="32" fill="#004692" />
      <path
        d="M19.017 14.6686L18.0742 15.6115L29.3879 26.9252L30.3307 25.9824L19.017 14.6686Z"
        fill="#F5F7F8"
      />
      <path
        d="M16.5029 17.1828L14.9315 18.7541L26.2452 30.0679L27.8166 28.4965L16.5029 17.1828Z"
        fill="#F5F7F8"
      />
      <path
        d="M16.5029 17.1828L18.0742 15.6115L29.3879 26.9252L27.8166 28.4965L16.5029 17.1828Z"
        fill="#DD2033"
      />
      <path
        d="M16.9975 18.7523L16.0547 17.8094L4.74095 29.1232L5.68375 30.066L16.9975 18.7523Z"
        fill="#F5F7F8"
      />
      <path
        d="M14.4833 16.2381L12.912 14.6668L1.59825 25.9805L3.1696 27.5518L14.4833 16.2381Z"
        fill="#F5F7F8"
      />
      <path
        d="M14.4833 16.2381L16.0547 17.8094L4.74095 29.1232L3.1696 27.5518L14.4833 16.2381Z"
        fill="#DD2033"
      />
      <path
        d="M13.3582 18.066L14.301 17.1232L2.98732 5.80946L2.04451 6.75227L13.3582 18.066Z"
        fill="#F5F7F8"
      />
      <path
        d="M15.8724 15.5518L17.4437 13.9805L6.13002 2.66676L4.55867 4.23811L15.8724 15.5518Z"
        fill="#F5F7F8"
      />
      <path
        d="M15.8724 15.5518L14.301 17.1232L2.98732 5.80946L4.55867 4.23811L15.8724 15.5518Z"
        fill="#DD2033"
      />
      <path
        d="M14.489 13.9821L15.4319 14.9249L26.7456 3.61122L25.8028 2.66841L14.489 13.9821Z"
        fill="#F5F7F8"
      />
      <path
        d="M17.0032 16.4963L18.5746 18.0676L29.8883 6.75392L28.3169 5.18257L17.0032 16.4963Z"
        fill="#F5F7F8"
      />
      <path
        d="M17.0032 16.4963L15.4319 14.9249L26.7456 3.61122L28.3169 5.18257L17.0032 16.4963Z"
        fill="#DD2033"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3333 32H11.1111V20.8889H0V18.6667H11.1111L11.1111 13.3333L0 13.3333V11.1111L11.1111 11.1111L11.1111 0H13.3333L13.3333 11.1111H18.6667L18.6667 0H20.8889L20.8889 11.1111L32 11.1111V13.3333H20.8889V18.6667H32V20.8889L20.8889 20.8889L20.8889 32H18.6667L18.6667 20.8889H13.3333L13.3333 32ZM18.6667 13.3333H13.3333L13.3333 18.6667H18.6667V13.3333Z"
        fill="#F5F7F8"
      />
      <path
        d="M13.3333 32H18.6667L18.6667 18.6667H32V13.3333H18.6667L18.6667 0H13.3333L13.3333 13.3333L0 13.3333V18.6667H13.3333L13.3333 32Z"
        fill="#DD2033"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as EngIcon };
