import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomAuthenticationWrapper from "components/CustomAuthenticationWrapper/CustomAuthenticationWrapper";
import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import Spacer from "components/Spacer/Spacer";
import loginLogo from "assets/images/login-img.png";
import ResetPasswordMobile from "./ResetPasswordMobile";
import { useScreenType } from "utils/constants/UseScreenType";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { PasswordIcon } from "components/Icons/PasswordIcon";
import { useForgotPassword } from "hooks/useForgotPassword";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const { isTab } = useScreenType();
  const location = useLocation();
  const codeVerification = location?.state?.codeVerification;
  const { ResetPassword, sendingForgotCode } = useForgotPassword();

  const [password, setPassword] = useState({
    newPasswrd: "",
    confirm: "",
  });

  const onNewPasswordChanged = (e: any) => {
    setPassword((prev) => ({ ...prev, newPasswrd: e }));
  };

  const onConfirmPasswordChanged = (e: any) =>
    setPassword((prev) => ({ ...prev, confirm: e }));

  const handleValidate = () =>
    ResetPassword({
      newPassword: password.newPasswrd,
      code: codeVerification ?? "",
    });

  const disableBtn =
    password.newPasswrd?.trim()?.length < 6 ||
    password.confirm?.trim()?.length < 6 ||
    password.newPasswrd?.trim()?.toLowerCase() !==
      password.confirm?.trim()?.toLowerCase();

  return !isTab ? (
    <CustomAuthenticationWrapper>
      <CustomAuthContent
        loading={disableBtn || sendingForgotCode}
        logo={loginLogo}
        title={t('resetPassword.modifyPassword')}
        onBtnClick={handleValidate}
      >
        <div>
          <NeomorphInputWithIcon
            width={400}
            label={t('resetPassword.createNewPassword')}
            placeholder={t('resetPassword.createNewPassPlaceholder')}
            icon={<PasswordIcon style={{ marginTop: 2 }} />}
            onChange={onNewPasswordChanged}
            value={password.newPasswrd}
          />

          <Spacer height={10} />

          <NeomorphInputWithIcon
            width={400}
            label={t('resetPassword.confirm')}
            placeholder={t('resetPassword.confirm')}
            icon={<PasswordIcon style={{ marginTop: 2 }} />}
            onChange={onConfirmPasswordChanged}
            value={password.confirm}
          />

          <Spacer height={10} />
        </div>
      </CustomAuthContent>
    </CustomAuthenticationWrapper>
  ) : (
    <ResetPasswordMobile />
  );
};
