import React from "react";
import { makeStyles } from "@mui/styles";

const TransactionsStyles = makeStyles(() => {
  return {
    contentOne: {
      width: "100%",
      height: "50px",
      display: "flex",
      alignItems: "center",
    },
    contentOneMobile: {
      width: "100vw",
      height: "auto",
      display: "flex",
      flexDirection: "column",
    },
    btnCalendar: {
      width: "250px",
      height: "50px",
      borderRadius: "10px",
      padding: "15px",
    },
  };
});

export default TransactionsStyles;
