import { Dispatch, FC, SetStateAction, createContext, useState } from "react";

type Props = {
  children: any;
};

type OperatorContextType = {
  updateOperatorList: boolean;
  setUpdateOperatorList: Dispatch<SetStateAction<boolean>>;
};

const defaultValue: OperatorContextType = {
  updateOperatorList: false,
  setUpdateOperatorList: () => {},
};

export const OperatorContext = createContext<OperatorContextType>(defaultValue);

const OperatorProvider: FC<Props> = ({ children }) => {
  const [updateOperatorList, setUpdateOperatorList] = useState<boolean>(false);

  return (
    <OperatorContext.Provider
      value={{ updateOperatorList, setUpdateOperatorList }}
    >
      {children}
    </OperatorContext.Provider>
  );
};

export default OperatorProvider;
