import { UserRole } from "utils/interfaces";

const roles: UserRole[] = [
  {
    id: 1,
    label: "Admin",
    value: "ADMIN",
  },
  {
    id: 2,
    label: "User",
    value: "USER",
  },
];

export const getStatus = (t: any) => [
  { label: t('input.active'), value: 1 },
  { label: t('input.noActive'), value: 0 },
];

const countries = [
  {
    id: 1,
    name: "Ghana",
    code: "+261",
    phonePrefix: 632145,
  },
  {
    id: 2,
    name: "Cameroon",
    code: "+269",
    phonePrefix: 123456,
  },
];

export { roles, countries };
