import React, { FC } from "react";

interface Props{
 color: string
}

const WalletMobile:FC<Props> = (props) => {

  const { color } = props;

  return (
    <svg
      width="35"
      height="42"
      viewBox="0 0 35 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6074 0.166505H17.3925C13.892 0.166474 11.1193 0.166451 8.9494 0.463278C6.71622 0.768759 4.90869 1.4124 3.48324 2.86271C2.05779 4.31303 1.42518 6.15208 1.12494 8.42421C0.8332 10.632 0.833223 13.453 0.833253 17.0145V24.9851C0.833223 28.5467 0.8332 31.3677 1.12494 33.5755C1.42518 35.8476 2.05779 37.6866 3.48324 39.137C4.90869 40.5873 6.71622 41.2309 8.9494 41.5364C11.1193 41.8332 13.8919 41.8332 17.3924 41.8332H17.6074C21.1078 41.8332 23.8805 41.8332 26.0504 41.5364C28.2836 41.2309 30.0911 40.5873 31.5166 39.137C32.9421 37.6866 33.5747 35.8476 33.8749 33.5755C34.1666 31.3677 34.1666 28.5467 34.1666 24.9852V17.0145C34.1666 13.453 34.1666 10.632 33.8749 8.42421C33.5747 6.15208 32.9421 4.31303 31.5166 2.86271C30.0911 1.4124 28.2836 0.768759 26.0504 0.463278C23.8805 0.166451 21.1079 0.166474 17.6074 0.166505ZM10.3571 34.5657C10.3571 33.763 10.9967 33.1122 11.7856 33.1122H23.2142C24.0032 33.1122 24.6428 33.763 24.6428 34.5657C24.6428 35.3685 24.0032 36.0192 23.2142 36.0192H11.7856C10.9967 36.0192 10.3571 35.3685 10.3571 34.5657Z"
        fill = {color}
      />
    </svg>
  );
};

export default WalletMobile;
