import React from 'react'
import countryStyle from './CountryStyle'
import CountryList from './CountryList/CountryList';
import CountryStat from './CountryStat/CountryStat';

const Country = () => {
  const classes = countryStyle();
  return (
    <div className={classes.content}>
        <div className={classes.contentList}>
            <CountryList/>
        </div>
        <div className={classes.contentStat}>
            <CountryStat/>
        </div>
    </div>
  )
}

export default Country