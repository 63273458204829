import {
  Avatar,
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";
import { FC, useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";
import { baseStyles } from "utils/constants/baseStyles";
import MenuPopover from "./MenuPopover/MenuPopover";
import { LogoutIcon } from "components/Icons/LogoutIcon";
import { SettingIcon } from "components/Icons/SettingIcon";
import { AuthContext } from "providers/AuthProvider";
import useAuthenticationHooks from "hooks/useAuthenticationHooks";
import { UserContext } from "providers/UserProvider";
import { currencySymbol, fullName, truncate } from "utils/utils";
import { useScreenType } from "utils/constants/UseScreenType";
import MenuMobileIcon from "components/Icons/MenuMobileIcon";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import CloseIcon from "components/Icons/CloseIcon";
import SideBarMenu from "pages/SideBarMenu/SideBarMenu";
import { MoneyIcon } from "components/Icons/MoneyIcon";
import { Money, Wallet } from "@mui/icons-material";
import { FaDollarSign } from "react-icons/fa";
import useCurrencyHooks from "hooks/useCurrencyHooks";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import Spacer from "components/Spacer/Spacer";
import { Currency } from "utils/interfaces";
import { useTranslation } from "react-i18next";

interface IProps {}

const itemsMenu = [
  {
    index: 0,
    name: "Commissions entrantes",
    pathName: ROUTES.commissions,
  },
  {
    index: 1,
    name: "Gérer les commissions",
    pathName: ROUTES.manageCommision,
  },
];

const AppHeader: FC<IProps> = ({}) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { getUserInfo } = useAuthenticationHooks();
  const { currentUser, setCurrentCurrency, currentCurrency } =
    useContext(UserContext);
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const { isTab } = useScreenType();
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const { loading, fetchCurrencies, currencies } = useCurrencyHooks();
  useEffect(() => {
    if (currentUser?.profilePicture) {
      setProfilePicture(
        `${process.env.REACT_APP_API_URL}/${currentUser?.profilePicture?.path}`
      );
    }
  }, [currentUser]);

  const { logout } = useContext(AuthContext);
  const performLogout = () => {
    logout();
  };

  function HeaderTitle() {
    switch (pathname) {
      case ROUTES.users:
        return t('menu.users');

      case ROUTES.transactions:
        return t('menu.transactions');

      case ROUTES.journal:
        return t('menu.journal');

      case ROUTES.commissions:
        return t('menu.commissions');

      case ROUTES.operators:
        return t('menu.operators');
      case ROUTES.myAccount:
        return t('menu.myAccount');

      case ROUTES.country:
        return t('menu.countryManagement');

      case ROUTES.customers:
        return t('menu.customers-management');

      default:
        return "Dashboard";
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<number | null>(0);
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [filteredCurrencies, setFilteredCurrencies] = useState<Currency[]>([]);
  const handleItemClick = (item: any) => {
    setActiveItem(item.index);
    navigate(item.pathName);
  };

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  useEffect(() => {
    getUserInfo();
    fetchCurrencies();
  }, []);
  useEffect(() => {
    setFilteredCurrencies(currencies);
  }, [currencies]);

  useEffect(() => {
    if (key.length > 0) {
      const f = currencies.filter(
        (item) =>
          item.name?.toLowerCase().includes(key.toLowerCase()) ||
          item?.code?.toLowerCase().includes(key.toLowerCase())
      );
      setFilteredCurrencies(f);
    } else {
      setFilteredCurrencies(currencies);
    }
  }, [key]);
  useEffect(() => {
    pathname === "/manage" ? setActiveItem(1) : setActiveItem(0);
  }, [pathname]);

  const connectedUserName = fullName(
    currentUser?.firstname,
    currentUser?.lastname
  );
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const commission = pathname === "/commissions" || pathname === "/manage";

  return (
    <header
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingInline: 20,
      }}
    >
      {pathname === "/commissions" || pathname === "/manage" ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          {itemsMenu.map((item) => (
            <div
              key={item.index}
              onClick={() => handleItemClick(item)}
              style={{
                borderBottom: activeItem === item.index ? "2px solid" : "none",
                borderBottomColor:
                  activeItem === item.index
                    ? baseStyles.color.blue.primary
                    : "none",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: "30px",
              }}
            >
              <p
                style={{
                  color:
                    activeItem === item.index
                      ? baseStyles.color.blue.primary
                      : baseStyles.text.default,
                  fontSize: 14,
                  fontWeight: 700,
                }}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <h2
          style={{
            fontSize: 32,
            fontWeight: 700,
            color: baseStyles.text.black,
          }}
        >
          {HeaderTitle()}
        </h2>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: commission ? "20px" : "none",
          }}
          onClick={togglePopover}
        >
          <Avatar src={profilePicture} />
          <span
            style={{
              marginInline: 10,
              fontSize: 12,
              fontWeight: 500,
              color: baseStyles.text.black,
            }}
          >
            {connectedUserName ?? ""}
          </span>

          <span>
            <ArrowDownIcon />
          </span>
        </div>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={toggleMenu}
        >
          {isTab && (
            <span>
              <MenuMobileIcon color={baseStyles.text.black} />
            </span>
          )}
        </div>
      </div>

      <MenuPopover open={open} anchorEl={anchorEl} handleClose={togglePopover}>
        <div
          style={{
            paddingTop: 20,
            paddingBottom: 20,
            width: 180,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "30px"
            }}
          >
            <SettingIcon />
            <NavLink to={ROUTES.myAccount}>
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: baseStyles.text.black,
                  marginLeft: 10,
                }}
                onClick={togglePopover}
              >
                {t('menu.myAccount')}
              </p>
            </NavLink>
          </div>

          <Divider
            style={{ backgroundColor: baseStyles.color.grey, margin: "10px 0" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "30px"
            }}
            onClick={performLogout}
          >
            <LogoutIcon />
            <p
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: baseStyles.text.black,
                marginLeft: 10,
              }}
            >
              {t('login.logout')}
            </p>
          </div>

          <Divider
            style={{ backgroundColor: baseStyles.color.grey, margin: "10px 0" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              marginLeft: "30px",
              flex: 1,
              width: 90,
            }}
            onClick={() => {
              setFilteredCurrencies(currencies);
              setShowCurrencyModal(true);
            }}
          >
            <FaDollarSign />
            <p
              style={{
                fontSize: 12,
                width: 120,
                fontWeight: 500,
                color: baseStyles.text.black,
                marginLeft: 7,
              }}
            >
              {`Devise (${
                currencySymbol[currentCurrency ?? ("EUR" as any)]?.symbol ??
                "None"
              })`}
            </p>
          </div>
        </div>
      </MenuPopover>

      {menuOpen && (
        <Slide direction="left" in={menuOpen} mountOnEnter unmountOnExit>
          <Box
            sx={{
              width: "300px",
              position: "fixed",
              bgcolor: baseStyles.color.grey,
              top: 0,
              bottom: 0,
              right: 0,
              height: "100%",
              zIndex: 999,
              padding: "20px 10px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: 24,
                  fontWeight: 700,
                  color: baseStyles.text.black,
                  marginLeft: 20,
                }}
              >
                Menus
              </p>
              <NeomorphContainer
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 5,
                  borderRadius: "50%",
                }}
                width={40}
                height={40}
              >
                <div onClick={toggleMenu}>
                  <CloseIcon />
                </div>
              </NeomorphContainer>
            </Box>
            <Box
              sx={{
                height: "80%",
                display: "flex",
                padding: "5px",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <div onClick={toggleMenu}>
                <SideBarMenu />
              </div>
            </Box>
          </Box>
        </Slide>
      )}
      <Modal
        style={{
          alignSelf: "center",
          justifySelf: "center",
        }}
        open={showCurrencyModal}
        onClose={() => {
          setShowCurrencyModal(false);
        }}
      >
        <Box
          sx={{
            width: 420,
            height: 400,
            borderRadius: 2,
            padding: 2,
            background: baseStyles.color.grey,
          }}
        >
          <h2>Séléctioner une devise</h2>
          <Spacer />
          <NeomorphInputWithIcon
            placeholder="Recherche ..."
            onChange={(e: any) => {
              setKey(e);
            }}
            withoutPlaceHolder={true}
          />
          <Spacer />
          <div
            style={{
              height: 240,
              display: "flex",
              flexDirection: "column",
              gap: 20,
              padding: 5,
              paddingTop: 20,
              overflowY: "auto",
            }}
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  marginTop: 50,
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              filteredCurrencies.map((item) => (
                <NeomorphButtonBase
                  key={item.name + item.id}
                  onClick={() => {
                    setCurrentCurrency(item.code);
                    setShowCurrencyModal(false);
                  }}
                  style={{ height: 45 }}
                  background={
                    item.code === currentCurrency
                      ? baseStyles.color.blue.primary
                      : baseStyles.color.grey
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      flex: 1,
                      width: 350,
                      height: 45,
                      padding: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ textTransform: "none" }}>
                      {truncate(item.name, 25)}
                    </Typography>
                    <Typography style={{ textTransform: "none" }}>
                      {`(${item.code}) ${
                        currencySymbol[item?.code ?? ("EUR" as any)]
                          ?.symbol_native ?? ""
                      }`}
                    </Typography>
                  </div>
                </NeomorphButtonBase>
              ))
            )}
          </div>
        </Box>
      </Modal>
    </header>
  );
};

export default AppHeader;
