import CustomAuthContent from "components/CustomAuthenticationWrapper/CustomAuthContent/CustomAuthContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spacer } from "ui-neumorphism";
import forgotLogo from "assets/images/forgot-pwd-img.png";
import { useScreenType } from "utils/constants/UseScreenType";
import CustomAuthenticationWrapper from "components/CustomAuthenticationWrapper/CustomAuthenticationWrapper";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { EmailIcon } from "components/Icons/EmailIcon";
import { isValidEmail } from "utils/utils";
import { useForgotPassword } from "hooks/useForgotPassword";

const ForgetPasswordEmail = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();
  const [email, setEmail] = useState("");
  const onEmailChanged = (value: string) => {
    setEmail(value);
  };

  const { SendConfirmationCodeToEmail, sendingForgotCode } =
    useForgotPassword();

  const handleValidate = () => {
    SendConfirmationCodeToEmail(email);
  };

  const disableBtn = !isValidEmail(email);

  return (
    <CustomAuthenticationWrapper>
      <CustomAuthContent
        loading={disableBtn || sendingForgotCode}
        logo={forgotLogo}
        title="Mot de passe oublié"
        subtitle="Pour récupérer votre mot de passe, veuillez renseigner votre adresse e-mail"
        onBtnClick={handleValidate}
      >
        <>
          <Spacer />

          <div style={{ width: isMobile ? "90vw" : 400, marginTop: "20px" }}>
            <NeomorphInputWithIcon
              width={400}
              label="Adresse e-mail"
              placeholder="Votre adresse e-mail ..."
              icon={<EmailIcon style={{ marginTop: 5 }} />}
              onChange={onEmailChanged}
              value={email}
            />
          </div>

          <Spacer />
        </>
      </CustomAuthContent>
    </CustomAuthenticationWrapper>
  );
};

export default ForgetPasswordEmail;
