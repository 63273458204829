import axios from "services/AxiosInstance";
import { PostPutOperatorPayload } from "utils/interfaces";

const GetOperators = (providerCode?: string) => {
  let url = `/api/operators`;
  if (providerCode && providerCode?.length > 0) {
    url = `${url}?providerCode=${providerCode}`;
  }
  return axios.get(url);
};

const GetOperatorsProviders = () => {
  return axios.get("/api/operators/providers");
};

const PostOperator = (payload: PostPutOperatorPayload) => {
  return axios.post("/api/operators/", { ...payload });
};

const PutOperator = ({
  operatorId,
  payload,
}: {
  operatorId: number;
  payload: PostPutOperatorPayload;
}) => {
  return axios.put(`/api/operators/${operatorId}`, { ...payload });
};

const DeleteOperator = ({ operatorId }: { operatorId: number }) => {
  return axios.delete(`/api/operators/${operatorId}`);
};

export const OperatorService = {
  GetOperatorsProviders,
  GetOperators,
  PostOperator,
  PutOperator,
  DeleteOperator,
};
