import { FC } from "react";
import { useScreenType } from "utils/constants/UseScreenType";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {}

const BottomContentItemTitle: FC<IProps> = () => {
  const { isTab } = useScreenType();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <p
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: baseStyles.text.default,
          marginLeft: -50,
        }}
      >
        Date
      </p>

      <p
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: baseStyles.text.default,
        }}
      >
        Expéditeur
      </p>

      <p
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: baseStyles.text.default,
        }}
      >
        Montant envoyé
      </p>

      {!isTab && (
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: baseStyles.text.default,
          }}
        >
          Commission
        </p>
      )}

      <p
        style={{
          fontWeight: 500,
          fontSize: 12,
          color: baseStyles.text.default,
        }}
      >
        Destinataire
      </p>

      {!isTab && (
        <>
          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.default,
            }}
          >
            Référence
          </p>
          <p
            style={{
              fontWeight: 500,
              fontSize: 12,
              color: baseStyles.text.default,
            }}
          >
            Montant de transfert
          </p>
        </>
      )}
    </div>
  );
};

export default BottomContentItemTitle;
