import {
  DashboardJournalItem,
  TransactionItem,
  JournalItem,
  UserItem,
  IOperatorItem,
} from "./interfaces";
import orange from "assets/images/Orange.png";
import mtn from "assets/images/mtn.png";
import Moov from "assets/images/Moov.png";
import wave from "assets/images/wave.png";
import Ghana from "assets/images/Ghana.png";
import Benin from "assets/images/Benin.png";
import CoteIvoire from "assets/images/CoteIvoire.png";

const journales: DashboardJournalItem[] = [
  {
    id: 1,
    name: "Katarina Hermansson",
    description: "Modification mot de passe",
    date: "23 déc 2023",
    time: "15:30",
  },
  {
    id: 2,
    name: "Adam Berggren",
    description: "Création de compte",
    date: "23 déc 2023",
    time: "15:30",
  },
  {
    id: 3,
    name: "Julia Eklund",
    description: "Création de bénéficiaire ",
    date: "23 déc 2023",
    time: "15:30",
  },
  {
    id: 4,
    name: "Stefan Eliasson",
    description: "Modification mot de passe",
    date: "23 déc 2023",
    time: "15:30",
  },
  {
    id: 5,
    name: "Anders Björk",
    description: "Création de compte",
    date: "23 déc 2023",
    time: "15:30",
  },
  {
    id: 6,
    name: "Birgit Eklund",
    description: "Création de bénéficiaire ",
    date: "23 déc 2023",
    time: "15:30",
  },
];

// const journalesData: JournalItem[] = [
//   {
//     id: 1,
//     name: "Katarina Hermansson",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Modification mot de passe",
//     date: "il y a une minute",
//     status: 1,
//   },
//   {
//     id: 2,
//     name: "Adam Berggren",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Création de compte",
//     date: "il y a une minute",
//     status: 1,
//   },
//   {
//     id: 3,
//     name: "Julia Eklund",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Création de bénéficiaire ",
//     date: "il y a une minute",
//     status: 1,
//   },
//   {
//     id: 4,
//     name: "Stefan Eliasson",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Modification mot de passe",
//     date: "il y a une minute",
//     status: 0,
//   },
//   {
//     id: 5,
//     name: "Anders Björk",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Création de compte",
//     date: "il y a une minute",
//     status: 1,
//   },
//   {
//     id: 6,
//     name: "Birgit Eklund",
//     profilePicture: "",
//     pays:"Benin",
//     activity: "Création de bénéficiaire ",
//     date: "il y a une minute",
//     status: 1,
//   },
// ];

const lastTransactions: TransactionItem[] = [
  {
    id: 1,
    expeditor: "Christian Abrahamsson",
    montant: "50",
    referance: "12154545",
    destinator: "Linda Jansson",
    transfertMode: "MOMO->MOMO",
    status: "En attente",
    date: "23 déc 2023, 15:30",
  },
  {
    id: 2,
    expeditor: "Ali Lindgren",
    montant: "50",
    referance: "23 déc 2023",
    destinator: "Johanna Lindqvist",
    transfertMode: "MOMO->MOMO",
    status: "En attente",
    date: "23 déc 2023, 15:30",
  },
  {
    id: 3,
    expeditor: "Per Falk",
    montant: "50",
    referance: "23 déc 2023",
    destinator: "Alexander Ekström",
    transfertMode: "MOMO->MOMO",
    status: "En attente",
    date: "23 déc 2023, 15:30",
  },
];

const users: UserItem[] = [
  {
    id: 1,
    name: "Katarina Hermansson",
    role: "Admin",
    email: "admin@email.com",
    isActivated: true,
  },
  {
    id: 2,
    name: "Alexander Ekström",
    role: "Utilisateur",
    email: "user@email.com",
    isActivated: true,
  },
  {
    id: 3,
    name: "Linda Jansson",
    role: "Admin",
    email: "admin@email.com",
    isActivated: false,
  },
  {
    id: 4,
    name: "Per Falk",
    role: "Utilisateur",
    email: "user@email.com",
    isActivated: false,
  },
  {
    id: 5,
    name: "Per Falk",
    role: "Utilisateur",
    email: "user@email.com",
    isActivated: false,
  },
  {
    id: 6,
    name: "Per Falk",
    role: "Utilisateur",
    email: "user@email.com",
    isActivated: false,
  },
  {
    id: 7,
    name: "Per Falk",
    role: "Utilisateur",
    email: "user@email.com",
    isActivated: false,
  },
];

const operators: IOperatorItem[] = [
  {
    id: 1,
    country: "Benin",
    icon: orange,
    isActivated: true,
    flag: Benin,
    name: "Orange",
  },
  {
    id: 2,
    country: "Benin",
    flag: Benin,
    icon: mtn,
    isActivated: false,
    name: "MTN",
  },
  {
    id: 3,
    country: "Benin",
    flag: Benin,
    icon: wave,
    isActivated: true,
    name: "Wave",
  },
  {
    id: 4,
    country: "Benin",
    flag: Benin,
    icon: Moov,
    isActivated: false,
    name: "Moov",
  },

  {
    id: 5,
    country: "Cote d'Ivoire",
    flag: CoteIvoire,
    icon: orange,
    isActivated: false,
    name: "Orange",
  },
  {
    id: 6,
    country: "Cote d'Ivoire",
    flag: CoteIvoire,
    icon: mtn,
    isActivated: false,
    name: "MTN",
  },
  {
    id: 7,
    country: "Cote d'Ivoire",
    flag: CoteIvoire,
    icon: wave,
    isActivated: true,
    name: "Wave",
  },
  {
    id: 8,
    country: "Cote d'Ivoire",
    flag: CoteIvoire,
    icon: Moov,
    isActivated: false,
    name: "Moov",
  },

  {
    id: 9,
    country: "Ghana",
    flag: Ghana,
    icon: orange,
    isActivated: true,
    name: "Orange",
  },
  {
    id: 10,
    country: "Ghana",
    flag: Ghana,
    icon: mtn,
    isActivated: false,
    name: "MTN",
  },
  {
    id: 11,
    country: "Ghana",
    flag: Ghana,
    icon: wave,
    isActivated: false,
    name: "Wave",
  },
  {
    id: 12,
    country: "Ghana",
    flag: Ghana,
    icon: Moov,
    isActivated: false,
    name: "Moov",
  },
];

export {
  users,
  journales,
  lastTransactions,
  operators,
  Ghana,
  CoteIvoire,
  Benin,
  wave,
  orange,
  Moov,
};
