import { Typography } from "@mui/material";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import ArrowRightLineIcone from "components/Icons/ArrowRightLineIcone";
import { GoBackIcon } from "components/Icons/GoBackIcon";
import WalletBanq from "components/Icons/WalletBanq";
import WalletMobile from "components/Icons/WalletMobile";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import Spacer from "components/Spacer/Spacer";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { baseStyles } from "utils/constants/baseStyles";

interface Props {
  setPage: any;
}

const CreateCommission: FC<Props> = (props) => {
  const { setPage } = props;
  const navigation = useNavigate();
  const handleGoBack = () => setPage(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex" }}>
        <div onClick={handleGoBack}>
          <GoBackIcon />
        </div>
        <h2
          style={{
            fontSize: 18,
            fontWeight: 700,
            color: baseStyles.text.black,
          }}
        >
          Créer une commission
        </h2>
      </div>
      <div>
        <NeomorphContainer style={{ height: 196, padding: "20px" }}>
          <div style={{ width: "100%" }}>
            <h2
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: baseStyles.text.black,
                textTransform: "uppercase",
              }}
            >
              Mode de transfert :
            </h2>
            <Spacer />
            <div style={{ display: "flex" }}>
              <NeomorphContainer style={{ padding: "20px", height: 115 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <WalletMobile color={baseStyles.color.warning.icon} />
                    <ArrowRightLineIcone />
                    <WalletMobile color={baseStyles.color.blue.primary} />
                  </div>
                  <Spacer />
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: baseStyles.text.default,
                      }}
                    >
                      Mobile money vers mobile money
                    </p>
                  </div>
                </div>
              </NeomorphContainer>
              <Spacer />
              <NeomorphContainer style={{ padding: "20px", height: 115 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <WalletBanq />
                    <ArrowRightLineIcone />
                    <WalletMobile color={baseStyles.color.blue.primary} />
                  </div>
                  <Spacer />
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: baseStyles.text.default,
                      }}
                    >
                      Carte bancaire vers mobile money
                    </p>
                  </div>
                </div>
              </NeomorphContainer>
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto", padding: "20px" }}>
          <div>
            <h2
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: baseStyles.text.black,
                textTransform: "uppercase",
              }}
            >
              Expéditeur
            </h2>
            <Spacer />
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <FakeSelect label="Pays" placeholder="Sélectionner un pays" />
              </div>
              <Spacer />
              <div style={{ width: "50%" }}>
                <FakeSelect
                  label="Opérateur mobile"
                  placeholder="Sélectionner un opérateur mobile"
                />
              </div>
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto", padding: "20px" }}>
          <div>
            <h2
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: baseStyles.text.black,
                textTransform: "uppercase",
              }}
            >
              destinataire
            </h2>
            <Spacer />
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <FakeSelect label="Pays" placeholder="Sélectionner un pays" />
              </div>
              <Spacer />
              <div style={{ width: "50%" }}>
                <FakeSelect
                  label="Opérateur mobile"
                  placeholder="Sélectionner un opérateur mobile"
                />
              </div>
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto", padding: "20px" }}>
          <div>
            <h2
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: baseStyles.text.black,
                textTransform: "uppercase",
              }}
            >
              Palier transaction
            </h2>
            <Spacer />
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <NeomorphInputWithIcon
                  withoutIcon={true}
                  placeholder="0,00"
                  label="Montant minimum"
                  onChange={() => ""}
                  withoutPlaceHolder={true}
                  unite="FCFA"
                />
              </div>
              <Spacer />
              <div style={{ width: "50%" }}>
                <NeomorphInputWithIcon
                  withoutIcon={true}
                  placeholder="0,00"
                  label="Montant maximum"
                  onChange={() => ""}
                  withoutPlaceHolder={true}
                  unite="FCFA"
                />
              </div>
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <NeomorphContainer style={{ height: "auto", padding: "20px" }}>
          <div>
            <h2
              style={{
                fontSize: 16,
                fontWeight: 700,
                color: baseStyles.text.black,
                textTransform: "uppercase",
              }}
            >
              Montant du commission
            </h2>
            <Spacer />
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <NeomorphInputWithIcon
                  withoutIcon={true}
                  placeholder="0,00"
                  onChange={() => ""}
                  withoutPlaceHolder={true}
                  label="Montant"
                  unite="FCFA"
                />
              </div>
            </div>
          </div>
        </NeomorphContainer>
      </div>
      <Spacer />
      <div>
        <div style={{right: 10, display: 'flex', justifyContent: 'flex-end' }}>
          <NeuromorphBtn
            label="Créer une commision"
            onClick={() => ""}
            width={194}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCommission;
