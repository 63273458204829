import React, { FC } from "react";

interface IProps {
  height?: number;
  width?: number;
}

const Spacer: FC<IProps> = ({ height, width }) => {
  return (
    <div style={{ height: height ?? 20, minHeight: 20, width: width ?? 20 }} />
  );
};

export default Spacer;
