import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={34}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="#fff"
      d="M33.667 17c0 9.205-7.462 16.667-16.667 16.667C7.795 33.667.333 26.205.333 17 .333 7.795 7.795.333 17 .333 26.205.333 33.667 7.795 33.667 17Z"
    />
    <path
      fill="#7383C0"
      fillRule="evenodd"
      d="M17 9.083c.69 0 1.25.56 1.25 1.25v6.15l3.8 3.8a1.25 1.25 0 0 1-1.767 1.767l-4.167-4.166A1.25 1.25 0 0 1 15.75 17v-6.667c0-.69.56-1.25 1.25-1.25Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as ClockCircleIcon };
