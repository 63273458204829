import React from "react";

interface MenuMobileProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const MenuMobileIcon: React.FC<MenuMobileProps> = ({ width = "23", height = "18", color = "none", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0  0  23  18"
      fill={color ? color : "#131A21"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* The rest of your SVG paths and shapes */}
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0  1.5C0  1.16848  0.131696  0.850537  0.366116  0.616117C0.600537  0.381696  0.918479  0.25  1.25  0.25H21.25C21.5815  0.25  21.8995  0.381696  22.1339  0.616117C22.3683  0.850537  22.5  1.16848  22.5  1.5C22.5  1.83152  22.3683  2.14946  22.1339  2.38388C21.8995  2.6183  21.5815  2.75  21.25  2.75H1.25C0.918479  2.75  0.600537  2.6183  0.366116  2.38388C0.131696  2.14946  0  1.83152  0  1.5ZM0  9C0  8.66848  0.131696  8.35054  0.366116  8.11612C0.600537  7.8817  0.918479  7.75  1.25  7.75H13.75C14.0815  7.75  14.3995  7.8817  14.6339  8.11612C14.8683  8.35054  15  8.66848  15  9C15  9.33152  14.8683  9.64946  14.6339  9.88388C14.3995  10.1183  14.0815  10.25  13.75  10.25H1.25C0.918479  10.25  0.600537  10.1183  0.366116  9.88388C0.131696  9.64946  0  9.33152  0  9ZM0  16.5C0  16.1685  0.131696  15.8505  0.366116  15.6161C0.600537  15.3817  0.918479  15.25  1.25  15.25H7.5C7.83152  15.25  8.14946  15.3817  8.38388  15.6161C8.6183  15.8505  8.75  16.1685  8.75  16.5C8.75  16.8315  8.6183  17.1495  8.38388  17.3839C8.14946  17.6183  7.83152  17.75  7.5  17.75H1.25C0.918479  17.75  0.600537  17.6183  0.366116  17.3839C0.131696  17.1495  0  16.8315  0  16.5Z"
        fill={color ? color : "#131A21"}
      />
    </svg>
  );
};

export default MenuMobileIcon;
