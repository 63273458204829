import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import BottomContentItems from "./BottomContentItems/BottomContentItems";
import { useScreenType } from "utils/constants/UseScreenType";

interface IProps {}

const BottomContent: FC<IProps> = (props: IProps) => {
   const {isMobile, isTab} = useScreenType();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <BottomContentItems />
    </div>
  );
};

export default BottomContent;
