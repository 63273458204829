import axios from "services/AxiosInstance";

export const getHistory = (
  startDate: string,
  endDate: string,
  page: number,
  limit: number
) => {
  return axios.get(
    `/api/users/updateHistory?page=${page}&limit=${limit}`
    //&startDate=${startDate}&endDate=${endDate}
  );
};
