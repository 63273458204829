import { FC } from "react";
import { baseStyles } from "utils/constants/baseStyles";
import ActivityJournal from "./ActivityJournal/ActivityJournal";
import TodayContent from "./TodayContent/TodayContent";
import { useScreenType } from "utils/constants/UseScreenType";
import Spacer from "components/Spacer/Spacer";

interface IProps {}

const TopContent: FC<IProps> = (props: IProps) => {
  const { isMobile, isTab } = useScreenType();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: isTab ? "column" : "row",
        justifyContent: "space-between",
        height: isTab ? "auto" : 503,
      }}
    >
      <TodayContent />
      {isTab && <Spacer />}
      <ActivityJournal />
    </div>
  );
};

export default TopContent;
