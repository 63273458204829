import { baseStyles } from "utils/constants/baseStyles";

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  containerTab: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  children: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  details: { lineHeight: "18px" },
  title: {
    color: baseStyles.text.black,
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
  },
  description: {
    color: baseStyles.text.default,
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
    width: 400,
    lineHeight: "18px",
  },
  descriptionMobile: {
    color: baseStyles.text.default,
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
    lineHeight: "18px",
  },
  phoneNmuber: {
    color: baseStyles.text.black,
    fontSize: 12,
    fontWeight: 700,
    textAlign: "center",
    width: 400,
    lineHeight: "18px",
  },
  goBackMobile: {
    cursor: "pointer",
    position: "fixed",
    left:"0",
    top: "2%",
  }
};

export default styles;
