import logoDashboard from "assets/images/logo-dashboard.png";
import { FC } from "react";

interface IProps {
  height?: number;
  width?: number;
}

export const LogoDashboard: FC<IProps> = ({ height, width }) => {
  return (
    <img
      height={height ?? 409}
      width={width ?? 480}
      alt="logo-dashboard"
      src={logoDashboard}
    />
  );
};
