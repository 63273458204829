import { Dispatch, SetStateAction } from "react";
import { PostPutOperatorPayload } from "utils/interfaces";

const handleInputValueChange = ({
  value,
  keyValue,
  setPayload,
}: {
  value: string;
  keyValue: string;
  setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
}) => {
  setPayload((prev) => ({ ...prev, [keyValue]: value }));
};

const handleSetDefaultOperatorPayload = ({
  setPayload,
  operator,
}: {
  setPayload: Dispatch<SetStateAction<PostPutOperatorPayload>>;
  operator: any;
}) => {
  if (operator?.id > 0) {
    setPayload({
      name: operator?.name ?? "",
      countryCode: operator?.country?.code ?? "",
      active: Number(operator?.active) ?? 1,
      logo: "data:@file/jpeg;base64,/9j/4AAQSkZJRgABAQIALwAvAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAABAAEDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAUEAEAAAAAAAAAAAAAAAAAAAAA/8QAFAEBAAAAAAAAAAAAAAAAAAAAA//EABQRAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhEDEQA/AJKAUT//2Q==",
      phone: operator?.phone ?? "",
      email: operator?.email ?? "",
    });
  }
};

export { handleInputValueChange, handleSetDefaultOperatorPayload };
