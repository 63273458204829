import { useState } from "react";
import { AxiosError } from "axios";
import { CountryService } from "services/Country/Country.service";
import { CountryItem, IOperator, IOperatorItem, IZone } from "utils/interfaces";
import { toast } from "react-hot-toast";
import { OperatorService } from "services/Operators/OperatorService";
import { useTranslation } from "react-i18next";

const useCountryHooks = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [zones, setZones] = useState<IZone[]>([]);
  const [operatorProviders, setOperatorsProviders] = useState<IOperator[]>([]);

  const FetchCountries = async () => {
    setLoading(true);

    try {
      const response = await CountryService.GetCountries();
      if (response?.data) {
        setCountries(response?.data?.results);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      toast.error(t('alert.errorCountry'), {
        id: "Error",
      });
      setLoading(false);
    }
  };

  const FetchCountriesZones = async () => {
    setLoading(true);

    try {
      const response = await CountryService.GetCountriesZone();
      if (response?.data) {
        setZones(response?.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      toast.error(
        t('alert.errorCreateZone'),
        {
          id: "Error",
        }
      );
      setLoading(false);
    }
  };

  const FetchOperatorProviders = async () => {
    setLoading(true);

    try {
      const response = await OperatorService.GetOperatorsProviders();
      if (response?.data) {
        setOperatorsProviders(response?.data);
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      setLoading(false);
    }
  };

  const UpdateCountry = async (country: CountryItem, onDone?: Function) => {
    setLoading(true);

    try {
      const response = await CountryService.UpdateCountry(country);
      if (response?.data) {
        toast.success(
          country.supported
            ? t('alert.addCountry')
            : t('alert.deleteCountry')
        );
        onDone?.();
        await FetchCountries();
      }
      setLoading(false);
    } catch (e) {
      const error = e as AxiosError;
      const data = error.response?.data;
      console.log(data);
      toast.error(
        t('alert.errorModify'),
        { id: "Error" }
      );
      setLoading(false);
    }
  };

  return {
    FetchCountries,
    FetchCountriesZones,
    UpdateCountry,
    FetchOperatorProviders,
    operatorProviders,
    loading,
    countries,
    zones,
  };
};

export default useCountryHooks;
