import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { baseStyles } from "utils/constants/baseStyles";

export const LinearChart = (props) => {
  const { series, categories } = props;
  const [data, setData] = useState({
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
    ],
    options: {
      colors: [
        baseStyles.color.primaire,
        baseStyles.color.secondaire,
        baseStyles.color.quaternaire,
      ],
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-19T00:00:00.000Z",
          "2018-09-19T01:30:00.000Z",
          "2018-09-19T02:30:00.000Z",
          "2018-09-19T03:30:00.000Z",
          "2018-09-19T04:30:00.000Z",
          "2018-09-19T05:30:00.000Z",
          "2018-09-19T06:30:00.000Z",
        ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  useEffect(() => {
    if (props.series && props.categories) {
      setData((old) => ({
        ...old,
        series: series,
        options: {
          ...old.options,
          xaxis: {
            ...old.options.xaxis,
            categories: categories,
          },
        },
      }));
    }
  }, [props.series, props.categories]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="area"
          height={266}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default LinearChart;
