import { Box } from "@mui/material";
import { CalendarIcon } from "components/Icons/CalendarIcon";
import NeomorphInputSearch from "components/InputFieldSearch/NeomorphInputSearch";
import React, { FC, useEffect, useState } from "react";
import JournalStyles from "../JournalStyles";
import { Button } from "ui-neumorphism";
import { FakeSelect } from "components/FakeSelect/FakeSelect";
import Spacer from "components/Spacer/Spacer";
import NeomorphInputWithIcon from "components/InputFieldWithIcon/NeomorphInputWithIcon";
import { useScreenType } from "utils/constants/UseScreenType";
import { CustomDateRangePicker } from "components/DateRangeInput/DateRangeInput";
import { formatToCalendarDate, getStartAndEndOfYear } from "utils/utils";
import { IJournalFilter, PostPutOperatorPayload } from "utils/interfaces";
import OperatorCountryForm from "pages/Operators/OperatorForm/OperatorCountryForm";
import { defaultOperatorPayload } from "hooks/useOperatorsHooks";
import { useTranslation } from "react-i18next";
export const defaultJournalFilter: IJournalFilter = {
  endDate: new Date(),
  startDate: getStartAndEndOfYear().start,
  keyword: "",
  countryCode: "",
};

interface IProps {
  handleChangeFilter?: Function;
  withExport?: boolean;
}
const JournalHeader: FC<IProps> = (props: IProps) => {
  const CalendarBtn: any = Button;
  const { t } = useTranslation();
  const classes = JournalStyles();
  const { isTab } = useScreenType();
  const [recherche, setRecherche] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [filter, setFilter] = useState<IJournalFilter>(defaultJournalFilter);
  const [payload, setPayload] = useState<PostPutOperatorPayload>(
    defaultOperatorPayload
  );
  const changeRecherche = (e: any) => {
    setRecherche(e);
    setFilter((old: any) => ({ ...old, keyword: e }));
  };

  useEffect(() => {
    if (payload) {
      setFilter((old: any) => ({ ...old, countryCode: payload.countryCode }));
    }
  }, [payload]);

  useEffect(() => {
    props?.handleChangeFilter?.(filter);
  }, [filter]);

  return (
    <div className={isTab ? classes.contentOneMobile : classes.contentOne}>
      <div style={{ width: isTab ? "90%" : "30%" }}>
        <NeomorphInputWithIcon
          placeholder={t('input.search')}
          onChange={changeRecherche}
          withoutPlaceHolder={true}
        />
      </div>
      <Spacer />
      <div style={{ display: "flex", width: isTab ? "90%" : "70%" }}>
        <div style={{ width: isTab ? "50%" : "50%" }}>
          <OperatorCountryForm
            withoutLabel
            payload={payload}
            setPayload={setPayload}
          />
        </div>
        <Spacer />
        <Box>
          <CalendarBtn
            onClick={() => setShowDatePicker(!showDatePicker)}
            className={classes.btnCalendar}
          >
            <CalendarIcon />{" "}
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                marginLeft: "10px",
              }}
            >
              {`${formatToCalendarDate(
                filter.startDate
              )} - ${formatToCalendarDate(filter.endDate)}`}
            </p>
          </CalendarBtn>
        </Box>
        <CustomDateRangePicker
          visible={showDatePicker}
          toggle={() => setShowDatePicker(false)}
          onchange={(range) => {
            setFilter((old) => ({
              ...old,
              startDate: range.start,
              endDate: range.end,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default JournalHeader;
