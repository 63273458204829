import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserService } from "services/User/UserService";
import { baseStyles } from "utils/constants/baseStyles";

interface IProps {
  userId: number;
  isUserValidatedKyc?: null | boolean;
  askedAccountDeletion?: boolean;
  onClickDetails?: (userId: number) => void;
}

const UserStatus: FC<IProps> = ({
  userId,
  isUserValidatedKyc,
  askedAccountDeletion,
  onClickDetails
}) => {
  const { t } = useTranslation();
  const handleDetailsClick = () => {
    if (onClickDetails) {
      onClickDetails(userId);
    }
  };

  const statusData = useMemo(() => {
    if(askedAccountDeletion === true) {
      return {
        text: t('user.cancelation'),
        color: baseStyles.color.error.color,
        onClick: handleDetailsClick
      }
    }

    switch (isUserValidatedKyc) {
      case null:
        return {
          text: t('user.new'),
          color: baseStyles.color.primaire,
          onClick: handleDetailsClick
        }

      case true:
        return {
          text: t('user.active'),
          color: baseStyles.color.info.color,
          onClick: handleDetailsClick
        }

      case false:
        return {
          text: t('user.denied'),
          color: baseStyles.text.black,
          onClick: handleDetailsClick
        }

      default:
        return {
          text: t('user.cancelation'),
          color: baseStyles.color.error.color
        }
    }
  }, [isUserValidatedKyc, askedAccountDeletion, t, userId])

  return (
    <div style={{ width: "15%", display: "flex", cursor: "pointer" }} onClick={() => statusData?.onClick?.()}>
        <p
          style={{
            fontWeight: 500,
            fontSize: 12,
            color: statusData.color,
          }}
        >
          {statusData.text}
        </p>
      </div>
  );
};

export default UserStatus;

