import axios from "services/AxiosInstance";

const GetTransactions = (minDate: string, maxDate: string, page = 1) => {
  return axios.get(
    `/api/transactions?minDate=${minDate}&maxDate=${maxDate}&limit=12&page=${page}`
  );
};

const GetDashboardTransactions = (
  minDate: string,
  maxDate: string,
  currency: string = "EUR"
) => {
  return axios.get(
    `/api/transactions?minDate=${minDate}&maxDate=${maxDate}&conversionCurrencyCode=${currency}`
    // `/api/transactions`
  );
};

export const TransactionService = {
  GetTransactions,
  GetDashboardTransactions,
};
