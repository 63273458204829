import NeomorphContainer from "components/CustomNeomorph/NeomorphContainer";
import { lastTransactions } from "utils/fakedata";
import BottomContentItem from "./BottomContentItem";
import BottomContentItemTitle from "./BottomContentItemTitle";

const BottomContentItems = () => {
  return (
    <div
      style={{
        paddingTop: 20,

        paddingInline: 5,
      }}
    >
      <BottomContentItemTitle />

      <div
        style={
          {
            // overflow: "hidden",
            // overflowY: "scroll",
            // height: `calc(23vh + 2px)`,
          }
        }
      >
        {lastTransactions?.map((transaction) => (
          <NeomorphContainer key={transaction.id} style={{ marginBottom: 20 }}>
            <BottomContentItem transaction={transaction} />
          </NeomorphContainer>
        ))}
      </div>
    </div>
  );
};

export default BottomContentItems;
