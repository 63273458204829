import React, { useState } from 'react';
import { Button, Grid, Drawer, Typography } from '@mui/material';
import { IUserItem } from "utils/interfaces";
import useUsersHooks from "hooks/useUsersHooks";
import { baseStyles } from "utils/constants/baseStyles";
import CustomIconButton from "components/CustomIconButton/CustomIconButton";
import CloseIcon from "components/Icons/CloseIcon";
import NeuromorphBtn from "components/Button/NeuromorphBtn";
import NeomorphTextarea from 'components/TextareaField/NeomorphTextarea';
import { useTranslation } from "react-i18next";
import "./input.css";

interface RejectionDrawerProps {
  open: boolean;
  onClose: () => void;
  user?: IUserItem;
}

const KycRejectionDrawer: React.FC<RejectionDrawerProps> = ({ open, onClose, user }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const { RejectUserKyc } = useUsersHooks({});
  const [loading, setLoading] = useState(false);

  const handleKycRejection = () => {
    setLoading(true);
    const onSuccess = () => {
      setLoading(false);
      onClose();
    }
    RejectUserKyc(user?.id, reason, onSuccess);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        style={{
          width: 350,
          height: '100vh',
          backgroundColor: baseStyles.color.grey,
          paddingBottom: 40,
          padding: 25,
          display: 'flex',
        }}
      >
        {/* Header */}
        <Grid
          item
          style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}
        >
          <Typography fontSize={16} fontWeight={600}>
            {t('user.kycRejection')}
          </Typography>
          <CustomIconButton
            icon={<CloseIcon />}
            onClick={onClose}
          />
        </Grid>

        {/* Textarea for Reason */}
        <Grid item style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <NeomorphTextarea 
            onChange={(text: string) => {
              setReason(text);
            }}
            withoutIcon={true}
            label={t('user.kycRejectionReasonLabel')}
            placeholder={t('user.kycRejectionReasonPlaceholder')}
            height='auto'
          />
        </Grid>

        {/* Spacer to push button to the bottom */}
        <Grid item style={{ marginTop: 'auto' }}>
          <NeuromorphBtn
            width={300}
            disabled={loading || !reason}
            loading={loading}
            label={t('user.customerRejectKycRejection')}
            onClick={handleKycRejection}
            background={baseStyles.color.error.main}
          />
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default KycRejectionDrawer;
