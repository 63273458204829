import { AxiosError } from "axios";
import { AuthContext } from "providers/AuthProvider";
import { UserContext } from "providers/UserProvider";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/routes";
import AuthService, { PostLoginPayload } from "services/Login/LoginService";
import { UserService } from "services/User/UserService";
import { useTranslation } from "react-i18next";

function useAuthenticationHooks() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { setCurrentUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState({
    incorrectCredential: false,
    serverError: false,
  });

  const performLogin = (payload: PostLoginPayload) => {
    setLoading(true);
    AuthService.PostLogin(payload)
      .then((response) => {
        if (response.status === 200) {
          const loginToken = response.data?.access;
          console.log("loginToken", loginToken);
          if (loginToken) {
            login(loginToken);
          }
          setLoading(false);
        }
        setLoading(false);
        navigate(ROUTES.dashboard);
      })
      .catch((e) => {
        toast.error(t('alert.incorrectIdentification'));
        console.log("error ", e);
        setLoading(false);
        if (e.response?.status === 401 || e.response?.status === 400) {
          setLoginError((state) => ({ ...state, incorrectCredential: true }));
        } else {
          console.log("Erreur serveur");
          setLoginError((state) => ({ ...state, serverError: true }));
        }
      });
  };

  const getUserInfo = async () => {
    setLoading(true);

    try {
      const response = await UserService.GetCurrentUser();
      if (response.status === 200) {
        const userInfo = response.data;

        if (userInfo) {
          setCurrentUser(userInfo);
        }
        setLoading(false);
      }
    } catch (e) {
      const error = e as AxiosError;
      console.log(error?.response);
      setLoading(false);
    }
  };

  return {
    performLogin,
    loginError,
    setLoginError,
    loading,

    getUserInfo,
  };
}

export default useAuthenticationHooks;
