import { ButtonBase, Grid, Menu, Typography } from "@mui/material";
import { ArrowDownIcon } from "components/Icons/ArrowDownIcon";
import NeomorphButtonBase from "components/NeomorphButtonBase/NeomorphButtonBase";
import Spacer from "components/Spacer/Spacer";
import { useState } from "react";
import { Card } from "ui-neumorphism";
import { baseStyles } from "utils/constants/baseStyles";
import { ISelectItem } from "utils/interfaces";

type SelectProps = {
  id?: string;
  placeholder?: string;
  label?: string;
  width?: any;
  value?: any;
  onchange?: (e: any) => any;
  handleClick?: any;
  disabled?: boolean;
  items?: ISelectItem[];
};

export const FakeSelect = (props: SelectProps) => {
  const { handleClick, placeholder, onchange, label, items, disabled, value } =
    props;
  const Content: any = Card;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const onClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (selected: any) => {
    setAnchorEl(null);
    onchange?.(selected);
  };

  const valueLabel = (_value: any) => {
    const founded = items?.find((item) => item.value === _value);
    return founded ? founded.label : "Non défini";
  };

  return (
    <div style={{ flex: 1 }}>
      {label && (
        <>
          <p
            style={{
              color: baseStyles.text.default,
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {label} :{" "}
          </p>
          <Spacer height={10} />
        </>
      )}
      <ButtonBase
        disabled={disabled}
        onClick={(e) => {
          handleClick?.(e);
          onClickOpen(e);
        }}
        style={{
          flex: 1,
          display: "flex",
          flexGrow: 1,
          width: "100%",
        }}
      >
        <Content
          inset
          style={{
            flex: 1,
            height: 50,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 5,
            backgroundColor: baseStyles.color.grey,
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Grid
            direction={"row"}
            alignItems="center"
            justifyContent="space-between"
            style={{ display: "flex" }}
          >
            <p
              style={{
                display: "inline-block",
                fontSize: 12,
                paddingTop: 11,
                fontWeight: 500,
                color: baseStyles.text.black,
              }}
            >
              {value !== null && value !== undefined
                ? valueLabel(value)
                : placeholder}
            </p>
            <ArrowDownIcon
              style={{
                display: "inline-block",
                paddingTop: 5,
                alignSelf: "flex-end",
              }}
            />
          </Grid>
        </Content>
      </ButtonBase>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 300,
            padding: 10,
            background: baseStyles.color.grey,
          },
        }}
      >
        {items?.map((item, i) => (
          <NeomorphButtonBase
            key={item.value}
            height={40}
            background={
              item.value === value
                ? baseStyles.color.blue.primary
                : baseStyles.color.grey
            }
            style={{ marginBottom: i < items.length - 1 ? 15 : 0 }}
            onClick={() => handleClose(item.value)}
          >
            <Typography style={{ textTransform: "none" }}>
              {item?.label ?? item?.name}
            </Typography>
          </NeomorphButtonBase>
        ))}
      </Menu>
    </div>
  );
};
