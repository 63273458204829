export const ROUTES = {
  home: "/",
  login: "/login",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
  validationCode: "/code-validation",
  emailCodeValidation: "/email-validation",
  dashboard: "/dashboard",
  users: "/users",
  customers: "/customers",
  transactions: "/transactions",
  journal: "/journal",
  country: "/country",
  commissions: "/commissions",
  manageCommision: "/manage",
  operators: "/operators",
  myAccount: "/me",
};
