import useUsersHooks from "hooks/useUsersHooks";
import { FC, createContext, useState, useEffect } from "react";
import { ConnectedUserInfos, Currency, IUserItem } from "utils/interfaces";

type UserContextType = {
  currentUser: ConnectedUserInfos;
  setCurrentUser: (user: any) => any;
  userList: IUserItem[];
  setUserList: (users: IUserItem[]) => void;
  refreshUserList: Function;
  filteredUsers: IUserItem[];
  setFilteredUsers: (user: IUserItem[]) => void;
  currentCurrency: string;
  setCurrentCurrency: Function;
};

export const UserContext = createContext<UserContextType>({
  currentUser: {} as ConnectedUserInfos,
  setCurrentUser: (user: any) => {},
  userList: [],
  setUserList: (user: IUserItem[]) => {},
  filteredUsers: [],
  setFilteredUsers: (user: IUserItem[]) => {},
  refreshUserList: () => {},
  currentCurrency: "",
  setCurrentCurrency: () => {},
});

interface UserProviderProps {
  children: any;
}

export const UserProvider: FC<UserProviderProps> = (
  props: UserProviderProps
) => {
  const [currentUser, setCurrentUser] = useState({} as ConnectedUserInfos);
  const [users, setUsers] = useState<IUserItem[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUserItem[]>([]);
  const [currentCurrency, setCurrentCurrency] = useState("EUR");
  const { children } = props;
  const { performGetUserList } = useUsersHooks({});

  useEffect(() => {
    console.log("User's list upated");
  }, [users]);

  return (
    <UserContext.Provider
      value={{
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
        userList: users,
        setUserList: (_users) => {
          setUsers(_users);
        },
        refreshUserList: () => {
          performGetUserList();
        },
        filteredUsers,
        setFilteredUsers,
        currentCurrency,
        setCurrentCurrency,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
