import * as React from "react";
import { SVGProps, Ref, forwardRef } from "react";
const SvgComponent = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    ref={ref}
    {...props}
  >
    <g fill={props.color ?? "#6E847E"}>
      <path d="M16 8a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0ZM18.5 17c0 1.933-2.686 3.5-6 3.5s-6-1.567-6-3.5 2.686-3.5 6-3.5 6 1.567 6 3.5ZM7.622 5.5c.178 0 .35.017.518.05A4.977 4.977 0 0 0 7.5 8c0 .868.221 1.685.61 2.396-.158.03-.32.045-.488.045-1.414 0-2.561-1.106-2.561-2.47 0-1.365 1.147-2.471 2.561-2.471ZM5.947 19.486C5.38 18.807 5 17.974 5 17c0-.944.357-1.756.896-2.423-1.905.148-3.396 1.19-3.396 2.452 0 1.275 1.517 2.325 3.447 2.457ZM17.5 8c0 .868-.221 1.685-.61 2.396.157.03.32.045.488.045 1.414 0 2.56-1.106 2.56-2.47 0-1.365-1.146-2.471-2.56-2.471-.178 0-.35.017-.518.05.407.724.64 1.56.64 2.45ZM19.053 19.486c1.93-.132 3.447-1.182 3.447-2.457 0-1.263-1.491-2.304-3.396-2.452.54.667.896 1.479.896 2.423 0 .974-.38 1.807-.947 2.486Z" />
    </g>
    <defs>
      <filter
        id="a"
        width={24}
        height={25}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0} />
        <feComposite in2="hardAlpha" k2={-1} k3={1} operator="arithmetic" />
        <feColorMatrix values="0 0 0 0 0.352941 0 0 0 0 0.396078 0 0 0 0 0.443137 0 0 0 1 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1147_833" />
      </filter>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
export { ForwardRef as UserIcon };
