import { FC, useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "./routes";
import { AuthContext } from "providers/AuthProvider";
import { baseStyles } from "utils/constants/baseStyles";
import Spacer from "components/Spacer/Spacer";
import { LogoTCash } from "components/Logo/LogoTCash";
import SideBarMenu from "pages/SideBarMenu/SideBarMenu";
import AppHeader from "pages/AppHeader/AppHeader";
import { Divider } from "ui-neumorphism";
import { useMediaQuery } from "@mui/material";
import { useScreenType } from "utils/constants/UseScreenType";

interface IProps {}

const ProtectedRoute: FC<IProps> = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { token } = useContext(AuthContext);
  const { isMobile, isTab } = useScreenType();
  

  useEffect(() => {
    if (!token || token === null) {
      navigate(ROUTES.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      style={{
        height: `calc(100vh)`,
        display: "flex",
        flexDirection: "row",
        backgroundColor: baseStyles.color.grey,
      }}
    >
      {!isTab && (
        <div
          style={{
            width: 300,
            padding: "20px 0 20px 20px",
            backgroundColor: baseStyles.color.white,
          }}
        >
          <Spacer height={20} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: -20,
            }}
          >
            <LogoTCash height={96} width={129} />
          </div>

          <Spacer height={50} />

          <SideBarMenu />
        </div>
      )}
      <div
        style={{
          backgroundColor: baseStyles.color.grey,
          width: "100%",
          display: "block",
          overflow: "auto",
          padding: "20px 10px",
        }}
      >
        <AppHeader />


        {pathname !== "/commissions" && pathname !== "/manage" && (<Spacer />)}

        <Divider dense elevated />

        <Spacer />

        {/* Outlet => Children Routes */}
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedRoute;
